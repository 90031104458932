import { observer } from "mobx-react";
import { useCallback } from "react";
import { useLocation, useParams } from "react-router";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { companiesStore } from "../../stores/CompaniesStore";
import { UseParams, withParams } from "../app/router/history";
import { HrRoutes } from "../hr/router/HrRoutes";
import { StaffRoutes } from "../staff/router/StaffRoutes";
import { CenteredContent } from "../ui/CenteredContent";
import { EmptyState } from "../ui/EmptyState";
import { NavBarBack } from "../ui/NavBarBack";
import { SiteContent } from "../ui/SiteContent";
import { mapPayrollItem } from "./payrollUtils";
import { Disclaimer } from "./ResultsDisclaimer";
import { ResultsPayrollAccount } from "./ResultsPayrollAccount";
import { ResultsRoutes } from "./router/ResultsRoutes";

export const ResultsEmployeePayrollAccount = observer(function ResultsEmployeePayrollAccount() {
    const companyId = companiesStore.selectedCompanyId;

    if (companyId === undefined) {
        return <EmptyState title={t("results.payrollAccountSheet.noResultsYear.title")} />;
    }

    return <ResultsEmployeePayrollAccountSheet companyId={companyId} />;
});

interface ResultsEmployeePayrollAccountAccountSheetProps {
    companyId: string;
}

const ResultsEmployeePayrollAccountSheet = (props: ResultsEmployeePayrollAccountAccountSheetProps) => {
    const { companyId } = props;
    const params = useParams<UseParams<typeof ResultsRoutes.EMPLOYEE_PAYROLL_ACCOUNT>>();
    const location = useLocation();
    const { employeeName, previousRoute } = (location.state as { employeeName?: string; previousRoute?: string }) || {};
    const employeeId = params.employeeId;

    const loader = useCallback(
        async (companyId: string, year: number, options: { filter: string; previousYears: number }) => {
            if (!employeeId) {
                throw new Error("Employee ID is required");
            }
            const response = await API.getEmployeePayrollAccount({
                companyId,
                employeeId: employeeId,
                year,
                query: { filter: options.filter, previousYears: options.previousYears },
            });

            return (response.employeePayrollAccount.payrollItems ?? []).map(mapPayrollItem);
        },
        [employeeId],
    );

    let backLabel;
    let backTarget;

    if (previousRoute === "employeeDetails") {
        backLabel = t("screen.hr.employee.details.payrollAccount.navBack", {
            fullName: employeeName,
        });
        backTarget = withParams(HrRoutes.EMPLOYEES.DETAILS, { employeeId: employeeId ?? "" });
    } else if (previousRoute === "staff") {
        backTarget = StaffRoutes.ROOT;
        backLabel = t("screen.hr.staff.payrollAccount.navBack");
    } else {
        backLabel = t("results.navbar.buttons.payrollAccount");
        const year = params.year ?? new Date().getFullYear().toString();
        backTarget = withParams(ResultsRoutes.EMPLOYER_PAYROLL_ACCOUNT, { year: year });
    }

    return (
        <>
            <NavBarBack
                backLabel={backLabel}
                backTarget={backTarget}
                showCancel={false}
                title={`${t("results.navbar.buttons.payrollAccount")}: ${employeeName ?? ""}`}
            />
            <Disclaimer />
            <CenteredContent>
                <SiteContent>
                    <ResultsPayrollAccount type={"employee"} loader={loader} companyId={companyId} />{" "}
                </SiteContent>
            </CenteredContent>
        </>
    );
};
