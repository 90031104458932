import { observer } from "mobx-react";
import { Redirect } from "react-router";
import { companiesStore } from "../../../stores/CompaniesStore";
import { Routes } from "../../app/router/Routes";
import { ResultsRouter } from "../router/ResultsRouter";

export const ResultsContainerSite = observer(function ResultsContainerSite() {
    if (!companiesStore.selectedCompanyId) {
        // no company selected at the moment, redirect to root
        return <Redirect to={Routes.ROOT} />;
    }

    const companyStore = companiesStore.selectedCompanyStore;
    if (!companyStore) {
        // company/permissions not loaded yet, return null to keep waiting
        return null;
    }

    const enabled = companyStore.canReadResultsOrIsStaff;
    if (!enabled) {
        // not allowed to access the analysis in company, redirect to cockpit
        return <Redirect to={Routes.COCKPIT} />;
    }

    return <ResultsRouter />;
});
