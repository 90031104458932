import { Location } from "history";
import { action, observable } from "mobx";
import { create, persist } from "mobx-persist";
import * as config from "../config";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { apiClient } from "../network/APIClient";
import { GetResponsibleUsersResponse, UserNotification } from "../network/APITypes";
import { getApiError } from "../network/NetworkStapler";
import { HttpStatusCode } from "../network/httpStatusCode";
import { addStoreToWindow } from "../util/debug";
import { toError } from "../util/error";

interface ICookieSettings {
    marketing: boolean | undefined;
}

export type Notification =
    | { severity: "info" | "warning"; message: string }
    | { severity: "error"; message: string; error?: Error };

const NOTIFICATION_QUEUEING = false;
class GeneralStore {
    constructor() {
        apiClient.generalStore = this;
    }

    _isLoading = observable.array<string>([]);
    set isLoading(loading: boolean) {
        if (loading) {
            this.beginLoading("unknown");
        } else {
            this.endLoading("unknown");
        }
    }

    get isLoading() {
        return this._isLoading.length > 0;
    }

    beginLoading(label: string) {
        this._isLoading.push(label);
    }

    endLoading(label: string) {
        const found = this._isLoading.indexOf(label);
        if (found >= 0) {
            this._isLoading.splice(found, 1);
        } else {
            if (config.DEBUG) {
                throw new Error(`Could not find loading label ${label}`);
            }
        }
    }

    findLoading(label: string) {
        return this._isLoading.includes(label);
    }

    // Save as objet in preparation for future where maybe more than one type of optional cookies will be saved
    @persist("object") @observable cookieSettings: ICookieSettings = { marketing: undefined };
    @persist @observable cookiesSaved = false;

    @observable isRehydrated = false;

    @observable showUserReleaseInfoAdminDialog = false;
    @observable showUserReleaseInfoDialog = false;
    @observable showOpenEmployeeDocumentReleasesDialog = false;

    @observable lastLocation: Location | null = null;

    // _notifications is a FIFO queue
    @observable _notifications: Notification[] = [];

    @observable globalQuickActionsBottomOffset = 0;

    // Remove oldest notification
    @action popNotification() {
        if (NOTIFICATION_QUEUEING) {
            this._notifications = this._notifications.slice(1);
        } else {
            this._notifications = [];
        }
    }

    // Returns the first notification in the queue
    get notification() {
        return this._notifications.length > 0 ? this._notifications[0] : undefined;
    }

    @action addNotification(notification: Notification) {
        if (NOTIFICATION_QUEUEING) {
            this._notifications = this._notifications.concat([notification]);
        } else {
            this._notifications = [notification];
        }
    }
    @action setError(message: string, error?: unknown) {
        let errorMessage = message;

        const apiError = getApiError(error);
        if (
            apiError?.statusCode === HttpStatusCode.GatewayTimeout_504 ||
            apiError?.statusCode === HttpStatusCode.ServiceUnavailable_503
        ) {
            // show timeout message in case
            // * the request timed out (504)
            // * or (another) service is unavailable (503)
            errorMessage = `${t("error.timeout")} (${apiError.response.type?.toString() ?? ""})`;
        }

        // Add more info to make customer screenshots more useful
        if (error && errorMessage.startsWith(t("error.general"))) {
            if (typeof apiError?.response.type === "string") {
                errorMessage += ` (${apiError.response.type})`;
            }
        }

        this.addNotification({ severity: "error", message: errorMessage, error: toError(error) });
    }

    @observable appNotifications: UserNotification[] = [];

    @action async init() {
        await this.loadAppNotifications();
    }

    @action async loadAppNotifications() {
        try {
            const appNotifications = await API.getNotifications({
                module: "general",
                state: "unread",
                type: "appNotification",
            });
            this.appNotifications = appNotifications.notifications ?? [];
        } catch (error) {
            this.setError(t("error.general"), error);
        }
    }

    // Until we enable F2F chat for prod we have the support dialog as fallback
    @observable responsibleUsers?: GetResponsibleUsersResponse;
    @observable selectedSupportId?: string; // later selection should be communicated via query param into route

    @action openSupportDialog = (responsibleUsers: GetResponsibleUsersResponse, selectedSupportId?: string) => {
        this.responsibleUsers = responsibleUsers;
        this.selectedSupportId = selectedSupportId;
    };

    @action closeSupportDialog = () => {
        this.responsibleUsers = undefined;
        this.selectedSupportId = undefined;
    };

    wipe() {
        this.showUserReleaseInfoAdminDialog = false;
        this.showUserReleaseInfoDialog = false;
        this.showOpenEmployeeDocumentReleasesDialog = false;
        this._notifications = [];

        this.closeSupportDialog();

        // Intentionally don't reset lastLocation to still allow back history
        // Intentionally don't reset _isLoading to avoid getting stuck on loading indicator
        // Intentionally don't reset cookie consent. Otherwise user would have to agree everytime he logs out
    }
}

export const generalStore = new GeneralStore();

if (import.meta.env.NODE_ENV !== "test") {
    (async () => {
        const hydrate = create({
            storage: (await import("localforage")).default,
        });

        hydrate("general", generalStore)
            .then(() => {
                generalStore.isRehydrated = true;
            })
            .catch((error: unknown) => {
                console.error(error);
            });
    })();
}

addStoreToWindow("generalStore", generalStore);
