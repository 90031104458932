const bulgarian = {
  "accounting.bankAccount.account.label": "Сметка",
  "accounting.bankAccount.account.placeholder": "Сметка",
  "accounting.bankAccount.account.title": "Изберете акаунт",
  "accounting.bankAccount.transactions.assignInvoices.account.error": "Категориите на документите не могат да бъдат заредени",
  "accounting.bankAccount.transactions.assignInvoices.account.placeholder": "Категория на документа",
  "accounting.bankAccount.transactions.assignInvoices.filters.bankAccountTransactions.dateRange.required": "Моля, изберете начална и крайна дата.",
  "accounting.bankAccount.transactions.assignInvoices.filters.bankAccountTransactions.title": "Банкови транзакции",
  "accounting.bankAccount.transactions.assignInvoices.filters.records.recordTypes.title": "Символ на документа",
  "accounting.bankAccount.transactions.assignInvoices.filters.records.title": "Постъпления",
  "accounting.bankAccount.transactions.assignInvoices.records.empty": "Не са намерени доказателства",
  "accounting.bankAccount.transactions.assignInvoices.records.pagination.next": "Следващ документ",
  "accounting.bankAccount.transactions.assignInvoices.records.pagination.previous": "Предишен документ",
  "accounting.bankAccount.transactions.assignInvoices.subtitle": "Моля, припишете квитанцията към банкова операция.",
  "accounting.bankAccount.transactions.assignInvoices.title": "Разпределяне на ваучери",
  "accounting.bankAccount.transactions.table.action.assignOpenInvoices": "Възлагане на отворени ваучери",
  "accounting.bankAccount.transactions.table.action.downloadBankStatement": "Експорт за осчетоводяване на банкови извлечения",
  "accounting.bankAccount.transactions.table.action.downloadBuerf": "Експорт за внос на резервации в NTCS",
  "accounting.bankAccount.transactions.table.emptyState.title": "Не са намерени транзакции",
  "accounting.bankAccount.transactions.table.label.amount": "Сума",
  "accounting.bankAccount.transactions.table.label.assignInvoice": "Присвояване на документ",
  "accounting.bankAccount.transactions.table.label.date": "Дата на резервацията",
  "accounting.bankAccount.transactions.table.label.invoice": "Разписка",
  "accounting.bankAccount.transactions.table.label.isPrivate": "Частна",
  "accounting.bankAccount.transactions.table.label.recipient": "Приемник",
  "accounting.bankAccount.transactions.table.label.text": "Текст на резервацията",
  "accounting.bankAccount.transactions.table.label.valueDate": "Дата на стойността",
  "accounting.bankAccount.transactions.table.menu.deleteAssignedInvoice": "Премахване на връзката към документа",
  "accounting.bankAccount.transactions.table.menu.markAsPrivate": "Отбелязване на частна транзакция",
  "accounting.bankAccount.transactions.table.menu.removePrivate": "Отмяна на частното маркиране",
  "accounting.bankAccount.transactions.table.search.count": "Транзакции: <b>{count}</b>",
  "accounting.bankAccount.transactions.table.search.placeholder": "Търсене на транзакции",
  "accounting.bankAccount.transactions.table.uploadInvoice.progress.title": "Разписката е качена",
  "accounting.bankAccount.transactions.table.uploadInvoice.subsidiaryAndRecordType.title": "Изберете код на фирмата и символ на документа",
  "accounting.bankAccount.transactions.title": "Транзакции",
  "accounting.bankConnection.delete.message": "Наистина ли искате да изтриете банковата сметка {име}?",
  "accounting.bankConnection.delete.title": "Изтриване на банкови данни",
  "accounting.bankConnections.accountHolder": "Собственик:",
  "accounting.bankConnections.accountType": "Вид на сметката:",
  "accounting.bankConnections.accountType.Bausparen": "Спестявания в строителните дружества",
  "accounting.bankConnections.accountType.Checking": "Текуща сметка",
  "accounting.bankConnections.accountType.CreditCard": "Кредитна карта",
  "accounting.bankConnections.accountType.Loan": "Кредитна сметка",
  "accounting.bankConnections.accountType.Membership": "Кооперативни акции",
  "accounting.bankConnections.accountType.Savings": "Спестовна сметка",
  "accounting.bankConnections.accountType.Security": "Сметка за ценни книжа",
  "accounting.bankConnections.addBankConnection": "Добавяне на банкови данни",
  "accounting.bankConnections.balance": "Салдо по сметката:",
  "accounting.bankConnections.emptyState.description": "Добавете нова банкова сметка.",
  "accounting.bankConnections.emptyState.title": "Без банкови данни",
  "accounting.bankConnections.expired": "Изтекъл срок на валидност",
  "accounting.bankConnections.expiredAt": "Срокът на валидност изтича на {дата}",
  "accounting.bankConnections.expiresAt": "Срокът на валидност изтича на {дата}",
  "accounting.bankConnections.iban": "IBAN:",
  "accounting.bankConnections.lastSuccessfulUpdate": "за {дата}",
  "accounting.bankConnections.pending": "Изключителен",
  "accounting.bankConnections.refreshBankConnection": "Подновяване на банковата сметка",
  "accounting.bankConnections.refreshPending": "Текущи подновявания",
  "accounting.bankConnections.refreshPending.info": "Подновяването на банковите данни се извършва в отделен прозорец. Ако прозорецът е бил затворен, преди да завърши обновяването, трябва да щракнете отново върху \"Подновяване на банковите данни\".",
  "accounting.bankConnections.refreshPending.label": "Процесът на подновяване е в ход",
  "accounting.bankConnections.upToDate": "Потвърдено",
  "accounting.details.edit.header": "Редактиране на документ",
  "accounting.details.updatedAt": "последно изменен на",
  "accounting.details.upload.files": "Файлове",
  "accounting.details.upload.skip": "Пропуснете всички",
  "accounting.finished.reports": "Доклади",
  "accounting.table.label.totalGrossAmount": "Размер на фактурата",
  "addFolderDialog.button.text.create": "Създаване на",
  "addFolderDialog.folderName": "Име на папката",
  "addFolderDialog.info": "Отидете в Sharepoint, за да управлявате членовете.",
  "addFolderDialog.title": "Създаване на папка",
  "advisor.uploads.uploadReports.title": "Предаване на доклади",
  "analysis.powerBI.reports.table.label.description": "",
  "analysis.powerBI.reports.table.label.name": "",
  "analysis.powerBI.reports.table.search.count": "",
  "analysis.powerBI.reports.table.search.placeholder": "",
  "analysis.powerBI.reports.title": "",
  "analysis.powerBI.report.title": "",
  "appNotificationDialog.button.accept": "Разбрах",
  "appNotificationDialog.title.maintenance": "Работа по поддръжката",
  "appNotificationDialog.title.newFeature": "Нови функции",
  "appNotificationDialog.title.update": "",
  "branchType.allocationGroup": "Район на населеното място",
  "branchType.costCenter": "Ръководител на разходите",
  "branchType.department": "Отдел",
  "branchType.financialAccountancyCircle": "Кръг на FIBU",
  "branchType.other": "Други",
  "branchType.wageCompany": "Компания за заплати",
  "branchType.workplace": "Работно място",
  "button.activateMfa": "Активирайте се сега",
  "button.answer": "ОТГОВОР",
  "button.archive": "Архив",
  "button.assign": "Задаване на",
  "button.back_to_login": "Обратно към влизането",
  "button.closeTicket": "Маркирайте като свършено",
  "button.configure": "КОНФИГУРИРАНЕ НА",
  "button.continueInBackground": "Продължете във фонов режим",
  "button.continue_without_microsoft": "Продължаване без акаунт в Microsoft",
  "button.copy": "Копиране/преместване",
  "button.deactivateMfa": "Деактивиране сега",
  "button.enter": "ВЛЕЗТЕ В",
  "button.markAsPrivate": "Маркиране като поверително",
  "button.moreInfo": "ПОВЕЧЕ ИНФОРМАЦИЯ",
  "button.move": "Преместете се тук",
  "button.nextStep": "следваща стъпка",
  "button.paste": "Копирайте тук",
  "button.refresh": "Обновяване на",
  "button.release": "Споделете",
  "button.submitArchiveMultiple": "{count} Архив съобщения",
  "button.submitArchiveSingle": "{count} Архив на съобщението",
  "button.toOverview": "Към прегледа",
  "button.transfer": "Предаване",
  "button.unlock": "Отключване на",
  "button.upload": "Качване на файл",
  "captcha.invalid": "",
  "captcha.placeholderText": "",
  "chatInput.deleteLink": "Изтриване на",
  "chatInput.editLink": "Редактиране на",
  "chatInput.enterLink": "Връзка",
  "chatInput.openLink": "Отворен URL адрес",
  "chatInput.saveLink": "Запазете",
  "common.abacus": "Abacus",
  "common.accounting": "Счетоводство",
  "common.accounting.period": "Период на резервация",
  "common.accounting.recordType": "Символ на документа",
  "common.accounting.subsidiary": "Код на компанията",
  "common.add": "Добавяне на",
  "common.albanian": "Shqiptare",
  "common.all": "Всички",
  "common.amount": "Номер",
  "common.anyDateRange": "Всеки период",
  "common.apply": "Поемете управлението",
  "common.archive": "Архив",
  "common.back": "Обратно",
  "common.bankConnection": "Банкови данни",
  "common.bankConnections": "Банкови данни",
  "common.bankContact": "Банкови данни",
  "common.bic": "BIC",
  "common.bmd": "BMD",
  "common.bulgarian": "Български",
  "common.cancel": "Отмяна на",
  "common.cancelCaps": "ОТМЕНЯНЕ",
  "common.chat": "Чат",
  "common.clientNumber": "Номер на клиента",
  "common.cockpit": "Кокпит",
  "common.companies": "Компания",
  "common.company": "Компания",
  "common.companyNumber": "Номер на компанията",
  "common.companyOverview": "Преглед на компанията",
  "common.companyRegisterNumber": "Регистрационен номер на дружеството",
  "common.continue": "Още",
  "common.country": "Държава",
  "common.croatian": "Hrvatski",
  "common.czech": "Česky",
  "common.dateformat": "DD.MM.YYYY",
  "common.dateValidation": "Моля, въведете валидна дата",
  "common.defaultSignature": "Вашият TPA",
  "common.delete": "Изтриване на",
  "common.done": "Готов",
  "common.download": "Изтегляне",
  "common.downloadAll": "",
  "common.dueDate": "Краен срок",
  "common.dueUntil": " най-късно до {dueDate}",
  "common.emailAddress": "Адрес на електронна поща",
  "common.english": "English",
  "common.errorOccurred": "Възникнала е грешка",
  "common.events": "Събития",
  "common.files": "Файлове",
  "common.finished": "Завършен",
  "common.folder": "Папка",
  "common.from": "От",
  "common.fullName": "{първо име} {последно име}",
  "common.generalData": "Общи данни",
  "common.generalDocuments": "Общи файлове",
  "common.german": "Deutsch",
  "common.global": "В цялата компания",
  "common.hr": "Заплати",
  "common.hr.period": "Счетоводен период",
  "common.hrPreRegistrations": "Персонал - предварителни вписвания",
  "common.hr.short": "Персонал",
  "common.hr.subsidiary": "Район на населеното място",
  "common.hungarian": "Magyar",
  "common.invite": "Покана",
  "common.kpi": "Факти и цифри",
  "common.kpi.long": "Факти и цифри",
  "common.later": "По-късно",
  "common.logout": "Излизане от системата",
  "common.me": "I",
  "common.members": "Членове",
  "common.moreInformation": "научете повече",
  "common.mr": "Г-н {име}",
  "common.mrs": "Г-жа {име}",
  "common.name": "Име",
  "common.no": "Не",
  "common.none": "няма",
  "common.noSearchResults": "Не са намерени резултати",
  "common.notAvailable": "N/A",
  "common.notSpecified": "n.a.",
  "common.numberValidation": "Моля, въведете валиден номер",
  "common.ok": "Добре",
  "common.optional": "(по избор)",
  "common.orderBy": "Сортирай по",
  "common.partial": "отчасти",
  "common.people": "Хора",
  "common.polish": "Polski",
  "common.profile": "Профил",
  "common.project": "Проект",
  "common.projects": "Проекти",
  "common.readingTimeMinutes": "Време за четене: {време} Мин",
  "common.readingTimeMinutes.short": "{време} Мин",
  "common.recipient": "Приемник",
  "common.refresh": "Обновяване",
  "common.releasedAt": "издаден на {дата}",
  "common.releasedAtBy": "издаден на {дата} от {името}",
  "common.releaseRequired.plural": "{count} Освобождавания, които се изискват",
  "common.releaseRequired.singular": "Изисква се освобождаване",
  "common.remainingItems": "+{count} още",
  "common.remainingItemsUnknown": "Заредете повече",
  "common.retry": "Опитайте отново",
  "common.romanian": "Românesc",
  "common.russian": "Русский",
  "common.save": "Запазете",
  "common.sendCaps": "ИЗПРАТИ",
  "common.serbian": "Српски",
  "common.settings": "Настройки",
  "common.showAll": "Покажи всички",
  "common.slovak": "Slovenská",
  "common.slovene": "Slovenski",
  "common.taskTitle": "Заглавие",
  "common.taxNumber": "Данъчен номер",
  "common.termsOfUse": "Условия за ползване",
  "common.ticket": "Консултация",
  "common.timePeriod": "Период",
  "common.timePeriod.olderThan1Month": "По-стари от един месец",
  "common.timePeriod.olderThan1Week": "По-стари от една седмица",
  "common.timePeriod.olderThan1Year": "По-стари от една година",
  "common.timePeriod.olderThan6Months": "По-стари от 6 месеца",
  "common.to": "До",
  "common.tpaFirstName": "TPA",
  "common.tpaLastName": "Служители",
  "common.unknown": "неизвестен",
  "common.upcomingTermsOfUse": "Бъдещи условия за ползване",
  "common.upload": "Качване на",
  "common.uploadFiles": "Качване на файлове",
  "common.user": "Потребители",
  "common.vatNumber": "UID",
  "common.yes": "Да",
  "commuterAllowance.IndividuellePendlerpauschale": "Индивидуална надбавка за пътуване до работа",
  "commuterAllowance.KeinePendlerpauschale": "Без надбавка за пътуване до работа",
  "commuterAllowance.MehrAls20kmKeinBus": "Повече от 20 км, без автобус",
  "commuterAllowance.MehrAls40kmBus": "Повече от 40 км, автобус",
  "commuterAllowance.MehrAls40kmKeinBus": "Повече от 40 км, без автобус",
  "commuterAllowance.MehrAls60kmBus": "Повече от 60 км, автобус",
  "commuterAllowance.MehrAls60kmKeinBus": "Повече от 60 км, без автобус",
  "commuterAllowance.Mindestens20kmBus": "Най-малко 20 км, автобус",
  "commuterAllowance.Mindestens2kmKeinBus": "Поне 2 км, без автобус",
  "companiesUserReleaseInfoActionsBar.message": "Имате {брой} нови потребители за споделяне",
  "companiesUserReleaseInfoActionsBar.submit": "Преглед и споделяне",
  "companiesUserRelease.permissions.navbar.back": "Споделяне и покана на потребители / Присвояване на роля",
  "companiesUserRelease.title": "Споделяне и покана на потребители",
  "companyImages.cover.error": "Изображението на корицата не може да бъде заредено",
  "companyImages.cover.title": "Изображение на корицата",
  "companyImages.delete.message": "Наистина ли искате да изтриете снимката?",
  "companyImages.delete.title": "Изтриване на изображението",
  "companyImages.logo.error": "Логото на компанията не може да бъде заредено",
  "companyImages.logo.title": "Лого на компанията",
  "companyImages.title": "Изображение на корицата и лого на вашата компания (по избор)",
  "companyList.remainingCompanies": "+{останали компании} Още",
  "company.optionalSideBarMenuItems.maxLength.name": "Името може да съдържа само 15 символа",
  "company.optionalSideBarMenuItems.nameAlreadyinUse": "Вече е наличен допълнителен елемент от менюто",
  "company.optionalSideBarMenuItems.name.label": "Име",
  "company.optionalSideBarMenuItems.required.name": "Трябва да се посочи име",
  "company.optionalSideBarMenuItems.required.url": "Трябва да се посочи URL адрес",
  "company.optionalSideBarMenuItems.title": "Незадължителни елементи от менюто на компанията",
  "company.optionalSideBarMenuItems.tooltip.removeItem": "Премахване на елемент от менюто",
  "company.optionalSideBarMenuItems.urlAlreadyinUse": "Вече съществува връзка за опционалния елемент от менюто",
  "company.optionalSideBarMenuItems.url.label": "URL",
  "company.optionalSideBarMenuItems.urlNotValid": "Моля, въведете валиден URL адрес (пример: https://www.tpa-connect.app )",
  "companyOverview.emptyState.message": "Моля, свържете се с вашия съветник",
  "companyOverview.emptyState.title": "Няма действащо дружество",
  "companyOverview.emptyState.tpa.message": "В момента нямате активни клиенти",
  "companyOverview.emptyState.tpa.title": "Няма активни клиенти",
  "companyOverview.unlockCountry.tooltip": "Отключване на нови държави{br}{br} Отключете международните проекти от <b>{страна}</b>, за да използвате функцията за проекти на тази компания и да преглеждате всички заявки.",
  "companyStatus.accountingSetupDone": "0/2 направено",
  "companyStatus.active": "активен",
  "companyStatus.hrSetupDone": "1/2 готово",
  "companyStatus.inactive": "неактивен",
  "companyStatus.userSetupDone": "2/2 готово",
  "config.accounting.accountingByCustomer": "Предаване на счетоводни данни от клиентите чрез TPA Connect",
  "config.accounting.delete.subsidiaries.last.message": "Кодът на фирмата {име} не може да бъде изтрит. Трябва да съществува поне един код на фирма.",
  "config.accounting.delete.subsidiaries.message": "Наистина ли искате да изтриете кода на компанията {име}?",
  "config.accounting.delete.subsidiaries.title": "Изтриване на кода на фирмата",
  "config.accounting.description": "Определяне на вида и обхвата на счетоводната област.",
  "config.accounting.description.customer": "Вашият консултант по TPA е конфигурирал тази област за вас. Ако желаете да направите някакви промени, моля, свържете се с вашия консултант по TPA по всяко време.",
  "config.accounting.due.accounting.reports.select.title": "Дни след предаването на счетоводството",
  "config.accounting.dueDates.title": "Предаване на счетоводни документи",
  "config.accounting.due.reportRelease.select.subtitle": "Например 4 дни след предаването на отчетите от TPA. Това означава на 12-о число от следващия месец (ако предаването на отчетите от TPA е станало на 8-о число от следващия месец).",
  "config.accounting.due.reportRelease.select.title": "Дни след предаването на докладите",
  "config.accounting.due.reportRelease.select.value": "{day} Дни след предаването на докладите",
  "config.accounting.due.reportRelease.title": "Издаване на отчети от клиенти",
  "config.accounting.due.reports.select.subtitle": "Например 3 дни след предаването на счетоводството. Това означава на 8-о число от следващия месец (ако предаването на счетоводството е станало на 5-о число от следващия месец).",
  "config.accounting.due.reports.select.value": "{day} Дни след предаването на отчетите",
  "config.accounting.due.reports.title": "Предаване на доклади от TPA",
  "config.accounting.due.transfer.select.subtitle": "Например на 5-то число на следващия месец.",
  "config.accounting.due.transfer.select.title": "Предаване до",
  "config.accounting.due.transfer.select.value": "{day} Дни след края на периода",
  "config.accounting.due.transfer.title": "Предаване на счетоводни данни от клиентите",
  "config.accounting.globalReports.description": "Клиентът разполага с отчети за цялата компания",
  "config.accounting.globalReports.title": "Отчети за цялата компания",
  "config.accounting.hasAccounting": "Клиентът има счетоводна отчетност",
  "config.accounting.hasBankAccountTransactionInvoices": "Присвояване на документи към банкови транзакции",
  "config.accounting.hasCockpitKpi": "Фактите и цифрите в пилотската кабина",
  "config.accounting.hasKpi": "Факти и цифри",
  "config.accounting.hasResults": "Оценки",
  "config.accounting.incaseoflaw.description": "Клиентът разполага с функцията \"В случай на закон",
  "config.accounting.incaseoflaw.label": "В случай на право",
  "config.accounting.incaseoflaw.title": "В случай на право",
  "config.accounting.month.description": "Включва продължителност от един месец",
  "config.accounting.month.title": "Месечно счетоводство",
  "config.accounting.quarter.description": "Включва период от 3 месеца",
  "config.accounting.quarter.title": "Счетоводство на тримесечие",
  "config.accounting.recordTypes": "Счетоводни постъпления",
  "config.accounting.recordTypes.ocrDisabled": "Автоматично разпознаване на документи (OCR) деактивирано",
  "config.accounting.recordTypes.ocrEnabled": "Активирано автоматично разпознаване на документи (OCR)",
  "config.accounting.requireTwoReportReleases": "Изисква се одобряване на отчетите от двама потребители (принцип на двойния контрол)",
  "config.accounting.subsidiaries.add": "Добавяне на код на компанията",
  "config.accounting.subsidiaries.title": "Определяне на кодове на компании",
  "config.accounting.subsidiaries.title.customer": "Кодове на фирмите",
  "config.accounting.subsidiary.maxReached": "Достигнат максимален брой кодове на фирми",
  "config.accounting.subsidiary.name": "Кодово име на компанията",
  "config.accounting.week.description": "Продължителността е 5 работни дни и е разделена на календарни седмици.",
  "config.accounting.week.title": "Седмично счетоводство",
  "config.bulk.button.configure": "Конфигуриране на",
  "config.bulk.data.description": "Тук можете да качите изображение на корицата и логото на компаниите.",
  "config.bulk.data.title": "Основни данни",
  "config.bulk.finish.description": "Фирмите са в процес на конфигуриране. Това може да отнеме няколко минути, моля, не затваряйте прозореца на браузъра.",
  "config.bulk.finish.start": "Стартиране на",
  "config.bulk.finish.title": "Финализиране на конфигурацията",
  "config.companies": "Конфигурация на компанията",
  "config.companies.buttonConfig": "Конфигуриране на компанията",
  "config.companies.count": "{count} Компания",
  "config.companies.description": "В тази стъпка определяте специфичните за компанията процеси. Те включват визуалния вид в TPA Connect, вида и обхвата на счетоводството и заплатите, както и крайните срокове.",
  "config.companyData.cockpit": "Кокпит",
  "config.companyData.description": "Тук можете да качите изображение на корицата и логото на компанията и да зададете критериите за резервация.",
  "config.companyData.title": "Основни данни",
  "config.companyOverview.inactive": "Преглед на компанията / Неактивна",
  "config.customMenuPoints.deleteCustomMenuPoint.confirmLabel": "Да, изтрийте",
  "config.customMenuPoints.deleteCustomMenuPoint.message": "Наистина ли искате да изтриете този елемент от менюто?",
  "config.customMenuPoints.deleteCustomMenuPoint.title": "Изтриване на елемент от менюто",
  "config.customMenuPoints.noMenuPointsAvailable": "Няма налични елементи от менюто",
  "config.employees.assignRole": "Задаване на роля",
  "config.employees.emptyState.message": "В момента няма активни служители",
  "config.employees.emptyState.title": "Няма активни служители",
  "config.employees.table.missingEmail": "Липсва адрес на електронна поща",
  "config.employees.table.missingEmail.tooltip": "Адресът на електронната поща трябва да бъде въведен в BMD от консултант на TPA",
  "config.features.alreadyToggledToday.disabled": "Добавката вече е деактивирана днес",
  "config.features.alreadyToggledToday.enabled": "Добавката вече е активирана днес",
  "config.features.confirm.message.disabled": "Ако активирате добавката, платената добавка ще бъде налична веднага и ще ви бъде начислена от днес. Можете да деактивирате добавката отново утре.",
  "config.features.confirm.message.enabled": "Платената добавка ще бъде деактивирана в края на деня. Все пак ще бъдете таксувани за днешния ден. Можете да активирате отново добавката от утре.",
  "config.features.confirm.title.disabled": "Активиране на добавката",
  "config.features.confirm.title.enabled": "Деактивиране на добавката",
  "config.finishConfiguration": "Финализиране на конфигурацията",
  "config.finishConfiguration.cockpit": "Към пилотската кабина",
  "config.finishConfiguration.continue": "Създаване на друга компания",
  "config.finishConfiguration.emailNotification": "Уведомяване на потребителите по имейл",
  "config.finishConfiguration.message": "Конфигурацията на {company} е успешно конфигурирана. Сега тя може да се използва за обмен между TPA и клиента.",
  "config.finishSetup": "ИЗХОД ОТ НАСТРОЙКАТА",
  "config.hr.chat.description": "Служителите, които получават заплата, могат да се свързват с консултанти на TPA.",
  "config.hr.chat.title": "Чат",
  "config.hr.delete.subsidiaries.last.message": "Счетоводната област {име} не може да бъде изтрита. Трябва да съществува поне една счетоводна област.",
  "config.hr.delete.subsidiaries.message": "Наистина ли искате да изтриете счетоводната област {име}?",
  "config.hr.delete.subsidiaries.title": "Изтриване на областта за заплати",
  "config.hr.description": "Определете естеството и обхвата на областта на заплащане.",
  "config.hr.dueDates.reminder": "Напомняне на крайните срокове",
  "config.hr.dueDates.title": "Крайни срокове за отчитане на заплатите",
  "config.hr.due.relativeDueDates": "Доставка на документи през следващия месец",
  "config.hr.due.reports.select.subtitle": "Например 3 дни след предаването на ведомостта за заплати. Това означава на 8-мо число от следващия месец (ако предаването на счетоводната информация за заплатите е станало на 5-то число от следващия месец).",
  "config.hr.due.reports.select.title": "Дни след предаване на ведомостта за заплати",
  "config.hr.due.reports.select.value": "{day} Дни след предаването на справката за персонала",
  "config.hr.due.select.value": "{ден}. на месеца",
  "config.hr.due.transfer.select.subtitle": "Например на 5-то число от месеца.",
  "config.hr.due.transfer.select.value.relativeDueDates": "{day} Дни след края на месеца",
  "config.hr.due.transfer.title": "Предаване на ведомости за заплати от клиентите",
  "config.hr.hasHr": "Клиентът има ведомост за заплати",
  "config.hr.hasResults": "Анализи",
  "config.hr.recordTypes": "Документи за заплати",
  "config.hr.releaseSteps.oneStep.description": "Всички месечни оценки се предават от TPA на клиента в една стъпка. Клиентът трябва да предостави всички необходими документи в определен срок.",
  "config.hr.releaseSteps.oneStep.title": "в една стъпка",
  "config.hr.releaseSteps.title": "Етапи на освобождаване",
  "config.hr.releaseSteps.twoSteps.description": "Предаването и публикуването на месечната оценка се извършва на два етапа. Клиентът може да освободи разплащанията, преди да освободи всички останали месечни оценки.",
  "config.hr.releaseSteps.twoSteps.title": "в две стъпки",
  "config.hr.requireTwoReportReleases": "Изисква се одобряване на отчетите от двама потребители (принцип на двойния контрол)",
  "config.hr.sendPayroll.description": "Подаване на отчети за доходите по електронен път",
  "config.hr.sendPayroll.title": "Електронен регистър на приходите",
  "config.hr.sendPayroll.tooltip": "Когато се издава фиш за заплата, всеки служител автоматично получава достъп до приложението TPA Connect, което може да се използва за преглед на собствените му записи за доходи и електронното досие на персонала.",
  "config.hr.subsidiaries.add": "Добавяне на област за заплати",
  "config.hr.subsidiaries.info": "След избора на първата счетоводна област могат да се избират само други от същия тип (напр. главна разходна част). За да се избере нов тип (напр. кръг FIBU), трябва да се изтрият всички счетоводни области с изключение на една.",
  "config.hr.subsidiaries.title": "Определяне на областите на заплащане",
  "config.hr.subsidiaries.title.customer": "Счетоводни вериги",
  "config.hr.subsidiary.maxReached": "Достигнат максимален брой счетоводни вериги",
  "config.hr.subsidiary.name": "Наименование на счетоводната област",
  "config.hr.upload.description": "Наемните служители могат да качват документи в личното си досие",
  "config.hr.upload.title": "Качване на документи в досието на персонала",
  "config.hr.warning.noBranch": "Заплатите не могат да бъдат активирани. Моля, създайте компания за заплати в NTCS",
  "config.moduleTypeDetails.asp.helpInstructions": "Инструкции за клиентите на ASP",
  "config.moduleTypeDetails.asp.helpInstructions.open": "Към инструкциите",
  "config.moduleTypeDetails.label.database": "База данни",
  "config.moduleTypeDetails.label.dataSource": "Източник на данни",
  "config.moduleTypeDetails.label.url": "URL",
  "config.noDueDate": "Без краен срок",
  "config.optionalMenuPoints.success.title": "Елементите на менюто са успешно запазени",
  "config.overview": "Преглед на конфигурацията",
  "config.permission.change": "променено на \"{разрешение}\"",
  "config.permissions.accounting.globalReports": "Счетоводни отчети за цялата компания",
  "config.permissions.deleted": "(изтрито)",
  "config.permissions.description": "Изберете областите, до които потребителят има право на достъп. Можете да направите различни настройки за всеки код на компанията.",
  "config.permissions.employees": "Служители",
  "config.permissions.existingUser.navbar.back": "Преглед на конфигурацията / Администриране на потребителите / Присвояване на роля",
  "config.permissions.existingUser.navbar.backToUser": "Преглед на конфигурацията / Администриране на потребители / Присвояване на роля / {име на служител}",
  "config.permissions.global": "В цялата компания",
  "config.permissions.internationalProjects.info": "Проекти Разрешенията не могат да бъдат оттегляни: Членовете на международни проекти ги получават автоматично.",
  "config.permissions.navbar.back": "Преглед на конфигурацията / Администриране на потребителите / Избор на лице за контакт / Присвояване на роля",
  "config.permissions.navbar.backToUser": "Преглед на конфигурацията / Администриране на потребители / Избор на лице за контакт / Присвояване на роля / {име на служител}",
  "config.permissions.noPermissionSelected.message": "Изберете поне едно разрешение.",
  "config.permissions.noPermissionSelected.title": "Не е избрано разрешение",
  "config.permissions.title": "Адаптиране на разрешенията",
  "config.roles.change": "Ролята е променена от {oldRole} на {newRole}",
  "config.roles.customizePermissions": "Адаптиране на разрешенията",
  "config.roles.description": "Изберете роля с предварително дефиниран набор от разрешения. Ако е необходимо, можете да коригирате разрешенията в следваща стъпка.",
  "config.roles.multipleCompaniesDialog.message": "Потребителят {име} съществува в следните компании. Искате ли да направите промени в оторизацията във всички компании?",
  "config.roles.multipleCompaniesDialog.title": "Прилагане на промени в няколко компании",
  "config.roles.multipleCompaniesDialog.warning": "Компаниите не могат да бъдат заредени. Промените се прилагат само за текущата компания.",
  "config.roles.navbar.back": "Преглед на конфигурацията / Администриране на потребителите / Избор на лица за контакт",
  "config.roles.navbar.heading": "{employeeCount} Избран потребител",
  "config.roles.option.copy.description": "Въведете името на потребителя, за да поемете неговата роля и разрешения. Ако е необходимо, можете да коригирате разрешенията в следващата стъпка.",
  "config.roles.option.copy.label": "Поемане на роля от друг потребител",
  "config.roles.option.copy.placeholder": "Потребителско име",
  "config.roles.releaseInformation": "За да редактирате потребителските настройки на <b>{име на потребителя}</b>, <b>суперадминистратор</b> трябва да одобри/отхвърли промените.",
  "config.roles.releaseInformation.info.message": "Ако някой (освен суперадминистратора) направи промени в потребителските настройки, тези промени трябва да бъдат разрешени или отхвърлени от суперадминистратора. Дотогава настройките на потребителя са заключени и не могат да бъдат редактирани.",
  "config.roles.releaseInformation.info.title": "Защо настройките на потребителя не могат да се редактират?",
  "config.roles.releaseInformation.superAdmin": "{име} е направил промени в <b>{име на потребителя}</b>. Моля, одобрете промените на потребителя, за да продължите.",
  "config.roles.sidebar.title": "Избрани потребители",
  "config.roles.submit": "Предложете потребител",
  "config.roles.submit.existingUser": "Предлагане на промени",
  "config.roles.submit.superAdmin": "Поканете потребител",
  "config.roles.title": "Задаване на роля",
  "config.roles.viewPermissions": "Преглед на разрешенията",
  "config.terms.allSigneesAcceptedWarningDialog.confirmLabel": "Добре, запишете",
  "config.terms.allSigneesAcceptedWarningDialog.message": "Внимание: Тъй като всички добавени лица вече са се подписали, дружеството ще бъде активирано след запазване.",
  "config.terms.allSigneesAcceptedWarningDialog.title": "Запазване на условията за ползване",
  "config.terms.alreadySigned": "Условията за ползване на тяхната компания вече са подписани.",
  "config.terms.button.revokeActivation": "Отмяна на активирането",
  "config.terms.changeDocumentVersionDialog.confirmLabel": "Да, променете документа",
  "config.terms.changeDocumentVersionDialog.message": "Ако продължите, всички вече добавени имейл адреси трябва отново да приемат условията за ползване.",
  "config.terms.changeDocumentVersionDialog.title": "Документ за промяна",
  "config.terms.deleteEmailDialog.confirmLabel": "Да, изтрийте имейл адреса",
  "config.terms.deleteEmailDialog.message": "Наистина ли искате да изтриете имейл адреса?",
  "config.terms.deleteEmailDialog.title": "Изтриване на имейл адрес",
  "config.terms.documentVersion": "Версия на документа",
  "config.terms.emailAddressesInfo": "Добавете електронните адреси на желаните лица, които ще бъдат помолени по електронна поща да подпишат съответните условия за ползване. Добавяйте само електронните адреси на упълномощените за подписване лица! Условията за ползване се изпращат на посочените по-долу имейл адреси само когато конфигурацията на фирмата и потребителя е завършена.",
  "config.terms.emailAddressesTitle": "Адреси на електронна поща",
  "config.terms.emailAlreadyUsed": "Този имейл адрес вече е добавен",
  "config.terms.invalidEmail": "Невалиден имейл адрес",
  "config.terms.noDocumentsAvailable": "В момента няма налични документи",
  "config.terms.noSigneesAvailable": "Все още няма подписали се лица",
  "config.termsOfUse.navbar.back": "Преглед на конфигурацията / основни данни",
  "config.terms.removeEmail": "Изтриване на имейл адрес",
  "config.terms.resendEmail.header": "Изпратена електронна поща",
  "config.terms.resendEmail.message": "Имейлът за приемане на условията за ползване е изпратен отново",
  "config.terms.resendMail": "Повторно изпращане на имейл",
  "config.terms.revokeActivationDialog.confirmLabel": "да, активирането е отменено",
  "config.terms.revokeActivationDialog.message": "Сигурни ли сте, че искате да отмените активирането на дружеството? Всички регистрирани лица трябва да подпишат отново условията за ползване, за да активират отново компанията.",
  "config.terms.revokeActivationDialog.title": "Отмяна на активирането",
  "config.terms.signedVersion": "подписана версия:",
  "config.terms.skip.button": "прескочете",
  "config.terms.skip.dialog.message": "Потребителите от страна на клиента могат да влязат в системата само след като са приели условията за ползване. Дотогава компанията е достъпна само за вътрешни за TPA цели в приложението Connect.",
  "config.terms.skip.dialog.title": "Информация",
  "config.terms.success.header": "Успешно запазен",
  "config.terms.termsOfUseInfo": "Изберете подходящата версия на документа",
  "config.terms.termsOfUseTitle": "Условия за ползване",
  "config.users": "Администриране на потребителите",
  "config.users.active.title": "Активни потребители",
  "config.users.assignRole": "Задаване на роля",
  "config.users.buttonConfig": "Управление на потребители",
  "config.users.changeUsername": "Нов имейл",
  "config.users.changeUsername.tooltip": "Новият имейл адрес на потребителя е {newUsername}. С натискане на бутона от потребителя се иска да потвърди новия имейл адрес.",
  "config.users.description": "Присвояване на потребители или групи потребители към отделни процеси. Вие определяте кой има достъп до кои области и в каква степен.",
  "config.users.edit": "Промяна",
  "config.users.emptyState.message": "В момента няма активни потребители{br}За да създадете суперадминистратор, моля, използвайте BMD NTCS.",
  "config.users.emptyState.title": "Няма активни потребители",
  "config.users.inviteAgain": "Поканете отново",
  "config.users.navBar.back": "Преглед на конфигурацията / управление на потребителите",
  "config.users.new": "Нов",
  "config.users.newUsers": "Създаване на нов потребител",
  "config.users.newUsers.description": "Изберете лицата за контакт, които искате да създадете като потребители на TPA Connect. В следващата стъпка можете да зададете роля на избраните лица и да коригирате предварително определените разрешения, ако е необходимо.",
  "config.users.newUsers.title": "Изберете лице за контакт",
  "config.users.reject": "Спад",
  "config.users.release": "Споделете",
  "config.users.releaseAndInvite": "Споделяне и покана",
  "config.users.table.invalidEmail": "Невалиден имейл адрес",
  "config.users.table.invalidEmail.tooltip": "Адресът на електронната поща трябва да бъде коригиран от консултант на TPA в BMD",
  "config.users.waiting.title": "В очакване на освобождаване",
  "cookies.acceptAll": "Разрешаване на всички бисквитки",
  "cookies.acceptFunctional": "Използвайте само необходимите бисквитки",
  "cookies.cookieList": "Декларация за бисквитки",
  "cookies.text": "Използваме \"бисквитки\", за да оптимизираме услугата си. Можете да намерите повече информация за това в {връзка}.",
  "country.al": "Албания",
  "country.at": "Австрия",
  "country.bg": "България",
  "country.cz": "Чешка република",
  "country.hr": "Хърватия",
  "country.hu": "Унгария",
  "country.me": "Черна гора",
  "country.pl": "Полша",
  "country.ro": "Румъния",
  "country.rs": "Сърбия",
  "country.si": "Словения",
  "country.sk": "Словакия",
  "deleteDialog.message.plural": "Наистина ли искате да изтриете документите <b>{documents}</b>?{br}{br}Това действие не може да бъде отменено.",
  "deleteDialog.message.singular": "Наистина ли искате да изтриете документа <b>{documents}</b>?{br}{br}Това действие не може да бъде отменено.",
  "deleteDialog.title": "Изтриване на документи?",
  "deleteUser.message": "Сигурни ли сте, че искате да изтриете потребителя <b>{име}</b>?",
  "deleteUser.multipleCompanies.confirmLabel": "Изтриване на потребителя във всички компании",
  "deleteUser.multipleCompanies.message": "Потребителят е представен на следните компании:",
  "deleteUser.title": "Изтриване на потребител",
  "deleteUser.warning.superadminskipped": "Внимание! Компаниите, в които потребителите имат ролята Superadmin, се пропускат при изтриване.",
  "dialog.archive.checkbox_pdf": "Записване на PDF локално",
  "dialog.archive.subject": "Тема",
  "dialog.archive.success.text": "Сега можете да получите достъп до архивираните съобщения в {bmd}.",
  "dialog.archive.success.title": "Съобщенията са успешно архивирани!",
  "dialog.archive.text": "Изберете компанията, за която искате да съхраните избраните съобщения в BMD.",
  "dialog.archive.title_multiple": "{count} Архив съобщения",
  "dialog.archive.title_single": "1 Архивно съобщение",
  "dialog.assignTicket.header": "Задаване на запитване",
  "dialog.assignTicket.message": "Изберете лицето, на което искате да възложите консултацията.",
  "dialog.button.understood": "Разбрах",
  "dialog.closeTicket.success.header": "Проведена консултация",
  "dialog.companyConfig.success.header": "Конфигурацията на фирмата е запазена успешно.",
  "dialog.companyConfig.success.message": "Областите за {име} вече са готови.",
  "dialog.eldaTransfer.andNext": " и още",
  "dialog.eldaTransfer.downloadLater": "Изтегляне по-късно",
  "dialog.eldaTransfer.success.header": "Успешно подадено заявление",
  "dialog.loginError.multiFactorCodeWrong.title": "Въведеният код за съжаление е грешен",
  "dialog.loginError.subtitle": "Моля, опитайте отново.",
  "dialog.loginError.title": "Неизвестен имейл адрес или грешна парола",
  "dialog.noPhoneNumberWarning.message": "Поне един от избраните контакти няма зададен телефонен номер. Без телефонен номер двуфакторното удостоверяване не е достъпно и поканата може да не е сигурна.",
  "dialog.noPhoneNumberWarning.title": "Няма зададен телефонен номер",
  "dialog.passwordExpired.message": "Паролата ви е изтекла и трябва да бъде променена. Скоро ще получите имейл за промяна на паролата си. Ако имате някакви въпроси, моля, свържете се с TPA на {телефонен номер}. Много ви благодаря!",
  "dialog.passwordExpired.title": "Паролата е изтекла",
  "dialog.password_reset.success.header": "Паролата е успешно променена",
  "dialog.registration.success.button": "Влезте сега",
  "dialog.registration.success.header": "Успешно сте се регистрирали!",
  "dialog.registration.success.message": "Влезте в Microsoft, за да използвате TPA Connect.",
  "dialog.transferReports.success.message": "Сега клиентът ви ще прегледа файловете и ще се свърже с вас при евентуални запитвания.",
  "dialog.transfer.success.header": "Файловете са успешно предадени",
  "dialog.userConfig.success.header": "Запазване на потребителските настройки",
  "dialog.user_not_found.button": "Разбрах",
  "dialog.user_not_found.subtitle": "Свържете се с администратора на системата или влезте в профила си в TPA Connect.",
  "dialog.user_not_found.title": "Не е известен потребителски акаунт с този имейл адрес",
  "documentUpload.error.amount": "<b>{{failedUploads}</b> от {uploads} файловете не могат да бъдат качени:",
  "documentUpload.error.button.text.continue": "Продължете без тези файлове",
  "documentUpload.error.button.text.retry": "Опитайте отново",
  "documentUpload.error.message": "Грешка при качване на файл",
  "documentUpload.error.rejection.amount": "<b>{{failedUploads}</b> от {uploads} файловете не могат да бъдат качени:",
  "documentUpload.error.tooBigImages.amount": "<b>{failedUploads}</b> Изображенията са по-големи от макс. {maxImageDimension} пиксели. Размерът им ще бъде автоматично намален. Продължете?",
  "documentUpload.error.tooBigImages.amount.singular": "Изображението е по-голямо от макс. {maxImageDimension} пиксели. Размерът му се намалява автоматично. Продължете?",
  "documentUpload.error.tooBigImages.button.text.continue": "Намаляване и продължаване",
  "documentUpload.error.tooBigImages.pdf": "Изображенията в PDF файла са по-големи от макс. {maxImageDimension} пиксели. Моля, намалете размера на изображенията.",
  "documentUpload.error.tooManyFiles.plural": "Могат да бъдат качени максимум <b>{count}</b> файлове:",
  "documentUpload.error.tooManyFiles.singular": "Може да се качи максимум един <b>файл</b>:",
  "documentUpload.max.size": "Максимален размер на документа {maxUploadSizeMb} Mb",
  "documentUpload.tooBigImages.message": "Твърде големи изображения",
  "documentUpload.type.file.formats": "Поддържани формати",
  "dropzone.button.label.search": "потърсете го.",
  "dropzone.image.text": "Просто плъзнете изображението в тази област или",
  "dropzone.info.redo": "Качете отново",
  "dropzone.info.text": "Просто плъзнете и пуснете файловете в тази област или",
  "dueDate.answerAt": "да бъде отговорено до {дата}",
  "dueDate.closedAt": "завършен на {дата}",
  "dueDate.sentAt": "изпратен на {дата}",
  "dueDate.sentAtWithTime": "изпратен на {дата} в {време}",
  "dueDate.transferredAt": "предадени на {дата}",
  "dueDate.until": "до {дата}",
  "employmentInsuranceType.apprentice": "Стажант",
  "employmentInsuranceType.fullTime": "напълно застрахован",
  "employmentInsuranceType.independentContractor": "безплатно DN",
  "employmentInsuranceType.marginalIndependentContractor": "Незначителен свободен DN",
  "employmentInsuranceType.marginally": "незначително",
  "employmentRelationship.employee": "Служител",
  "employmentRelationship.worker": "Работници",
  "error.accounting.postSubsidiary": "Кодът на фирмата не може да бъде създаден",
  "error.accounting.subsidiaryDoesNotExist": "Кодът на компанията не е намерен",
  "error.activate": "Грешка при активиране",
  "error.addBankAccountTransactionInvoices": "Транзакцията и документите не могат да бъдат свързани",
  "error.addBankConnection": "Банковите данни не могат да бъдат добавени",
  "error.addCompanyToUserFavorites": "Компанията не може да бъде добавена към любимите ви",
  "error.addFolderBadRequest": "Папката не може да бъде създадена. Уверете се, че не сте използвали неразрешени специални символи: \" * : < > ? / \\ |",
  "error.archiveDisabledForCompany": "Архивирането не е възможно с тази компания",
  "error.archiveFailed": "Грешка по време на архивирането",
  "error.archiveProjectFailed": "Проектът не може да бъде архивиран",
  "error.avatarUploadFailed": "Профилната снимка не може да бъде качена. Уверете се, че форматът е JPEG или PNG, а размерът на файла е максимум {maxSize}MB",
  "error.avatarUploadOnlyOneImage": "Можете да качите само едно изображение",
  "error.changeUsernameEmail": "Електронната поща за промяна на потребителското име не може да бъде изпратена",
  "error.changeUsername.newUsernameConflict": "Новият имейл не съвпада със съхранения имейл.",
  "error.changeUsername.newUsernameDuplicate": "Вече използвана електронна поща.",
  "error.changeUsername.noNewUsername": "Не е зададен нов имейл адрес.",
  "error.changeUsername.tokenExpired": "Тази връзка е изтекла.",
  "error.changeUsername.tokenNotFound": "Връзката е невалидна, дали новият имейл адрес вече е потвърден?",
  "error.chatForbidden": "В тази компания не се активира услугата \"лице в лице\".",
  "error.chatNoCompany": "Съобщение без контекста на компанията.",
  "error.chatNoRecipient": "Съобщението няма получател.",
  "error.chatSearch": "Търсенето е неуспешно. Моля, опитайте отново.",
  "error.completeSign": "Електронният подпис не може да бъде попълнен",
  "error.config.accounting.duplicate": "Въвели сте няколко кода на фирми с едно и също име",
  "error.config.accounting.noSubsidiary": "Моля, задайте поне един код на фирма, за да продължите.",
  "error.config.activateCompanyFailed": "Този клиент все още не е технически подготвен за използването на TPA Connect. Свържете се с ИТ поддръжката, за да бъде създаден подходящ потребител.",
  "error.config.corruptSubsidiary": "Код на фирмата без ID",
  "error.config.hr.duplicate": "Посочили сте няколко области за заплати с едно и също име",
  "error.config.hr.noSubsidiary": "Моля, задайте поне една счетоводна област, за да продължите.",
  "error.copy.alreadyExists.file": "Грешка при копиране: Файл с това име вече съществува",
  "error.copy.alreadyExists.folder": "Грешка при копиране: Папка с това име вече съществува",
  "error.copy.alreadyExists.unknown": "Грешка при копиране: Папка или файл с това име вече съществува",
  "error.create": "Грешка при създаването",
  "error.create.alreadyExists.project": "Проект с това име вече съществува",
  "error.createEmployee": "Служителят не може да бъде създаден",
  "error.delete": "Грешка при изтриване",
  "error.deleteBankAccountTransactionInvoices": "Връзката не може да бъде изтрита",
  "error.deleteBankConnection": "Банковите данни не могат да бъдат изтрити",
  "error.delete.fileLocked": "Файлът в момента се редактира и затова не може да бъде изтрит.",
  "error.deleteUser": "Потребителят не може да бъде изтрит. Моля, опитайте отново в по-късен момент.",
  "error.deregisterEmployee": "Служителят не може да бъде изписан",
  "error.documentForbidden": "Нямате достъп до документа",
  "error.documentInProgress": "Документът се изпраща на TPA и не може да бъде изтеглен. Опитайте отново след малко.",
  "error.documentNotFound": "Документът не е намерен",
  "error.documentsInProgress": "Документите се изпращат на TPA и не могат да бъдат изтеглени. Опитайте отново след малко.",
  "error.download": "Грешка при изтегляне",
  "error.downloadTermsOfUseDocument": "Възникна грешка при изтеглянето на условията за ползване",
  "error.download.transferInProgress": "Документът се прехвърля. Моля, опитайте отново малко по-късно.",
  "error.dueDate.minDate": "Датата на крайния срок не трябва да е минала.",
  "error.duplicateName": "Вече зададено име",
  "error.duplicateUsername": "Новоназначеният имейл адрес вече се използва",
  "error.edit": "Грешка по време на редактиране",
  "error.employeeWithoutEmail": "Служителят няма валиден имейл адрес",
  "error.folderIsEmpty": "Папката е празна и затова не може да бъде изтеглена",
  "error.foldersAreEmpty": "Всички избрани папки са празни и затова не могат да бъдат изтеглени",
  "error.forbidden": "Няма разрешение",
  "error.general": "Съжаляваме, възникна грешка. Моля, опитайте отново по-късно.",
  "error.getSign": "Възникна грешка при зареждането на необходимата информация за електронния подпис",
  "error.hr.noSubsidiarySelected": "Не е избрана област на заплащане",
  "error.hr.postSubsidiary": "Не може да бъде създадена област за заплати",
  "error.hr.subsidiaryDoesNotExist": "Областта за заплати не е намерена",
  "error.imageCrop": "Грешки при обработката на изображения",
  "error.imageTooBig": "Твърде голяма картина",
  "error.invalidCharacters": "Входът съдържа невалидни символи",
  "error.invalidCompanyId": "Невалиден идентификатор на компанията",
  "error.invalid_credentials": "Неправилно потребителско име или парола",
  "error.invalidDateFormat": "Невалиден формат на датата",
  "error.invalidEmployee": "Данните на служителя са непълни или неправилни",
  "error.invalidPeriodId": "Невалиден период за тази компания",
  "error.invalidRolePermissions": "Разрешенията за роли са невалидни",
  "error.invalidSsn": "Невалиден национален осигурителен номер",
  "error.invalidTicket": "Невалиден билет",
  "error.invalidWebhookParams": "Невалидни параметри на webhook.",
  "error.invitationNoCompany": "Покана без идентификационен номер на компанията",
  "error.linkExpired": "Тази връзка е изтекла. Моля, поискайте нов.",
  "error.linkInvalid": "Тази връзка е невалидна.",
  "error.loadAccount": "Акаунтът не може да бъде зареден",
  "error.loadAccountListing": "Балансовият списък не може да бъде зареден",
  "error.loadAccounts": "Сметките не могат да бъдат заредени",
  "error.loadAccountTransactions": "Резервациите не могат да бъдат заредени",
  "error.loadArchiveDocuments": "Не всички архивни документи могат да бъдат заредени",
  "error.loadBadges": "Грешка при зареждане на известия",
  "error.loadBalanceSheet": "Балансът не може да бъде зареден",
  "error.loadBankAccountTransactions": "Транзакциите не могат да бъдат заредени",
  "error.loadBankConnections": "Банковите данни не могат да бъдат заредени",
  "error.loadBranches": "Клоновете не могат да бъдат заредени",
  "error.loadCashAccounting": "Сметката за приходите и разходите не може да бъде заредена",
  "error.loadCompany": "Компанията не може да бъде заредена",
  "error.loadCompanyFeatures": "Добавките не могат да бъдат заредени",
  "error.loadContacts": "Контактите не могат да бъдат заредени",
  "error.loadCostCenters": "Центровете за разходи не могат да бъдат заредени",
  "error.loadCountries": "Данните за страната не могат да бъдат заредени",
  "error.loadCustomerListing": "Списъкът на длъжниците не може да бъде зареден",
  "error.loadDestinations": "Местоположенията на заетите лица не могат да бъдат заредени. Моля, опитайте отново по-късно",
  "error.loadDocument": "Документът не може да бъде зареден",
  "error.loadDocuments": "Документите не могат да бъдат заредени",
  "error.loadDraft": "Изданието не може да бъде заредено",
  "error.loadEmployee": "Служителят не може да бъде зареден",
  "error.loadEmployeeDocuments": "Файловете не могат да бъдат заредени",
  "error.loadEmployees": "Служителите не могат да бъдат поканени",
  "error.loadEvents": "Събитията не могат да бъдат заредени, опитайте отново по-късно",
  "error.loadFeatureFlags": "Флаговете на функциите не могат да бъдат заредени",
  "error.loadFeedCountries": "Страните не могат да бъдат заредени.",
  "error.loadFinancialAccountancies": "",
  "error.loadFormData": "Данните от формуляра не могат да бъдат заредени",
  "error.loadFunders": "Разходната единица не може да бъде заредена",
  "error.loadGeneralDocumentsTimeout": "Докладите са временно недостъпни. Моля, опитайте отново по-късно.",
  "error.loading": "Грешка при зареждане",
  "error.loadInsuranceCarrierBalance": "Грешка при зареждане на здравноосигурителния баланс",
  "error.loadInsuranceCarriers": "Грешка при зареждане на здравноосигурителните фондове",
  "error.loadInvitationResults": "Грешка при зареждане на освободените промени на потребителя",
  "error.loadKpiConfigurations": "Конфигурацията не може да бъде заредена",
  "error.loadKpis": "Фактите и цифрите не можаха да бъдат заредени, опитайте отново по-късно",
  "error.loadMessages": "Съобщенията не могат да бъдат заредени",
  "error.loadModuleTypeDetails": "Грешка при зареждането на детайлите на модула",
  "error.loadNews": "Съобщенията не могат да бъдат заредени, опитайте отново по-късно",
  "error.loadNews.noSource": "Новинарският канал в момента не е достъпен.",
  "error.loadNotifications": "Настройките за известия не могат да бъдат заредени",
  "error.loadOptionalMenuItems": "Елементите на допълнителното меню не могат да бъдат заредени",
  "error.loadPayrollAccount": "Сметката за заплати не може да бъде заредена",
  "error.loadPayrollAccountDetails": "Данните за сметката за заплати не могат да бъдат заредени",
  "error.loadPendingMicrosoftActions": "Информацията за поканите и освежаването не може да бъде заредена",
  "error.loadPeriod": "Грешка при зареждане на периода",
  "error.loadPeriodData": "Грешка при зареждане на данните за периода",
  "error.loadPermissions": "Разрешенията не могат да бъдат заредени",
  "error.loadPersonnelFile": "Файлът на персонала не може да бъде зареден",
  "error.loadPersonnelFile.notStaff": "Потребителят не получава заплата",
  "error.loadPowerBIReportEmbedToken": "",
  "error.loadPowerBIReports": "",
  "error.loadPreRegistration": "Предварителният запис не може да бъде зареден.",
  "error.loadProfile": "Профилът не може да бъде зареден",
  "error.loadProfitAndLoss": "G&V не може да бъде заредена",
  "error.loadProjects": "Проектите не могат да бъдат заредени",
  "error.loadProjectsCompanies": "Фирмите за проекти не могат да бъдат заредени",
  "error.loadPromotions": "Елементите не могат да бъдат заредени, опитайте отново по-късно",
  "error.loadPublications": "Публикациите не могат да бъдат заредени, опитайте отново по-късно",
  "error.loadRecord": "Документът не може да бъде зареден",
  "error.loadRecords": "Квитанциите не могат да бъдат заредени",
  "error.loadRecordTypes": "Грешка при зареждане на типовете резервации",
  "error.loadReports": "Отчетите не могат да бъдат заредени",
  "error.loadResponsibleUsers": "Съветниците не могат да бъдат заредени",
  "error.loadRolePermissions": "Грешка при зареждане на разрешенията за роли",
  "error.loadSubsidiaries": "Грешка при зареждане на кодовете на фирмите",
  "error.loadSubsidiary": "Грешка при зареждане на кода на компанията",
  "error.loadTeam": "Членовете на екипа не могат да бъдат заредени",
  "error.loadTermsofUse": "Възникна грешка при зареждането на условията за ползване",
  "error.loadTicketDetails": "Консултацията не може да бъде заредена",
  "error.loadTickets": "Заявките не могат да бъдат заредени",
  "error.loadUserCompanySettings": "Грешка при зареждане на потребителските настройки",
  "error.loadUsers": "Потребителите не могат да бъдат заредени",
  "error.loadVendorListing": "Списъкът на доставчиците не може да бъде зареден",
  "error.loadWaitingUsers": "Грешка при зареждане на потребители, които да бъдат освободени",
  "error.loginAccountSetup": "Тук сте за първи път? Подготвяме потребителския ви акаунт. Моля, опитайте отново след няколко минути.",
  "error.logout": "Грешка при излизане от системата",
  "error.markAsDone": "Можете да маркирате като завършени само заявките, които сте създали.",
  "error.markBankAccountTransactionAsPrivate": "Транзакцията не може да бъде маркирана като частна",
  "error.maxCharacters": "Максимален брой разрешени знаци {count}",
  "error.mfaActivation.codeNotMatched": "Въведеният код е грешен",
  "error.mfaCodeAlreadySent": "Кодът вече е изпратен, моля, изчакайте поне 20 секунди, преди да поискате следващия.",
  "error.mfaCodeWrong": "Съжаляваме, но кодът, който сте въвели, е грешен. Моля, опитайте отново.",
  "error.mfaSettingChange": "2FA не може да се активира или деактивира, тъй като вече е поискан код за активиране. Моля, изчакайте поне 20 секунди, преди да опитате отново.",
  "error.mfaSettingChange.codeAlreadySent": "Вече е поискан нов код. Моля, изчакайте поне 20 секунди, преди да опитате отново.",
  "error.mfaSettingChange.codeNotMatched": "Въведеният код е грешен",
  "error.microsoftLogin": "Възникна проблем при комуникацията с Microsoft. Моля, опитайте да влезете отново.",
  "error.missingDocument": "Липсващ документ",
  "error.missingEmail": "Липсващ адрес на електронна поща",
  "error.missingEmployee": "Липсващ служител",
  "error.missingParams": "Липсващи параметри",
  "error.move": "Грешка при преместване",
  "error.move.alreadyExists.unknown": "Грешка при преместване: Папка или файл с това име вече съществува",
  "error.msTokenExpired": "Срокът на валидност на вашия MS токен е изтекъл. Моля, подновете го.",
  "error.multipleArchives": "Няколко налични архива на клиенти на тази компания",
  "error.noArchive": "Няма наличен архив на клиенти за тази компания",
  "error.noCompanySelected": "Не е избрана компания",
  "error.noDocumentSelected": "Моля, изберете документ за условията за ползване",
  "error.noDotAtEnd": "Последният символ не трябва да е точка.",
  "error.noFinancialAccountancies": "Дружеството няма FIBUs",
  "error.noMsAccountAvailable": "Потребителят не може да бъде поканен. Очаква се персоналът на TPA вече да има акаунт в Microsoft.",
  "error.noRecordTypes": "Не са намерени категории документи",
  "error.noSignees": "Моля, добавете имейл адреси",
  "error.noSubsidiaries.accounting": "Не са намерени кодове на компании",
  "error.noSubsidiaries.hr": "Не са намерени счетоводни области",
  "error.noSubsidiaryWithEmployeeRead": "Няма кръг на ведомост с права за четене от служителите.",
  "error.notAdvisor": "Няма разрешения за консултанти",
  "error.notAllowed": "Действието не трябва да се извършва",
  "error.notificationCenter.load": "Известията не могат да бъдат заредени",
  "error.notificationCenter.markAllAsRead": "Известията не могат да бъдат маркирани като прочетени",
  "error.notificationCenter.markAsRead": "Известието не може да бъде отбелязано като прочетено",
  "error.oauthApi": "Имаше проблем с удостоверяването. Моля, опитайте отново.",
  "error.oauthCompany": "Няма избрана компания. Моля, изберете компания и опитайте отново.",
  "error.oauthUrl": "Имаше проблем при извикването на \"Факти и цифри\". Моля, опитайте отново.",
  "error.overlappingSignature": "Припокриващ се подпис. Моля, изберете нова позиция",
  "error.passwordReset.tokenExpired": "Тази връзка за възстановяване на паролата е изтекла",
  "error.passwordReset.tokenNotFound": "Линк за възстановяване на невалидна парола",
  "error.payAccountTransaction": "",
  "error.payTaxAccount": "Грешки при плащането на данъчното задължение",
  "error.payUnpaidAccount": "Грешка при плащане на фактура на TPA",
  "error.periodClosed": "Вече приключил период",
  "error.permission.corruptRoles": "Невалидни роли",
  "error.permission.onlyOneRoleAllowed": "Може да се зададе само едно хвърляне",
  "error.postTicket": "Консултацията не може да бъде създадена.",
  "error.projectForbidden": "Нямате достъп до проекти",
  "error.projectIsEmpty": "Проектът е празен и затова не може да бъде изтеглен",
  "error.projectPermissions": "Разрешенията на проекта не могат да бъдат заредени",
  "error.projects.cantMove": "Файловете не могат да бъдат преместени. Моля, опитайте отново по-късно.",
  "error.projects.cantPaste": "Файловете не могат да бъдат вмъкнати. Моля, опитайте отново по-късно.",
  "error.projects.openBookmarkUrlFailed": "URL адресът не може да бъде отворен",
  "error.qes.missingSignaturePositions": "Моля, добавете необходимите позиции за подпис",
  "error.recordForbidden": "Нямате достъп до ваучера",
  "error.recordWithNotAllowedCompany": "Няма разрешение за дружеството, за което е издаден документът.",
  "error.refreshBankConnection": "Банковата сметка не може да бъде подновена",
  "error.releaseEmployeeDocument": "Грешка при освобождаване",
  "error.releaseReport": "Грешка при освобождаване",
  "error.removeBankAccountTransactionPrivateMark": "Не може да премахнете избора",
  "error.removeCompanyFromUserFavorites": "Компанията не може да бъде премахната от любимите ви",
  "error.rename.alreadyExists.file": "Грешка при преименуването: Файл с това име вече съществува",
  "error.rename.alreadyExists.folder": "Грешка при преименуване: Папка с това име вече съществува",
  "error.requiredField": "Необходим вход",
  "error.resendTermsOfUseEmail": "При повторното изпращане на имейла е възникнала грешка",
  "error.save": "Грешка при запазване",
  "error.saveKpiConfigurations": "Фактите и цифрите Конфигурацията не може да бъде запазена",
  "error.saveOptionalMenuItems": "Допълнителните елементи на менюто не могат да бъдат заредени. Моля, опитайте отново по-късно",
  "error.saveTermsOfUse": "Възникна грешка при запазването на условията за ползване. Моля, опитайте отново по-късно",
  "error.send": "Грешка при изпращане.",
  "error.setDocumentOrSignees": "Възникнала е грешка при записването на документа или на лицата, които трябва да бъдат подписани",
  "error.settings": "Грешка при промяна на настройките",
  "error.signFileTooLarge": "Файлът е твърде голям и не може да бъде подписан електронно.",
  "error.signReadOnlyDocument": "Документът е само за четене и следователно не може да бъде подписан.",
  "error.signTermsOfUse": "Възникна грешка при приемането на условията за ползване. Моля, опитайте отново по-късно",
  "error.subsidiaryWithoutRecordTypes": "{subsidiary} няма активни символни документи",
  "error.termsOfUseDuplicateFile": "Същият файл с различно име вече съществува",
  "error.termsOfUseFolderNotFound": "Все още не е създадена папка с условията за ползване",
  "error.timeout": "В момента този процес отнема малко повече време. Моля, опитайте отново по-късно.",
  "error.toggleCompanyFeature": "Грешка при активиране/деактивиране на добавката",
  "error.tokenRefresh": "Грешка при удостоверяване на автентичността",
  "error.tooManyCharacters": "Максимален брой {maxCharacters} символи, разрешени за едно съобщение.",
  "error.transfer": "Грешка при прехвърляне",
  "error.transferEmployee": "Служителят не може да бъде прехвърлен",
  "error.transferEmployeeDirect": "Предаването на средствата на здравноосигурителната каса е неуспешно. Моля, свържете се с вашия съветник.",
  "error.transferEmployee.validation.general": "",
  "error.transferEmployee.validation.general.zipcode": "",
  "error.unknown": "Неизвестна грешка ({statusCode})",
  "error.unsupportedDocumentType": "Този тип документ не се поддържа",
  "error.updateBankAccountAccountNumber": "Акаунтът не може да бъде запазен.",
  "error.upgradeToMicrosoft": "Надстройката не може да бъде извършена. Моля, проверете паролата си.",
  "error.upload": "Грешка при качване",
  "error.userDeactivated": "Този потребител на Microsoft е деактивиран.",
  "error.userNotRegistered": "Потребителят не е регистриран",
  "error.userUnknown": "Неизвестен потребител",
  "externalLinkDialog.content": "",
  "externalLinkDialog.title": "",
  "failedUserDialog.amount": "<b>{{failedUserCount}</b> от {userCount} потребителите не могат да бъдат създадени/променени:",
  "failedUserDialog.button.text.continue": "Продължи въпреки това",
  "failedUserDialog.button.text.retry": "Опитайте отново",
  "failedUserDialog.error.emailAlreadyExists": "Вече използван имейл адрес",
  "failedUserDialog.error.pendingPermissionChanges": "Вече чакаме за пускане",
  "failedUserDialog.message": "Грешка при създаване/промяна",
  "features.default.prices.from": "",
  "features.default.prices.fromFlat": "",
  "features.default.prices.fromTo": "",
  "features.default.prices.fromToFlat": "",
  "filterDialog.apply": "Приложете филтър",
  "filterDialog.category.advanced": "Разширени филтри",
  "filterDialog.category.assigned": "Присвоена област",
  "filterDialog.header": "Опции за филтриране",
  "filterDialog.required": "Моля, изберете стойност",
  "filterDialog.reset": "ВЪЗСТАНОВЯВАНЕ НА ФИЛТЪРА",
  "finishProjectDialog.text": "Сигурни ли сте, че искате да завършите проекта <b>{име на проекта}</b>?",
  "finishProjectDialog.title": "Завършване на проекта",
  "footer.copyright": "Всички права запазени",
  "footer.disclaimer": "Отказ от отговорност",
  "footer.generalBusinessTerms": "AGB",
  "footer.legalInformation": "Отпечатък",
  "footer.privacy": "Защита на данните",
  "helpcenter.ideas": "Идеи или желания",
  "helpcenter.problems": "Докладване на проблеми",
  "helpcenter.problems.email": "Адрес на електронна поща",
  "helpcenter.problems.send": "Докладване на проблем",
  "helpcenter.problems.upload.failed": "Проблемът не може да бъде предаден",
  "helpcenter.problems.upload.success": "Проблемът беше успешно изпратен",
  "helpcenter.problems.upload.tooManyRequests": "",
  "helpcenter.problems.whathappened": "Какво се случи вместо това?",
  "helpcenter.problems.whatshouldhappen": "Какво е трябвало да се случи?",
  "helpcenter.problems.whatwasdone": "Какво се опита да направи?",
  "helpCenter.title": "Център за помощ",
  "helpcenter.videos": "Видеоклипове за помощ",
  "helpcenter.videos.tpaonly": "Помощни видеоклипове за служителите на TPA",
  "hr.casualEmployment": "Назначаване на работа за всеки отделен случай",
  "hr.disclaimer.additionalData": "За директното предаване все още се нуждаем от няколко допълнителни данни. Моля, попълнете ги.",
  "hr.disclaimer.employmentDate": "Дата на наемане",
  "hr.disclaimer.subtitle": "Искате ли да изпратите данните на TPA или директно на здравноосигурителната компания?",
  "hr.disclaimer.title": "Предаване",
  "hr.disclaimer.transfer.direct": "Предаването на данните се извършва директно на австрийската здравноосигурителна каса.",
  "hr.disclaimer.transfer.direct.subtitle": "Отбелязваме, че TPA ще провери данните едва през следващите няколко дни, най-рано когато офисът е отворен.",
  "hr.disclaimer.transfer.tpa": "Предаването на данни се извършва към TPA",
  "hr.disclaimer.transfer.tpa.subtitle": "Обръщаме внимание, че регистрацията на служителите в Австрийската здравноосигурителна каса ще се извърши едва след като TPA обработи данните.",
  "hr.documents.backLabel": "Персонал/досиета",
  "hr.documents.emptyState.header": " заснемане",
  "hr.documents.emptyState.message": "Започнете да качвате файловете си сега.",
  "hr.documents.list.backLabel": "Персонал/ Документи",
  "hr.documents.nonWorkingTimes": "Времена за неизпълнение",
  "hr.documents.nonWorkingTimesUpload": "Времена за неизпълнение на качването на файлове",
  "hr.documents.upload.navHeader": "{documentType}",
  "hr.documents.upload.navLabel": "Персонал/документи",
  "hr.employee.employment": ". Трудово правоотношение",
  "hr.employee.employmentRelationship": "Трудово правоотношение",
  "hr.employee.jobDescription": "Длъжност",
  "hr.employee.payslip.upload.date": "Период",
  "hr.employee.payslip.upload.title": "Качване на платежна бележка",
  "hr.employee.payslip.upload.type": "Вид на фиша за заплата",
  "hr.employee.payslip.upload.type.monthly": "Месечна ведомост",
  "hr.employee.payslip.upload.type.yearly": "Годишна ведомост за заплати",
  "hr.employee.reasonForLeaving": "Причина за напускане",
  "hr.employee.workplaceState": "Състояние на работното място",
  "hr.finished.analysis": "Месечни оценки",
  "hr.finished.billing": "Месечни отчети",
  "hr.finished.globalAnalysis": "Оценка на цялата компания",
  "hr.finished.globalBilling": "Счетоводно отчитане на цялата компания",
  "hr.insuranceCarrier": "Застраховател",
  "hr.overview.continuousActions": "Непрекъснати функции",
  "hr.recordTypes.names.benefitsInKindCar": "Обезщетения в натура автомобил",
  "hr.recordTypes.names.contractAdjustmentsAndSpecialPayments": "Корекции на договори и специални плащания",
  "hr.recordTypes.names.mealDeductions": "Отчисления за храна",
  "hr.recordTypes.names.nonWorkingTimes": "Времена за неизпълнение",
  "hr.recordTypes.names.overtimeAndBonusPayments": "Изплащане на извънреден труд и бонуси",
  "hr.recordTypes.names.supplementaryDocuments": "Допълнителни документи",
  "hr.recordTypes.names.timeRecords": "Времеви записи",
  "hr.recordTypes.names.travelExpenseReport": "Отчет за разходите за пътуване",
  "hr.tickets.preRegistration.NavBar": "Запитване за {preRegistration}",
  "hr.tickets.preRegistration.NavBarLabel": "Персонал / Служители / Запитвания",
  "hr.tickets.preRegistration.toOverview": "КЪМ ЛИЧНОТО ДОСИЕ",
  "imageCrop.description": "Плъзнете, за да преместите изображението",
  "imageCrop.title": "Позиционно изображение",
  "imageCrop.zoom": "Zoom",
  "language.german": "Немски",
  "maritalStatus.divorced": "разведен",
  "maritalStatus.livingInPartnership": "Живот в партньорство",
  "maritalStatus.married": "Женен/регистрирано партньорство",
  "maritalStatus.separated": "Живот поотделно",
  "maritalStatus.unknown": "неизвестен",
  "maritalStatus.unmarried": "единичен",
  "maritalStatus.widowed": "овдовял",
  "menu.answer": "Отговор",
  "menu.assign": "Задаване на",
  "menu.cancelRelease": "Оттегляне на освобождаването",
  "menu.delete": "Изтриване на",
  "menu.details": "Подробности",
  "menu.download": "Изтегляне",
  "menu.draft": "Искане за одобрение",
  "menu.edit": "Редактиране на",
  "menu.editRelease": "Повторно искане за освобождаване",
  "menu.finish": "Заключване",
  "menu.markAsDone": "Маркирайте като свършено",
  "menu.move": "Преместване",
  "menu.openInBrowser": "Отваряне в браузъра",
  "menu.openInSharepoint": "Отваряне в Sharepoint",
  "menu.rename": "Преименуване на",
  "menu.requestRelease": "Заявка за освобождаване",
  "menu.showReleases": "Показване на освобождавания",
  "menu.view": "Вижте",
  "mfa.activation.dialog.code.required": "Моля, въведете кода",
  "mfa.activation.dialog.description": "Моля, въведете кода, който изпратихме на телефонния ви номер, за да активирате двуфакторното удостоверяване.",
  "mfa.activation.dialog.title": "Активиране на 2FA",
  "mfa.activation.success.dialog.title": "2FA беше успешно активирана",
  "mfa.changeSetting.dialog.code.field.label": "Код",
  "mfa.changeSetting.dialog.resendCode": "Изпратете кода отново",
  "mfa.codeResend.success.dialog.title": "Кодът е изпратен отново",
  "mfa.deactivation.dialog.description": "Моля, въведете кода, който изпратихме на телефонния ви номер, за да деактивирате двуфакторното удостоверяване.",
  "mfa.deactivation.dialog.title": "Деактивиране на 2FA",
  "mfa.deactivation.success.dialog.title": "2FA беше успешно деактивирана",
  "mfa.validation.code.length": "Кодът има 6 цифри",
  "mfa.validation.code.required": "Трябва да се въведе код",
  "mfa.validation.code.type": "Позволени са само числа",
  "microsoft_oauth.button.login.title": "Влезте в системата на Microsoft",
  "microsoft_oauth.button.send_code.title": "Изпращане на код",
  "microsoft_oauth.button.upgrade.title": "Извършване на надграждане",
  "missingEmailDialog.description": "Имейл адресът на <b>{име}</b> не е регистриран. Моля, въведете валиден имейл адрес, за да можем да изпратим поканата.",
  "missingEmailDialog.title": "Липсва адрес на електронна поща",
  "missingEmail.email.error": "Моля, въведете правилен имейл адрес",
  "missingEmail.email.label": "Въведете имейл адрес",
  "module.finished.globalReports": "Отчети за цялата компания",
  "modules.delete.report.message": "Наистина ли искате да изтриете отчета {име}?",
  "modules.delete.report.title": "Изтриване на доклада",
  "navbar.week": "KW {седмица} {година}",
  "newUsername.checkbox.error": "Моля, потвърдете новия си имейл адрес",
  "notificationCenter.emptyState": "Няма известия",
  "notificationCenter.filter.accounting": "Счетоводство",
  "notificationCenter.filter.appNotification:maintenance": "Съобщения за поддръжка",
  "notificationCenter.filter.appNotification:newFeature": "Нови функции",
  "notificationCenter.filter.appNotification:update": "",
  "notificationCenter.filter.companySpecific": "Специфични за компанията",
  "notificationCenter.filter.employeeDocument": "Документи на служителите",
  "notificationCenter.filter.file": "Документи",
  "notificationCenter.filter.general": "Обща информация",
  "notificationCenter.filter.generalDocument": "Общи файлове",
  "notificationCenter.filter.hr": "Счетоводно отчитане на заплатите",
  "notificationCenter.filter.invoice": "Постъпления",
  "notificationCenter.filter.login": "Вход",
  "notificationCenter.filter.module": "Модул",
  "notificationCenter.filter.notificationType": "Вид на известието",
  "notificationCenter.filter.other": "Други",
  "notificationCenter.filter.payslip": "платежна бележка",
  "notificationCenter.filter.project": "Проекти",
  "notificationCenter.filter.report": "Доклади",
  "notificationCenter.filter.ticket": "Запитване",
  "notificationCenter.filter.unread": "Само непрочетени",
  "notificationCenter.markAllAsRead": "Маркирайте всички като прочетени",
  "notificationCenter.title": "Център за известия",
  "openEmployeeDocumentReleasesDialog.releaseMessage.multi": "В личното ви досие има <b>{брой} нови документи</b>, които изискват вашето одобрение.",
  "openEmployeeDocumentReleasesDialog.releaseMessage.single": "Имате <b>нов документ</b> в личното си досие, който изисква вашето одобрение.",
  "openEmployeeDocumentReleasesDialog.submit": "Преглед и споделяне",
  "openEmployeeDocumentReleasesDialog.title": "Споделяне на документи",
  "paymentDialog.button.pay": "Заплащане",
  "paymentDialog.field.amount": "Сума",
  "paymentDialog.field.amount.maxError": "",
  "paymentDialog.field.amount.minError": "",
  "paymentDialog.field.amount.typeError": "Моля, въведете число",
  "paymentDialog.field.iban": "IBAN",
  "paymentDialog.field.iban.invalidError": "Моля, въведете валиден IBAN",
  "paymentDialog.field.purpose": "Предвидена употреба",
  "paymentDialog.title": "Заплащане",
  "pdfView.error.caption": "Документът не може да бъде зареден, опитайте отново",
  "pendingUserCreationDialog.message": "Създаването на потребители в Sharepoint може да отнеме до 30 минути. Моля, бъдете търпеливи, докато бъдат създадени разрешенията.",
  "pendingUserCreationDialog.title": "Създават се потребители",
  "permission.accounting.banking": "Банкови данни",
  "permission.accounting.global.reports": "Счетоводни отчети за цялата компания",
  "permission.accounting.global.results": "Счетоводни оценки в цялата компания",
  "permission.accounting.incaseoflaw": "В случай на закон",
  "permission.accounting.records": "Обявяване на счетоводството",
  "permission.accounting.reports": "Счетоводни отчети",
  "permission.accountTransaction.payment": "",
  "permission.expenses.cockpit": "Преглед на факти и цифри в пилотската кабина",
  "permission.expenses.kpi": "Използване на модула \"Факти и цифри",
  "permission.hr.employeePayrollAccount": "Преглед на сметката за заплати на служителите",
  "permission.hr.employerPayrollAccount": "Сметка за заплати на работодателя",
  "permission.hr.employerPayrollAccount.details": "Преглед на подробности за типа заплата",
  "permission.hr.global.reports": "Счетоводни отчети за заплатите в цялата компания",
  "permission.hr.global.results": "Анализи на счетоводството на заплатите в цялата компания",
  "permission.hr.personnel": "Служители",
  "permission.hr.records": "Обявяване на споразумението за персонала",
  "permission.hr.reports": "Счетоводни отчети за заплатите",
  "permission.option.canAccess": "имате достъп до",
  "permission.option.canEdit": "може да редактира",
  "permission.option.canEdit.all": "може да редактира/отключва всичко",
  "permission.option.canEdit.none": "не можете да редактирате/отключвате нищо",
  "permission.option.canEdit.some": "Може частично да редактирате/освобождавате",
  "permission.option.canPay": "може да плати",
  "permission.option.canRead": "може да види",
  "permission.option.canRead.all": "можете да видите всичко",
  "permission.option.canRead.none": "не може да види нищо",
  "permission.option.canRead.some": "може да вижда частично",
  "permission.option.canRelease": "може да освободи",
  "permission.option.canUpdate": "може да редактира",
  "permission.option.canUpload": "можете да качвате",
  "permission.option.mixed": "смесен",
  "permission.option.none": "няма разрешение",
  "permission.projects": "Използване на модула за проекти",
  "permission.settings.company": "Преглед на настройките на компанията",
  "permission.settings.features": "Модифициране на допълненията",
  "permission.settings.images": "Промяна на изображението на корицата и логото",
  "permission.settings.user": "Предлагане на промени в правата на потребителите",
  "permission.taxAccount.payment": "Плащане на данъчни задължения",
  "permission.unpaidAccount.payment": "Плащане на неизплатени фактури",
  "project.invitation": "Покана за участие в проекта",
  "projectItemPermission.owner": "Пълен достъп",
  "projectItemPermission.owner.description": "Всички права за редактиране на папки и файлове. Редактирайте и завършете проекта и управлявайте членовете и разрешенията.",
  "projectItemPermission.read": "Прочетете",
  "projectItemPermission.read.description": "Преглед на папки и файлове и изтегляне на файлове",
  "projectItemPermission.readNoDownload": "Само за четене",
  "projectItemPermission.write": "Редактиране на",
  "projectItemPermission.write.description": "Преглед, създаване и преименуване на папки. Преглеждайте, качвайте, редактирайте и изтривайте файлове.",
  "projects.activate": "ВЪЗСТАНОВЯВАНЕ НА",
  "projects.active.emptystate.buttonlabel": "Създаване на проект",
  "projects.active.emptystate.message": "Все още няма проект за тази компания",
  "projects.addCompanies": "Добавяне на компания",
  "projects.addCompanies.tooltip": "Създаване на проекта в няколко компании",
  "projects.addFolder": "Създаване на папка",
  "projects.addMembers": "Добавяне на членове",
  "projects.addMembers.bulk.success.title": "Успешно добавени членове",
  "projects.bulkDeleteDialog.message": "Сигурни ли сте, че искате да изтриете избраните файлове и папки?",
  "projects.bulkDeleteDialog.title": "Изтриване на файлове и папки",
  "projects.cancelRequestedReleaseConfirmationDialog.confirm": "Изтегляне на",
  "projects.cancelRequestedReleaseConfirmationDialog.message": "Сигурни ли сте, че искате да изтеглите отворения дял към <b>{име на файл}</b>?",
  "projects.cancelRequestedReleaseConfirmationDialog.title": "Оттегляне на съществуващо издание?",
  "projects.closed.emptystate.message": "Все още няма завършен проект за тази компания",
  "projects.companies.emptyState.title": "Няма налични компании",
  "projects.companies.submit": "Добавяне на",
  "projects.copySuccessDialog.message": "Избраните файлове са копирани в клипборда ви",
  "projects.copySuccessDialog.title": "Копиране в клипборда",
  "projects.create.bulk.backToProjects": "Обратно към Проекти",
  "projects.create.bulk.description": "Проектите са в процес на създаване. Това може да отнеме няколко минути, моля, не затваряйте прозореца на браузъра.",
  "projects.create.bulk.start": "Стартиране на",
  "projects.deleted.emptystate.message": "Все още няма изтрит проект за тази компания",
  "projects.deleteDialog.message.file": "Сигурни ли сте, че искате да изтриете файла <b>{име}</b>?",
  "projects.deleteDialog.message.folder": "Сигурни ли сте, че искате да изтриете папката <b>{име}</b>?",
  "projects.deleteDialog.message.project": "Сигурни ли сте, че искате да изтриете проекта <b>{име}</b>?",
  "projects.deleteDialog.title.file": "Изтриване на файла",
  "projects.deleteDialog.title.folder": "Изтриване на папка",
  "projects.deleteDialog.title.project": "Изтриване на проекта",
  "projects.emptystate.title": "Няма налични проекти",
  "projects.externalId": "Референтен номер:",
  "projects.finish.successDialog.title": "Успешно завършен проект",
  "projects.folder.emptystate.message": "Сега започнете да попълвате папката.",
  "projects.folder.emptystate.title": "Създаване и качване на съдържание",
  "projects.importUsers.add": "Добавяне на",
  "projects.importUsers.replace": "Замяна на",
  "projects.importUsers.switch": "Импортиране на членове от друг проект",
  "projects.international": "Международен проект",
  "projects.international.activate": "За да превърнете даден проект в международен, трябва да активирате тази функция.",
  "projects.international.confirmButton": "Преобразуване на проекта",
  "projects.international.disabled.refresh": "Срокът на валидност на международното ви потребителско име в MS е изтекъл, моля, подновете го. След това можете да промените \"{project}\" в международен проект.",
  "projects.international.disabled.unlock": "За да превърнете даден проект в международен, трябва да активирате тази функция в прегледа на проекта.",
  "projects.international.info.message": "Превръщането на даден проект в международен позволява на служителите на TPA от други държави да бъдат поканени да участват в проекта, за да могат да работят с клиента през граница. За тази цел всички файлове и документи, съхранявани като част от проекта в Microsoft SharePoint, се прехвърлят между различните наематели на Microsoft и в хода на това се възстановяват всички потребителски разрешения за работа по проекта. В процеса могат да бъдат изгубени всички фини разрешения. Затова се препоръчва да се проверят възможностите за достъп след преобразуването в международен проект. Тази стъпка не може да бъде отменена.",
  "projects.international.info.subtitle": "Моля, обърнете внимание на следното:",
  "projects.international.invite.message": "Добавени сте към нови международни проекти. Активирайте следните държави еднократно, за да можете да разглеждате проекти от тези държави.",
  "projects.international.invite.title": "Отключване на нови държави",
  "projects.international.migrating": "Международен проект (в процес на преобразуване)",
  "projects.international.migrating.message": "Понастоящем проектът се превръща в международен проект. Редактирането няма да е възможно отново, докато не приключи преобразуването.",
  "projects.international.plural": "Международни проекти",
  "projects.international.switch": "Превръщане на този проект в международен проект",
  "projects.international.undoWarning.message": "Сигурни ли сте, че искате да превърнете този проект в международен? Всички данни за проекта ще бъдат копирани в TPA AT sharepoint. Това действие не може да бъде отменено, след като проектът е създаден.",
  "projects.international.undoWarning.message.at": "Сигурни ли сте, че искате да превърнете този проект в международен? Това действие не може да бъде отменено, след като проектът е създаден.",
  "projects.international.undoWarning.title": "Това действие не може да бъде отменено.",
  "projects.invite.international": "Добавени сте към международни проекти. Отключете ги сега.",
  "projects.invite.message": "Добавени сте към проекти от {numCountries} държави. Отключете ги сега.",
  "projects.invite.message.singular": "Добавени сте към проекти от {страна}. Отключете ги сега.",
  "projects.members.assignPermission": "Задаване на разрешение",
  "projects.membersIncluded.multi": "Включително {имена} и {име}.",
  "projects.membersIncluded.single": "Включително {име}.",
  "projects.moveConfirmationDialog.message": "Преместените файлове се изтриват от оригиналната папка!",
  "projects.moveConfirmationDialog.title": "Преместване на файлове?",
  "projects.moveSuccessDialog.message": "Файловете бяха преместени успешно",
  "projects.moveSuccessDialog.title": "Преместени файлове",
  "projects.navbar.activeProjects": "Активен",
  "projects.navbar.deletedProjects": "Изтрити",
  "projects.navbar.fileUpload": "Качване на файлове",
  "projects.navbar.finishedProjects": "Завършен",
  "projects.navbar.heading": "Проекти",
  "projects.navbar.notConfiguredProjects": "Не е конфигуриран",
  "projects.navbar.overview": "Преглед{път}",
  "projects.navbar.project.fileTree": "Структура на папката",
  "projects.navbar.project.heading": "Проект: {projectName}",
  "projects.navbar.project.releases": "Освобождава",
  "projects.navbar.releases.heading": "Споделяния за {fileName}",
  "projects.navbar.ticket.heading": "Запитване относно {име на файл}",
  "projects.new": "Нов проект",
  "projects.new.createNewProject": "Създаване на проект",
  "projects.new.externalId": "Референтен номер",
  "projects.new.name": "Име на проекта",
  "projects.new.permissionContextMenu.remove": "Премахване на",
  "projects.new.permissions.text": "Добавяне на нови членове и задаване на потребителски права.",
  "projects.new.title": "Име на проекта",
  "projects.new.userSearch.placeholder": "Поканете член...",
  "projects.notConfigured.emptystate.message": "Все още няма проект за конфигуриране за тази компания",
  "projects.openReleases.plural": "Отворени версии",
  "projects.openReleases.singular": "Открито освобождаване",
  "projects.pasteProgressDialog.title": "Файловете се вмъкват",
  "projects.pasteSuccessDialog.message": "Файловете бяха успешно вмъкнати",
  "projects.pasteSuccessDialog.title": "Вмъкнати файлове",
  "projects.refreshDialog.message": "Срокът на валидност на входните данни на Microsoft за следните държави е изтекъл. Моля, подновете ги.",
  "projects.refreshDialog.title": "Изтекъл срок на влизане в Microsoft",
  "projects.refresh.message": "Срокът на валидност на входните данни на Microsoft за {numCountries} държави е изтекъл. Моля, подновете ги.",
  "projects.refresh.message.singular": "Срокът на валидност на потребителското ви име в Microsoft за {страна} е изтекъл. Моля, подновете го.",
  "projects.releaseConfirmationDialog.message": "Моля, обърнете внимание на следния коментар от {name}:",
  "projects.releaseConfirmationDialog.title": "Споделяне на документ",
  "projects.releases.emptystate.message": "Понастоящем нямате издавания.",
  "projects.releases.emptystate.title": "Няма налични версии",
  "projects.releases.reject": "Спад",
  "projects.releases.status.canceled": "оттеглени",
  "projects.releases.status.canceledFrom": "оттеглени от {име}",
  "projects.releases.status.canceled.tooltip": "оттеглени на {performedAt} от {name}",
  "projects.releases.status.open": "В очакване на освобождаване",
  "projects.releases.status.openFrom": "чакаме пускането на {име}",
  "projects.releases.status.open.tooltip": "чака освобождаване (краен срок: {dueDate}) на {име}",
  "projects.releases.status.rejected": "Отхвърлени",
  "projects.releases.status.rejectedFrom": "отхвърлен от {име}",
  "projects.releases.status.rejected.tooltip": "отхвърлен на {performedAt} от {name}",
  "projects.releases.status.released": "пуснат",
  "projects.releases.status.releasedFrom": "освободен от {име}",
  "projects.releases.status.released.tooltip": "освободен на {performedAt} от {име}",
  "projects.releaseSuccessDialog.message": "<b>{име}</b> ще прегледа файловете и ще се свърже с вас за всякакви въпроси.",
  "projects.releaseSuccessDialog.multi.message": "Назначените лица ще прегледат досиетата и ще се свържат с вас за всякакви въпроси.",
  "projects.releaseSuccessDialog.title": "Успешно заявено освобождаване",
  "projects.requestReleaseConfirmationDialog.confirm": "Изтегляне и пресъздаване",
  "projects.requestReleaseConfirmationDialog.message": "Вече има отворен дял за <b>{име на файл}</b>. За да създадете нов дял, съществуващият дял трябва да бъде оттеглен.",
  "projects.requestReleaseConfirmationDialog.multi.message": "Във вашата селекция вече има отворени версии. За да се създадат нови акции, съществуващите акции трябва да бъдат оттеглени.",
  "projects.requestReleaseConfirmationDialog.multi.title": "Оттегляне на съществуващи издания?",
  "projects.requestReleaseConfirmationDialog.title": "Оттегляне на съществуващо издание?",
  "projects.restoreDialog.message.project": "Сигурни ли сте, че искате да активирате проекта <b>{име}</b>?",
  "projects.restoreDialog.title.project": "Активиране на проекта",
  "projects.statusClosed": "Завършено на",
  "projects.statusClosed.personalized": "Завършено от {име} на",
  "projects.statusDeleted": "Изтрит в",
  "projects.statusDeleted.personalized": "Изтрито от {име} на",
  "projects.statusInProgress": "Последна промяна на",
  "projects.statusInProgress.personalized": "Последно променен от {име} на",
  "projects.tickets.plural": "Запитвания",
  "projects.tickets.singular": "Консултация",
  "records.details.upload.header": "Запис {recordType}",
  "recordType.statusClosed": "Предава се на",
  "recordType.statusInProgress": "Редактиран на",
  "recordType.statusOpen": "Нередактиран",
  "renameDialog.button.text.rename": "Преименуване на",
  "renameDialog.label.document": "Наименование",
  "renameDialog.label.file": "Име на файла",
  "renameDialog.label.folder": "Име на папката",
  "renameDialog.title.document": "Преименуване на документ",
  "renameDialog.title.file": "Преименуване на файл",
  "renameDialog.title.folder": "Преименуване на папка",
  "renameProjectItemDialog.button.text.rename": "Преименуване на",
  "renameProjectItemDialog.itemName.file": "Име на файла",
  "renameProjectItemDialog.itemName.folder": "Име на папката",
  "renameProjectItemDialog.title.file": "Преименуване на файл",
  "renameProjectItemDialog.title.folder": "Преименуване на папка",
  "renameRecordDialog.button.text.rename": "Преименуване на",
  "renameRecordDialog.name": "Наименование",
  "renameRecordDialog.title": "Преименуване на документ",
  "requestReleaseProjectItemDialog.button.text.request": "Запитвания",
  "requestReleaseProjectItemDialog.info": "Кога ми е необходим квалифициран подпис?",
  "requestReleaseProjectItemDialog.label.comment": "Коментар:",
  "requestReleaseProjectItemDialog.label.dueDate": "Краен срок",
  "requestReleaseProjectItemDialog.label.requiresQes": "Освобождаване чрез електронен подпис (само при PDF файлове)",
  "requestReleaseProjectItemDialog.placeholder.searchForUsers": "Въведете името...",
  "requestReleaseProjectItemDialog.qes.collective.subtitle": "Всички лица трябва да освободят",
  "requestReleaseProjectItemDialog.qes.collective.title": "Колективно освобождаване",
  "requestReleaseProjectItemDialog.qes.single.subtitle": "Само едно лице трябва да освободи",
  "requestReleaseProjectItemDialog.qes.single.title": "Единично издание",
  "requestReleaseProjectItemDialog.searchForUsers.error": "Моля, изберете име от списъка",
  "requestReleaseProjectItemDialog.text": "Изберете лицата, на които искате да възложите освобождаването на изброените документи, и въведете краен срок.",
  "requestReleaseProjectItemDialog.title.addResponsibleUser": "Добавяне на друго лице",
  "requestReleaseProjectItemDialog.title.create": "Заявка за освобождаване",
  "requestReleaseProjectItemDialog.title.edit": "Редактиране на изданието",
  "results.accountSheet.accountDetails.pay": "",
  "results.accountSheet.accountTransaction.lastCompletedPayment": "",
  "results.accountSheet.accountTransaction.lastCompletedPaymentWithAmount": "",
  "results.accountSheet.accountTransaction.lastCompletedPaymentWithAmount.detailed": "",
  "results.accountSheet.backLabel.accountSheet": "Лист на сметката {accountNr} \"{name}\"",
  "results.accountSheet.backLabel.results.accountListing": "Балансов списък",
  "results.accountSheet.backLabel.results.balanceSheet": "Баланс",
  "results.accountSheet.backLabel.results.cashAccounting": "Отчет за приходите и разходите",
  "results.accountSheet.backLabel.results.customerListing": "Списък на длъжниците",
  "results.accountSheet.backLabel.results.profitAndLoss": "G&V",
  "results.accountSheet.backLabel.results.vendorListing": "Списък на задълженията",
  "results.accountSheet.columns.amount": "Сума",
  "results.accountSheet.columns.documentDate": "Дата на документа",
  "results.accountSheet.columns.documentNumber": "Документ №.",
  "results.accountSheet.columns.documents": "Постъпления",
  "results.accountSheet.columns.offsettingAccounts": "Контра сметки",
  "results.accountSheet.columns.openItemAmount": "Открита сума",
  "results.accountSheet.columns.postingSymbol": "BS",
  "results.accountSheet.columns.postingText": "Текст за публикуване",
  "results.accountSheet.details.costCenter": "Разходен център",
  "results.accountSheet.details.discount": "Отстъпка в брой",
  "results.accountSheet.details.externalDocumentNumber": "Външен документ №.",
  "results.accountSheet.details.foreignCurrency": "Чуждестранна валута / обменен курс",
  "results.accountSheet.details.period": "Период",
  "results.accountSheet.details.serialNumber": "Списание №.",
  "results.accountSheet.details.tax": "Данък",
  "results.accountSheet.details.taxCode.Ausfuhrlieferungen": "Експортни доставки",
  "results.accountSheet.details.taxCode.BauleistungenMitVSt": "Строителни работи с ДДС",
  "results.accountSheet.details.taxCode.BauleistungenOhneVSt": "Строителни работи без ДДС",
  "results.accountSheet.details.taxCode.EUSt": "EUSt",
  "results.accountSheet.details.taxCode.igEMitVSt": "igE с ДДС",
  "results.accountSheet.details.taxCode.igEOhneVst": "igE без ДДС",
  "results.accountSheet.details.taxCode.igLieferung": "доставка на ig",
  "results.accountSheet.details.taxCode.Kleinunternehmer": "Малък бизнес",
  "results.accountSheet.details.taxCode.RCMitVSt": "RC с VSt",
  "results.accountSheet.details.taxCode.RCOhneVSt": "RC без ДДС",
  "results.accountSheet.details.taxCode.sonstigeLeistungenEuZm": "Други услуги в ЕС (при спазване на ЗМ)",
  "results.accountSheet.details.taxCode.TeilabzugsfaehigeVSt": "частично приспадане на ДДС",
  "results.accountSheet.details.taxCode.Ust": "ДДС",
  "results.accountSheet.details.taxCode.VSt": "VSt",
  "results.accountSheet.details.taxPercent": "Данъчен процент",
  "results.accountSheet.details.vatPeriod": "Период на UVA",
  "results.accountSheet.documents.downloadAll": "Изтегляне на всички файлове",
  "results.accountSheet.filters.amount": "Сума",
  "results.accountSheet.filters.documentDate": "Дата на документа",
  "results.accountSheet.filters.postingSymbol": "Символ за резервация",
  "results.accountSheet.noResults.message": "Не бяха намерени никакви елементи за избраната сметка",
  "results.accountSheet.noResults.open.message": "Не бяха открити отворени позиции за избраната сметка",
  "results.accountSheet.noResults.open.title": "Не са намерени отворени елементи",
  "results.accountSheet.noResults.title": "Не са намерени никакви публикации",
  "results.accountSheet.offsettingAccounts.more": "{count} още",
  "results.accountSheet.paid": "платен",
  "results.accountSheet.search.count": "Резервации: <b>{count}</b>",
  "results.accountSheet.search.placeholder": "Търсене на текст за резервация...",
  "results.accountSheet.sum.title": "Σ Общо",
  "results.accountSheet.tabs.all": "Всички артикули",
  "results.accountSheet.tabs.open": "Само отворени елементи",
  "results.accountSheet.title": "Счетоводен лист за сметка {accountNr} \"{име}\"",
  "results.columns.changeAbsolute": "Abw.",
  "results.columns.changePercentage": "Dev. (%)",
  "results.columns.credit": "Разполагате с",
  "results.columns.debit": "Цел",
  "results.common.accounts": "Сметки",
  "results.common.quickExpand.expandAccounts": "Всички (вкл. сметки)",
  "results.common.quickExpand.expandNthDepth": "Ниво {дълбочина}",
  "results.common.quickExpand.expandRoot": "Основно ниво на разбивка",
  "results.common.quickExpand.expandRows": "Разгънете линиите:",
  "results.disclaimer": "Важна забележка",
  "results.disclaimerMessage": "За периодите, за които годишните финансови отчети все още не са финализирани, представените анализи се основават на данни от текущия списък на салдата към съответната отчетна дата. Анализите са изготвени въз основа на предоставените ни документи без съществен преглед на същите и своевременно предоставят на клиента допълнителна информация за текущото развитие на бизнеса. По-специално, начисленията и отсрочванията, промените в провизиите, промените в материалните запаси, оценката на вземанията и амортизациите са взети предвид само през финансовите години, за които годишните финансови отчети все още не са изготвени, доколкото това е договорено с клиента. Това води до разлики между представените анализи и окончателните годишни финансови отчети.\n\nПоради това ние не поемаме отговорност за точността и пълнотата на анализите по отношение на клиента или трети страни. Във всички останали отношения към нашите задачи и отговорности, включително по отношение на трети страни, се прилагат Общите условия на договора за счетоводните услуги (AAB).",
  "results.navbar.buttons.accountListing": "Балансов списък",
  "results.navbar.buttons.balanceSheet": "Баланс",
  "results.navbar.buttons.cashAccounting": "Отчет за приходите и разходите",
  "results.navbar.buttons.customerListing": "Списък на длъжниците",
  "results.navbar.buttons.payrollAccount": "Сметка за заплати на работодателя",
  "results.navbar.buttons.profitAndLoss": "G&V",
  "results.navbar.buttons.vendorListing": "Списък на задълженията",
  "results.noFinancialAccountancies.message": "Понастоящем няма налични оценки.",
  "results.noFinancialAccountancies.title": "Без оценки",
  "results.payrollAccountSheet.dataTable.month": "месец",
  "results.payrollAccountSheet.dataTable.number": "Не.",
  "results.payrollAccountSheet.dataTable.payrollType": "Вид заплата",
  "results.payrollAccountSheet.dataTable.total": "Общо",
  "results.payrollAccountSheet.dataTable.totalPreviousYear": "Общо за предходната година",
  "results.payrollAccountSheet.noPermission.message": "Не сте упълномощени да преглеждате сметката за заплати.",
  "results.payrollAccountSheet.noPermission.title": "Няма разрешение",
  "results.payrollAccountSheet.noResults.details": "Тази сметка за заплати не съдържа никакви данни",
  "results.payrollAccountSheet.noResultsYear.message": "Не са намерени данни за избраната година.",
  "results.payrollAccountSheet.noResultsYear.title": "Не са намерени данни",
  "results.settings.accountsVisibility.hideAccounts": "Скриване на акаунти",
  "results.settings.accountsVisibility.showAccounts": "Показване на акаунти",
  "results.settings.orderBy.accountNameAsc": "Име на сметката във възходящ ред",
  "results.settings.orderBy.accountNameDesc": "Име на сметката в низходящ ред",
  "results.settings.orderBy.accountNrAsc": "Възходящ номер на сметката",
  "results.settings.orderBy.accountNrDesc": "Номер на сметката в низходящ ред",
  "results.settings.orderBy.amountAsc": "Възходяща сума",
  "results.settings.orderBy.amountDesc": "Сума в низходящ ред",
  "results.settings.orderBy.title": "Сортиране по: {orderBy}",
  "results.settings.previousYearRanges.fullYear": "Показване на общата сума за предходната година",
  "results.settings.previousYearRanges.partialYear": "Вземете предвид предходната година само до {previousYearRangeUntil}.",
  "results.settings.variant.compactView": "Компактен изглед",
  "results.settings.variant.currentAndPastYear": "Новини и предишна година",
  "results.settings.variant.currentAndPastYearAbsoluteChange": "Текуща и предходна година с абсолютна промяна",
  "results.settings.variant.currentAndPastYearPercentageChange": "Текуща и предходна година с процентно изменение",
  "results.settings.variant.currentYear": "Текуща година",
  "results.settings.variant.detailView": "Подробен преглед",
  "results.title": "Оценки",
  "role.accountingAssistent": "Счетоводен асистент",
  "role.accountingAssistent.description": "Достъп до счетоводството без права за освобождаване",
  "role.accountingManagement": "Управление на финансите",
  "role.accountingManagement.description": "Достъп до счетоводството, включително права за освобождаване",
  "role.admin": "Администрация / управление",
  "role.admin.description": "Всички права за достъп до всички зони",
  "role.assistent": "Помощ",
  "role.assistent.description": "Достъп до счетоводството и ведомостите за заплати без права за освобождаване{br}Може да предложи промяна на потребителите",
  "role.custom": "Потребителски",
  "role.custom.description": "Дефинирани от потребителя права за достъп",
  "role.custom.jobTitle": "Служители",
  "role.custom.jobTitle.female": "Персонал",
  "role.hrAssistent": "Асистент по заплатите",
  "role.hrAssistent.description": "Достъп до счетоводството на заплатите без права за освобождаване",
  "role.hrManagement": "Управление на човешките ресурси",
  "role.hrManagement.description": "Достъп до счетоводството на заплатите, включително права за освобождаване",
  "role.internationalProjects": "Персонал на проекта",
  "role.internationalProjects.female": "Персонал на проекта",
  "role.legalAdvisor": "Правен съветник:в",
  "role.noRole": "няма част",
  "role.noRole.description": "Нямате права за достъп до TPA Connect",
  "role.staff": "Служител",
  "role.staff.description": "Получава електронни отчети за приходите",
  "role.staff.female": "Служител",
  "role.superAdmin": "Суперадминистратор",
  "role.superAdmin.description": "Достъп до всички области, вкл. управление на потребителите",
  "role.tpaAccounting": "Счетоводство",
  "role.tpaAccounting.description": "Консултантски достъп до счетоводството",
  "role.tpaAdvisor": "Съветник",
  "role.tpaAdvisor.description": "Достъп на консултант до всички зони",
  "role.tpaAdvisor.female": "Съветник",
  "role.tpaAnnualAccounts": "Годишни отчети",
  "role.tpaAnnualAccounts.description": "Отговаря за годишните отчети",
  "role.tpaAuditor": "Одит",
  "role.tpaBusinessConsultant": "Консултации по управление",
  "role.tpaBusinessConsultant.description": "Отговаря за консултациите в областта на управлението",
  "role.tpaChat": "Свържете се с",
  "role.tpaChatSecret": "Свържете се с",
  "role.tpaHr": "Заплати",
  "role.tpaHr.description": "Консултантски достъп до счетоводството на заплатите",
  "role.tpaOther": "Персонал на проекта",
  "role.tpaOther.female": "Служител на проекта",
  "role.tpaPartner": "Партньор",
  "role.tpaPartner.description": "Достъп на консултант до всички зони",
  "role.tpaPartner.female": "Партньор",
  "role.tpaPayrollAccountant": "Заплати",
  "role.tpaReporting": "Докладване",
  "role.tpaReporting.description": "Отговаря за докладването",
  "role.tpaSpecialAdvisor": "Специална консултация",
  "role.tpaSpecialAdvisor.description": "Отговаря за специални консултации",
  "role.tpaTaxAccountant": "Данъчни консултации",
  "role.tpaTaxAccountant.description": "Отговаря за данъчни консултации",
  "screen.accounting.navbar.bankConnections": "Банкови данни",
  "screen.accounting.navbar.heading": "Счетоводство",
  "screen.accounting.navbar.overview": "Преглед",
  "screen.accounting.navbar.records": "Постъпления",
  "screen.accounting.overview.bankConnection": "{{br} {br} Срокът на валидност на една или повече от банковите ви данни изтича в рамките на този отчетен период. Моля, подновете ги.{br} {br} С уважение{br} {подпис}",
  "screen.accounting.overview.bankConnection.expired": "{salutation}, {br} {br} Срокът на валидност на една или повече от банковите ви данни е изтекъл. Моля, подновете ги.{br} {br} С уважение{br} {подпис}",
  "screen.accounting.overview.bankConnection.expired.title": "Изтекли банкови данни",
  "screen.accounting.overview.bankConnections.refreshBankConnections": "Подновяване на банкови сметки",
  "screen.accounting.overview.bankConnection.title": "Изтичане на валидността на банковите сметки",
  "screen.accounting.overview.card.analysis": "Издаване на доклади за",
  "screen.accounting.overview.card.globalAnalysis": "Публикуване на отчети за цялата компания",
  "screen.accounting.overview.card.overview": "Отчитане на обявлението",
  "screen.accounting.overview.finished.download": "Изтегляне на всички файлове",
  "screen.accounting.overview.finished.message": "{{br} {br}Благодаря ви за проверката и одобрението на сметките. По-долу ще намерите вашите доклади.{br} {br} С уважение, {br}{подпис}",
  "screen.accounting.overview.reports": "{{br} {br} Приложени са следните документи за {период}. Документите са изготвени въз основа на информацията, която сте предоставили, и на представените от вас документи. Ако откриете неточности или непълноти в документите, моля, уведомете ни, за да можем да направим съответните корекции. {br} {br} Ако имате някакви въпроси, моля, не се колебайте да се свържете с нас.{br} {br} С уважение, {br}{подпис}",
  "screen.accounting.overview.ticketsWelcome.inProgress": "{{br} {br}Благодаря за предаването на ваучерите. В хода на счетоводството възникнаха някои въпроси:",
  "screen.accounting.overview.welcome.closed": "{{br} {br} Благодарим Ви, че представихте всички необходими документи. Ние ще ги обработим в съответствие с договорените срокове и ще се свържем с вас при евентуални запитвания.",
  "screen.accounting.overview.welcome.inProgress": "{{br} {br} Искаме да качите документите си за резервация за {период}{дата на падежа}. Моля, изпратете ни всички необходими документи. {br} {br} С най-добри пожелания, {br}{подпис}",
  "screen.accounting.records.banner.selectedAll": "Избират се всички {брой} файлове в \"{тип запис}\".",
  "screen.accounting.records.banner.selectedAll.transferFailed": "Избират се всички {брой} неуспешни файлове в \"{тип запис}\".",
  "screen.accounting.records.banner.selectedCount": "Избрани са всички {count} файлове на тази страница",
  "screen.accounting.records.banner.totalCount": "Изберете всички {брой} файлове в \"{тип запис}\".",
  "screen.accounting.records.banner.totalCount.transferFailed": "Изберете всички {брой} неуспешни файлове в \"{тип запис}\".",
  "screen.accounting.records.banner.unselect": "Отмяна на избора",
  "screen.accounting.records.documents": "Файлове:",
  "screen.accounting.records.empty": "Файлът е празен",
  "screen.accounting.records.filter.transferFailed": "Прехвърлянето е неуспешно",
  "screen.accounting.records.format.error": "Формат на проверката",
  "screen.accounting.records.move.dialog.button.text": "Преместване",
  "screen.accounting.records.move.multiple.dialog.title": "Преместване на документи",
  "screen.accounting.records.move.single.dialog.title": "Преместване на документ",
  "screen.accounting.records.navbar.tab.list": "Постъпления ({брой})",
  "screen.accounting.records.navbar.tab.tickets": "Запитвания ({count})",
  "screen.accounting.records.person.responsible": "Отговорност",
  "screen.accounting.records.serverUpload.error": "Файлът не може да бъде качен на сървъра",
  "screen.accounting.records.serverUpload.typeError": "Форматът не се приема",
  "screen.accounting.records.sizeAndFormat.error": "Проверка на размера и формата на файла",
  "screen.accounting.records.size.error": "Проверка на размера на файла",
  "screen.accounting.records.tickets": "| Запитвания: {numTickets}",
  "screen.accounting.records.transfer.button.text": "Предаване към TPA",
  "screen.accounting.records.transfer.dialog.text": "Искате ли да прехвърлите <b>всички счетоводни</b> файлове за периода {periodStart} - {periodEnd} в TPA за осчетоводяване?",
  "screen.accounting.records.transfer.dialog.textRecordType": "Искате ли да прехвърлите <b>всички файлове на \"{име}\"</b> за периода {periodStart} - {periodEnd} в TPA за осчетоводяване?",
  "screen.accounting.records.transfer.dialog.title": "Отчитане на предаването",
  "screen.accounting.records.transfer.retryDialog.button.text": "Опитайте отново.",
  "screen.accounting.records.transfer.retryDialog.file": "Грешка при предаването на {failedFile}",
  "screen.accounting.records.transfer.retryDialog.text": "Упс. Тук нещо се обърка. Моля, опитайте отново.",
  "screen.accounting.records.transfer.retryDialog.title": "Предаването е повредено",
  "screen.accounting.records.transfer.successDialog.text": "Вашият съветник по ТПД ще прегледа данните и ще се свърже с вас за всякакви въпроси.",
  "screen.accounting.records.transfer.successDialog.title": "Данните са предадени успешно",
  "screen.accounting.records.types.header": "Счетоводство",
  "screen.accounting.records.unknown.error": "Неизвестна грешка",
  "screen.accounting.records.upload.button": "Качване на разписки",
  "screen.accounting.records.upload.navbar.back": "Счетоводство / Ваучери",
  "screen.accounting.recordTypes.title": "Изберете вида на ваучерите",
  "screen.advisor.tabbar.active": "Активен",
  "screen.advisor.tabbar.notConfigured": "Не е конфигуриран",
  "screen.advisor.upload.approve.info": "Кога ми е необходим квалифициран подпис?",
  "screen.advisor.uploads.upload.approve": "Освобождаване на клиенти",
  "screen.advisor.uploads.upload.approve.option.click": "Освобождаване на клик",
  "screen.advisor.uploads.upload.approve.option.none": "Без одобрение от клиента",
  "screen.advisor.uploads.upload.approve.option.qes": "Освобождаване чрез електронен подпис",
  "screen.advisor.uploads.upload.buttonText": "Предаване",
  "screen.advisor.uploads.upload.category": "Категория",
  "screen.advisor.uploads.upload.category.validation": "Моля, изберете категория",
  "screen.advisor.uploads.upload.comment": "Текст на предаването",
  "screen.advisor.uploads.upload.comment.downloadError": "Грешка при зареждането на коментара",
  "screen.advisor.uploads.upload.dueDate": "Дължимо на",
  "screen.advisor.uploads.upload.dueDate.validation": "Моля, въведете дата на плащане",
  "screen.advisor.uploads.upload.global": "В цялата компания",
  "screen.advisor.uploads.upload.module": "Област",
  "screen.advisor.uploads.upload.module.validation": "Моля, изберете област",
  "screen.advisor.uploads.upload.navBack": "Качва",
  "screen.advisor.uploads.upload.navHeader": "Качване на файлове: {категория}",
  "screen.advisor.uploads.upload.notifyCustomer": "Уведомяване на клиентите",
  "screen.advisor.uploads.upload.subsidiary.validation": "Моля, изберете код на компанията",
  "screen.advisor.uploads.upload.visibleToEmployee": "Освобождаване за MA",
  "screen.auth.forgot_password_confirm.subtitle.section_1": "Ако сме открили потребител с имейл адрес {email}, скоро ще получите имейл с връзка за възстановяване на паролата.",
  "screen.auth.forgot_password_confirm.subtitle.section_2": "Не сте получили поща? Проверете папката за спам или се свържете със системния си администратор.",
  "screen.auth.forgot_password_confirm.title": "Скоро ще получите имейл.",
  "screen.auth.forgot_password_email.form.button": "Изпращане на връзка за нулиране",
  "screen.auth.forgot_password_email.form.label.email": "Идентификатор на потребителя / Адрес на електронна поща",
  "screen.auth.forgot_password_email.subtitle": "Въведете имейл адреса си и ние ще ви изпратим имейл с връзка за възстановяване на паролата ви.",
  "screen.auth.forgot_password_email.title": "Възстановяване на паролата",
  "screen.auth.forgot_password_email.validation_error": "Моля, въведете имейл адреса си",
  "screen.auth.forgot_password_reset.error.password_not_set": "Моля, въведете парола",
  "screen.auth.forgot_password_reset.error.passwords_do_not_match": "Паролите не съвпадат",
  "screen.auth.forgot_password_reset.error.password_weak": "Паролата ви все още е твърде слаба.",
  "screen.auth.forgot_password_reset.form.button": "Промяна на паролата",
  "screen.auth.forgot_password_reset.form.button.mfa": "Изпращане на код",
  "screen.auth.forgot_password_reset.form.button.mfa.resend_code": "Изпратете кода отново",
  "screen.auth.forgot_password_reset.form.mfa": "Код",
  "screen.auth.forgot_password_reset.form.password": "Парола",
  "screen.auth.forgot_password_reset.form.repeat_password": "Повтаряне на паролата",
  "screen.auth.forgot_password_reset.subtitle": "Задайте нова парола за профила си в TPA Connect.",
  "screen.auth.forgot_password_reset.subtitle.mfa": "Моля, въведете кода, който изпратихме на телефонния ви номер чрез SMS.",
  "screen.auth.forgot_password_reset.title": "Промяна на паролата",
  "screen.auth.forgot_password_reset.title.mfa": "Потвърждаване на промяната на паролата с двуфакторно удостоверяване",
  "screen.auth.registration.form.button": "Регистрация",
  "screen.auth.registration.form.button.mfa": "Изпращане на код",
  "screen.auth.registration.form.button.mfa.resendCode": "Изпратете кода отново",
  "screen.auth.registration.form.label.mfa": "Код",
  "screen.auth.registration.form.label.password": "Парола",
  "screen.auth.registration.form.label.repeat_password": "Повтаряне на паролата",
  "screen.auth.registration.form.section_1": "Използвайте поне 8 символа.",
  "screen.auth.registration.form.section_2": "Можете да използвате главни и малки букви, цифри и специални символи, за да направите паролата си по-силна.",
  "screen.auth.registration.form.section_3": "Измислете си мнемоника, която да ви помогне да запомните паролата си.",
  "screen.auth.registration.mfa.subtitle": "Моля, въведете кода, който изпратихме на телефонния ви номер чрез SMS.",
  "screen.auth.registration.subtitle": "Задайте парола за своя акаунт в TPA Connect.",
  "screen.auth.registration.title": "Задаване и регистриране на парола",
  "screen.auth.welcome.subtitle": "Свържете акаунта си в TPA Connect с акаунта си в Microsoft, за да се възползвате напълно от всички функции. Можете да свържете профила си и на по-късна дата.",
  "screen.auth.welcome.subtitle.msOnly": "Свържете акаунта си в TPA Connect с акаунта си в Microsoft, за да се възползвате напълно от всички функции.",
  "screen.auth.welcome.title": "Добре дошли в TPA Connect!",
  "screen.changeUsername.checkbox": "С настоящото потвърждавам новия си имейл адрес",
  "screen.changeUsername.message": "Изчислителното сърце на приложението TPA Connect е забелязало, че имейл адресът ви е променен на <b>{newUsername}</b>. За да сме напълно сигурни, че тази промяна е правилна, е необходимо да я потвърдите с едно кликване.",
  "screen.changeUsername.title": "Нов имейл адрес",
  "screen.cockpit.allEvents": "Събития: Всички събития",
  "screen.cockpit.allNews": "Новини: Всички статии",
  "screen.cockpit.dueDates.export": "Срокове за износ",
  "screen.cockpit.dueDates.navbar.heading": "Отворени крайни срокове",
  "screen.cockpit.dueDates.projects.text": "Освобождаване за {име на документ}",
  "screen.cockpit.dueDates.records.accounting.text": "Счетоводно оповестяване за {име на дъщерно дружество} - {период}",
  "screen.cockpit.dueDates.records.hr.text": "Оповестяване на данни за заплатите за {име на дъщерно дружество} - {период}",
  "screen.cockpit.dueDates.reports.global.text": "Издаване на отчети за \"отчети за цялата компания\" - {период}",
  "screen.cockpit.dueDates.reports.subsidiary.text": "Издаване на отчет за {име на дъщерно дружество} - {период}",
  "screen.cockpit.dueDates.reportUpload.bmd.message": "Моля, използвайте BMD NTCS за предоставяне на доклади",
  "screen.cockpit.dueDates.reportUpload.bmd.title": "Качване на доклад",
  "screen.cockpit.dueDates.reportUpload.global.text": "Качване на отчети за \"отчети за цялата компания\" - {период}",
  "screen.cockpit.dueDates.reportUpload.subsidiary.text": "Изпращане на отчет за {име на дъщерно дружество} - {период}",
  "screen.cockpit.dueDates.tickets.global.text": "Краен срок за справка с документа {documentName}",
  "screen.cockpit.dueDates.tickets.subsidiary.text": "Краен срок за справка с документа {име на документ} за {име на дъщерно дружество}",
  "screen.cockpit.emptyState.message": "Върнете се по-късно",
  "screen.cockpit.emptyState.title": "В момента няма новини",
  "screen.cockpit.eventDetail": "Подробности за събитието",
  "screen.cockpit.eventDetails.heading": "Подробности и регистрация",
  "screen.cockpit.eventDetails.lecturer.email": "E: {email}",
  "screen.cockpit.eventDetails.lecturers": "Презентатор",
  "screen.cockpit.eventDetails.lecturer.telephone": "Т: {телефон}",
  "screen.cockpit.eventDetails.register": "Регистрирайте се сега",
  "screen.cockpit.event.moreInformation": "ИНФОРМАЦИЯ И РЕГИСТРАЦИЯ",
  "screen.cockpit.kpis.add": "Добавяне на ключова фигура",
  "screen.cockpit.kpis.chart.legend": "в € 1000",
  "screen.cockpit.kpis.configurationDialog.insuranceCarrierBalance.label": "Здравно осигуряване",
  "screen.cockpit.kpis.configurationDialog.metric.label": "KPI",
  "screen.cockpit.kpis.configurationDialog.months.label": "Месеци",
  "screen.cockpit.kpis.configurationDialog.title": "Конфигуриране на уиджета KPI",
  "screen.cockpit.kpis.ebit.chart.name": "Развитие на EBIT",
  "screen.cockpit.kpis.ebit.kpi.name": "EBIT до момента",
  "screen.cockpit.kpis.ebit.kpi.tooltip": "Показва стойността на EBIT за всички месеци, които вече са напълно осчетоводени от началото на бизнес годината.",
  "screen.cockpit.kpis.empty": "Кодът на вашата компания \"{име}\" в момента не е наличен.",
  "screen.cockpit.kpis.insuranceCarrier.balance.kpi.name": "Здравноосигурителен баланс",
  "screen.cockpit.kpis.noneConfigured": "Добавяне на ключова фигура",
  "screen.cockpit.kpis.revenue.chart.name": "Тенденция на оборота",
  "screen.cockpit.kpis.revenue.kpi.name": "Досегашен оборот",
  "screen.cockpit.kpis.revenue.kpi.tooltip": "Показва оборота за всички вече напълно осчетоводени месеци от началото на бизнес годината.",
  "screen.cockpit.kpis.save": "Прилагане на промените",
  "screen.cockpit.kpis.svsAccount.kpi.info": "",
  "screen.cockpit.kpis.svsAccount.kpi.label": "",
  "screen.cockpit.kpis.svsAccount.kpi.name": "",
  "screen.cockpit.kpis.taxAccount.kpi.arrears": "Изоставане",
  "screen.cockpit.kpis.taxAccount.kpi.credit": "Кредитна бележка",
  "screen.cockpit.kpis.taxAccount.kpi.label": "Състояние към {дата}",
  "screen.cockpit.kpis.taxAccount.kpi.lastCompletedPayment": "[сума], последно платена на {дата}",
  "screen.cockpit.kpis.taxAccount.kpi.name": "Баланс на данъчната сметка",
  "screen.cockpit.kpis.unpaidAccount.kpi.lastCompletedPayment": "Вече е платено на {дата}",
  "screen.cockpit.kpis.unpaidAccounts.kpi.caption": "{invoicesCount} Фактури",
  "screen.cockpit.kpis.unpaidAccounts.kpi.label": "Състояние към {дата}",
  "screen.cockpit.kpis.unpaidAccounts.kpi.name": "Неизплатени фактури в ТПД",
  "screen.cockpit.navbar.heading": "Кокпит",
  "screen.cockpit.overview.kpis.title": "Факти и цифри",
  "screen.cockpit.overview.newestPublications": "Скорошни публикации",
  "screen.cockpit.overview.notifications.dueDates": "Отворени крайни срокове",
  "screen.cockpit.overview.notifications.messages": "Новини",
  "screen.cockpit.overview.notifications.tickets": "Запитвания",
  "screen.cockpit.overview.notifications.title": "Известия",
  "screen.cockpit.overview.promotions.title": "Новини",
  "screen.cockpit.overview.relevantNews.title": "Съответни новини",
  "screen.cockpit.overview.relevantPublications.description": "Можете да поискате печатна публикация на TPA, която ще ви бъде изпратена в рамките на няколко дни. Или: Можете да изтеглите електронна версия (PDF) на желаната публикация на TPA.",
  "screen.cockpit.overview.relevantPublications.title": "Съответни публикации",
  "screen.cockpit.promotion": "Новини: статия",
  "screen.cockpit.promotion.additionalNews": "Новини",
  "screen.cockpit.relevantNews": "Новини: Статия",
  "screen.cockpit.relevantNews.additionalNews": "Още новини",
  "screen.cockpit.tickets.navbar.heading": "Отворени заявки",
  "screen.hr.additionaldata.addBonus": "Добавете още една надбавка",
  "screen.hr.additionaldata.additionaldata": "Допълнителна информация",
  "screen.hr.additionaldata.additionalInformation": "Допълнителна информация",
  "screen.hr.additionaldata.amount": "Сума в {валута} на месец",
  "screen.hr.additionaldata.amount_error": "Моля, въведете сума",
  "screen.hr.additionaldata.bankDetails": "Банкови данни",
  "screen.hr.additionaldata.bic": "BIC",
  "screen.hr.additionaldata.bic.validation": "Невалиден BIC",
  "screen.hr.additionaldata.commuterAllowance": "Надбавка за пътуване до работа",
  "screen.hr.additionaldata.costCenter": "Разходен център",
  "screen.hr.additionaldata.costCenterNote": "Бележка относно разходния център",
  "screen.hr.additionaldata.description": "Следната информация може да бъде попълнена и при започване на услугата, ако е приложимо.",
  "screen.hr.additionaldata.designator": "Надбавка",
  "screen.hr.additionaldata.designator_error": "Моля, посочете надбавка",
  "screen.hr.additionaldata.emailAddress": "Адрес на електронна поща",
  "screen.hr.additionaldata.email_error": "Моля, въведете правилен имейл адрес",
  "screen.hr.additionaldata.familyBonusPlusAmount": "Fabo Plus Сума в EUR",
  "screen.hr.additionaldata.familyBonusPlusAmount_error": "Моля, въведете поне 0",
  "screen.hr.additionaldata.funder": "Разходна единица",
  "screen.hr.additionaldata.gross": "Бруто",
  "screen.hr.additionaldata.hours_error": "Моля, въведете число между {minHours} и {maxHours}.",
  "screen.hr.additionaldata.iban": "IBAN",
  "screen.hr.additionaldata.iban_error": "Невалиден IBAN",
  "screen.hr.additionaldata.jobDescription": "Длъжност",
  "screen.hr.additionaldata.kilometersForCommuterEuro": "Километри за пътнически евро",
  "screen.hr.additionaldata.kilometersForCommuterEuro_error": "Моля, въведете поне 0",
  "screen.hr.additionaldata.kilometersForCommuterEuro_typeerror": "Километрите могат да се посочват само в цели числа.",
  "screen.hr.additionaldata.maritalStatus": "Семейно положение",
  "screen.hr.additionaldata.monthlySalary": "Заплата в {валута} на месец",
  "screen.hr.additionaldata.monthlySalary_error": "Моля, въведете поне 0",
  "screen.hr.additionaldata.net": "Нето",
  "screen.hr.additionaldata.nextPage": "Продължете към документи",
  "screen.hr.additionaldata.number_error": "Моля, въведете число",
  "screen.hr.additionaldata.per": "на",
  "screen.hr.additionaldata.personaldata": "Лични данни",
  "screen.hr.additionaldata.religiousConfession": "Религиозна изповед",
  "screen.hr.additionaldata.salarayAndPosition": "Възнаграждение и длъжност",
  "screen.hr.additionaldata.saveChanges": "Запазване на промените",
  "screen.hr.additionaldata.soleEarnerStatus": "Едноличен търговец",
  "screen.hr.additionaldata.titelAndAcademicDegrees": "Титли и академични степени",
  "screen.hr.additionaldata.titlePrefix": "Префикс",
  "screen.hr.additionaldata.titleSuffix": "Под",
  "screen.hr.additionaldata.wageAgreementClassification": "Колективен трудов договор за класификация",
  "screen.hr.additionaldata.weeklyWorkDays": "Брой работни дни на седмица",
  "screen.hr.additionaldata.weeklyWorkDays_error": "Моля, изберете валидна стойност",
  "screen.hr.additionaldata.workingHours": "Брой часове",
  "screen.hr.additionaldata.workingHoursInterval": "Период",
  "screen.hr.additionaldata.workingTimes": "Работно време",
  "screen.hr.cancelRegistration.dataSaved": "Ако отмените, въведените данни ще останат запазени. Регистрацията може да бъде продължена на по-късен етап.",
  "screen.hr.cancelRegistration.header": "Отмяна на регистрацията",
  "screen.hr.cancelRegistration.question1": "Сигурни ли сте, че искате да отмените процеса на регистрация? Можете да използвате въведените до момента данни, за да",
  "screen.hr.cancelRegistration.question2": " и го изпратете на данъчния си консултант от TPA за регистрация.",
  "screen.hr.deregistration.data.navback": "Персонал / Служители / Регистрирани / {първо име} {име}",
  "screen.hr.deregistration.documents.navback": "Персонал / Служители / Регистрирани / {първо име} {име} / Данни за дерегистрация",
  "screen.hr.deregistration.navheader": "{първо име} {име} отписване",
  "screen.hr.deregistration.validation.deregistrationDate": "Моля, въведете дата на излизане",
  "screen.hr.deregistration.validation.deregistrationDateMin": "Датата на излизане трябва да е по-голяма от датата на влизане.",
  "screen.hr.deregistration.validation.deregistrationReason": "Моля, посочете причината за напускането",
  "screen.hr.deregistration.validation.remainingHolidays": "Моля, посочете оставащия отпуск",
  "screen.hr.deregistration.validation.remainingOvertime": "Моля, посочете свободния извънреден труд",
  "screen.hr.documents.citizenship": "Удостоверение за гражданство",
  "screen.hr.documents.contract": "Serviceдоговор",
  "screen.hr.documents.description": "Качете допълнителни документи, като например: трудов договор, лична карта, удостоверение за гражданство, регистрационен формуляр или други документи.",
  "screen.hr.documents.emptystate.buttonlabel": "Качване на документи",
  "screen.hr.documents.emptystate.message": "Няма налични документи за този служител",
  "screen.hr.documents.emptystate.title": "Няма налични документи",
  "screen.hr.documents.enterManually": "Ръчно въвеждане на данни",
  "screen.hr.documents.idcard": "Личнакарта за самоличност",
  "screen.hr.documents.logTime": "Рекордни времена",
  "screen.hr.documents.needsRelease.notReleased": "В очакване на освобождаване",
  "screen.hr.documents.needsRelease.released": "пуснат",
  "screen.hr.documents.needsRelease.released.tooltip": "издаден на {releasedAt} от {име}",
  "screen.hr.documents.nextButton": "Към обобщението",
  "screen.hr.documents.nonworkingtimes.emptystate.message": "Започнете да качвате файловете си сега или въведете времената за неизпълнение ръчно.",
  "screen.hr.documents.others": "Други",
  "screen.hr.documents.registrationslip": "Съобщениелист",
  "screen.hr.documents.uploadDocuments": "Качване на документи",
  "screen.hr.edit.flow.navback": "Персонал / Служители / TODO: Статус / {първо име} {последно име}",
  "screen.hr.edit.flow.navheader": "{първо име} {последно име} редактиране",
  "screen.hr.employee.current.all": "Всички",
  "screen.hr.employee.current.anyDateOfJoining": "Всяка дата на вписване",
  "screen.hr.employee.current.anyDateOfJoiningExiting": "Всяка дата на влизане/излизане",
  "screen.hr.employee.current.cancellation": "Дерегистрации",
  "screen.hr.employee.current.changeUsername": "Нова електронна поща",
  "screen.hr.employee.current.changeUsername.successDialog.message": "<b>{име на потребителя}</b> получава заявка за потвърждаване на новопредоставения имейл адрес.",
  "screen.hr.employee.current.changeUsername.successDialog.title": "Имейлът за промяна на имейл адреса е изпратен успешно.",
  "screen.hr.employee.current.changeUsername.tooltip": "Новият имейл адрес на служителя е {newUsername}. С натискане на бутона служителят е помолен да потвърди новия имейл адрес.",
  "screen.hr.employee.current.dataChange": "Промени в основните данни",
  "screen.hr.employee.current.notTransferred": "Не се предава",
  "screen.hr.employee.current.registration": "Регистрации",
  "screen.hr.employee.deregistration.data.date": "Дата на излизане",
  "screen.hr.employee.deregistration.data.header": "Данни за излизане от системата",
  "screen.hr.employee.deregistration.data.reason": "Причина за напускане",
  "screen.hr.employee.deregistration.data.remainingHolidays": "Оставащ отпуск в дни",
  "screen.hr.employee.deregistration.data.remainingOvertime": "Открит извънреден труд",
  "screen.hr.employee.deregistration.data.subtitle": "Ако са налични, качете документите за излизане за окончателно уреждане.",
  "screen.hr.employee.deregistration.documents.submitButton": "Отмяна на регистрацията на предаването",
  "screen.hr.employee.deregistration.stepper.data": "Данни за излизане от системата",
  "screen.hr.employee.deregistration.stepper.documents": "Документи за излизане",
  "screen.hr.employee.details.addFiles": "Добавяне на документи",
  "screen.hr.employee.details.currentEmployment": "Текущо трудово правоотношение",
  "screen.hr.employee.details.deregisterEmployee": "Дерегистриране на служител",
  "screen.hr.employee.details.employments": "Трудови правоотношения",
  "screen.hr.employee.details.fileProvidedBy": "предоставена от {име}",
  "screen.hr.employee.details.fileReleased": "Видими за служителите",
  "screen.hr.employee.details.linkedFiles": "Свързани файлове",
  "screen.hr.employee.details.navBack": "Персонал / {list}",
  "screen.hr.employee.details.pastEmployments": "Предишна работа",
  "screen.hr.employee.details.payrollAccount.navBack": "Персонал / Текущ DN / {пълното име}",
  "screen.hr.employee.details.personnelFile": "Лично досие",
  "screen.hr.employee.details.personnelNumber": "Номер на персонала:",
  "screen.hr.employee.details.records": "Доказателство за доходи",
  "screen.hr.employee.details.reregisterEmployee": "Повторна регистрация на служител",
  "screen.hr.employee.details.showAll": "Покажи всички",
  "screen.hr.employee.details.showEmployeePayrollAccount": "Показване на сметката за заплати",
  "screen.hr.employee.details.status": "Статус",
  "screen.hr.employee.details.weeklyWorkDays": "{weeklyWorkDays} дни в седмицата",
  "screen.hr.employee.details.workingHours": "{workingHours} часове за {workingHoursInterval};",
  "screen.hr.employee.documents.all": "Всички",
  "screen.hr.employee.documents.annualPayslips": "Годишна ведомост за заплати",
  "screen.hr.employee.documents.anyDate": "Всеки период",
  "screen.hr.employee.documents.backTargetDescription": "Персонал / Служители / {статус} / {първо име} {последно име}",
  "screen.hr.employee.documents.certificateOfCitizenship": "Доказателство за гражданство",
  "screen.hr.employee.documents.certificatesOfRegistration": "Потвърждения за регистрация",
  "screen.hr.employee.documents.contracts": "Договор за услуги",
  "screen.hr.employee.documents.deregisterDocument": "Документи за дерегистрация",
  "screen.hr.employee.documents.employeeDocuments": "Файлове от {първо име} {последно име}",
  "screen.hr.employee.documents.employmentPermit": "Разрешение за работа",
  "screen.hr.employee.documents.generalDocuments": "Други",
  "screen.hr.employee.documents.identityCard": "Карта за самоличност",
  "screen.hr.employee.documents.payslips": "Доказателство за доходи",
  "screen.hr.employee.emptystate.buttonlabel": "Записване на служители",
  "screen.hr.employee.emptystate.message": "Започнете да регистрирате нови служители сега",
  "screen.hr.employee.emptystate.title": "Регистриране на служители",
  "screen.hr.employee.former.anyDateOfLeaving": "Всяка дата на излизане",
  "screen.hr.employee.registration.stepper.additional": "Допълнителна информация",
  "screen.hr.employee.registration.stepper.documents": "Качване на документи",
  "screen.hr.employee.registration.stepper.personal": "Лични данни",
  "screen.hr.employee.registration.stepper.status": "Стъпка {activeStep} от {totalSteps}",
  "screen.hr.employee.registration.stepper.summary": "Резюме",
  "screen.hr.employees.current.registerEmployee": "Регистрирайте служител",
  "screen.hr.employees.current.uploadPayslip": "Качване на платежна бележка",
  "screen.hr.employees.documents.recordType.title": "Файлове, качени в досието на персонала в {дата}",
  "screen.hr.employees.documents.title": "Файлове в личното досие",
  "screen.hr.navbar.documents": "Файлове",
  "screen.hr.navbar.employees": "Служители",
  "screen.hr.navbar.heading": "Персонал",
  "screen.hr.navbar.overview": "Преглед",
  "screen.hr.navbar.tabbar.current": "Текущ DN",
  "screen.hr.navbar.tabbar.documents": "Файлове",
  "screen.hr.navbar.tabbar.employees": "Служители",
  "screen.hr.navbar.tabbar.former": "Пенсиониран DN",
  "screen.hr.navbar.tabbar.inprogress": "В процес на изпълнение",
  "screen.hr.navbar.tabbar.preRegistartionTickets": "Запитвания ({preRegistrationTicketCount})",
  "screen.hr.overview.card.analysis": "Издаване на месечни оценки за",
  "screen.hr.overview.card.billing": "Издаване на месечни отчети за",
  "screen.hr.overview.card.globalAnalysis": "Публикуване на оценки за цялата компания",
  "screen.hr.overview.card.globalBilling": "Освобождаване на споразумения за цялата компания",
  "screen.hr.overview.card.overview": "Оповестяване на месечните данни за персонала за",
  "screen.hr.overview.card.registration": "Регистрация/дерегистрация на служителите",
  "screen.hr.overview.finished.message": "{{br} {br}Благодаря ви за проверката и одобрението на ведомостта за заплати. По-долу ще намерите вашите доклади.{br} {br} С уважение, {br}{подпис}",
  "screen.hr.overview.registration": "{{br} {br} Моля, уведомете за всички промени в потребителите на услуги, за да можем да извършим регистрацията и дерегистрацията.{br} {br} С уважение,{br} {подпис}",
  "screen.hr.overview.registration.button": "Регистриране/дерегистрация на служители",
  "screen.hr.overview.ticketsWelcome.inProgress": "{salutation}, {br} {br}Благодарим Ви за изпращането на личните данни. В хода на обработката възникнаха някои въпроси:",
  "screen.hr.overview.welcome.closed": "{{br} {br} Благодарим Ви, че представихте всички необходими данни за персонала. Ние ще ги обработим в съответствие с договорените срокове и ще се свържем с вас за всякакви запитвания.",
  "screen.hr.overview.welcome.inProgress": "{{br} {br} Моля да ми предоставите всички необходими данни за персонала за периода на изплащане на заплатите {период}{дата на изплащане}. {br}{br}С уважение,{br}{подпис}",
  "screen.hr.personaldata.address": "Улица и номер на къщата",
  "screen.hr.personaldata.addressAddition": "Адресен суфикс",
  "screen.hr.personaldata.addressHead": "Адрес",
  "screen.hr.personaldata.area.validation": "Моля, посочете местоположение",
  "screen.hr.personaldata.birthdate.validation": "Моля, въведете дата на раждане",
  "screen.hr.personaldata.citizenship": "Гражданство",
  "screen.hr.personaldata.citizenship.validation": "Моля, посочете гражданство",
  "screen.hr.personaldata.city": "Местоположение",
  "screen.hr.personaldata.country": "Държава",
  "screen.hr.personaldata.country.validation": "Моля, изберете държава",
  "screen.hr.personaldata.dateOfBirth": "Дата на раждане",
  "screen.hr.personaldata.dateOfJoining": "Дата на вписване",
  "screen.hr.personaldata.description": "Следната информация е задължителна за регистрация преди започване на услугата.",
  "screen.hr.personaldata.diverse": "Водолази",
  "screen.hr.personaldata.employmentInsuranceType": "Застрахователна група",
  "screen.hr.personaldata.employmentRelationship": "Трудово правоотношение",
  "screen.hr.personaldata.employmenttypeheader": "Вид заетост",
  "screen.hr.personaldata.employmenttypeinformationbody": "Всички брутни месечни доходи над прага за ниски доходи се считат за задължително напълно осигурени.",
  "screen.hr.personaldata.employmenttypeinformationhead": "Напълно застраховани / минимално застраховани:",
  "screen.hr.personaldata.employmenttype.validation": "Моля, изберете трудово правоотношение",
  "screen.hr.personaldata.entrydate.invalid": "Невалидна дата на въвеждане",
  "screen.hr.personaldata.entrydate.validation": "Моля, въведете дата на влизане",
  "screen.hr.personaldata.female": "Жена",
  "screen.hr.personaldata.firstname": "Първо име",
  "screen.hr.personaldata.firstname.validation": "Моля, въведете първо име",
  "screen.hr.personaldata.fullTime": "Напълно застрахован",
  "screen.hr.personaldata.houseNumber": "Номер на къщата",
  "screen.hr.personaldata.houseNumber.validation": "Моля, въведете номер на къща",
  "screen.hr.personaldata.insurancedata": "Застрахователни данни",
  "screen.hr.personaldata.insurancedatainformationbody": "Ако няма номер на СИ, моля, оставете полето за национален осигурителен номер празно.",
  "screen.hr.personaldata.insurancedatainformationhead": "Намек:",
  "screen.hr.personaldata.insurancetype.validation": "Моля, изберете застраховка",
  "screen.hr.personaldata.lastname": "Фамилия",
  "screen.hr.personaldata.lastname.validation": "Моля, въведете фамилно име",
  "screen.hr.personaldata.male": "Мъжки",
  "screen.hr.personaldata.marginally": "Незначителен",
  "screen.hr.personaldata.navback": "Персонал / Служители",
  "screen.hr.personaldata.navheader": "Регистриране на служители",
  "screen.hr.personaldata.nextpage": "В допълнение към допълнителната информация",
  "screen.hr.personaldata.notSpecified": "Не е посочено",
  "screen.hr.personaldata.personaldata": "Лични данни",
  "screen.hr.personaldata.personalinformation": "Лични данни",
  "screen.hr.personaldata.previouslyEmployed": "Било ли е това лице някога на работа при вас през последните 12 месеца?",
  "screen.hr.personaldata.socialsecuritynumber.validation": "Моля, посочете национален осигурителен номер",
  "screen.hr.personaldata.ssn": "Национален осигурителен номер",
  "screen.hr.personaldata.state.validation": "Моля, въведете федерален щат",
  "screen.hr.personaldata.streetnr.validation": "Моля, въведете адрес",
  "screen.hr.personaldata.workplaceState": "Състояние на работното място",
  "screen.hr.personaldata.zipCode": "ПОЩЕНСКИ КОД",
  "screen.hr.personaldata.zipcode.length.validation": "",
  "screen.hr.personaldata.zipcode.validation": "Моля, въведете пощенски код",
  "screen.hr.records.move.multiple.dialog.title": "Преместване на файлове",
  "screen.hr.records.move.single.dialog.title": "Преместване на файла",
  "screen.hr.records.navbar.tab.list": "Файлове ({брой})",
  "screen.hr.records.navbar.tab.tickets": "Запитвания ({count})",
  "screen.hr.records.transfer.dialog.text": "Искате ли да прехвърлите <b>всички файлове и информация за заплатите</b> за периода {periodStart} - {periodEnd} в TPA за осчетоводяване?",
  "screen.hr.records.transfer.dialog.textRecordType": "Искате ли да прехвърлите <b>всички файлове и информация от \"{име}\"</b> за периода {periodStart} - {periodEnd} в TPA за осчетоводяване?",
  "screen.hr.records.transfer.dialog.title": "Предаване на файлове",
  "screen.hr.records.types.header": "Персонал",
  "screen.hr.records.upload.button": "Качване на файлове",
  "screen.hr.records.upload.navbar.back": "Персонал / Досиета",
  "screen.hr.recordTypes.title": "Изберете тип документи",
  "screen.hr.registration.flow.navback": "Отстъпете назад",
  "screen.hr.registration.flow.navheader": "{първо име} {последно име} влезте",
  "screen.hr.staff.payrollAccount.navBack": "",
  "screen.hr.summary.additionalinformation": "Допълнителна информация",
  "screen.hr.summary.bonuses": "Надбавки",
  "screen.hr.summary.changeData": "Добави в списъка",
  "screen.hr.summary.completeAdditionalData": "Предоставили сте цялата допълнителна информация.",
  "screen.hr.summary.completePersonalData": "Въвели сте цялата задължителна информация за регистрация в регионалната здравноосигурителна каса.",
  "screen.hr.summary.day": "Ден",
  "screen.hr.summary.daysPerWeek": "Дни на седмица",
  "screen.hr.summary.description": "Проверете дали предоставената информация е правилна, за да я изпратите на TPA в следващата стъпка.",
  "screen.hr.summary.dialogQuestion1": "Искате ли",
  "screen.hr.summary.dialogQuestion2": " и да го изпратите на вашия данъчен консултант на TPA за регистрация?",
  "screen.hr.summary.documents": "Файлове",
  "screen.hr.summary.gender": "Пол",
  "screen.hr.summary.grossSalary": "Брутна заплата в {валута}",
  "screen.hr.summary.hours": "Часове",
  "screen.hr.summary.incompleteAdditionalData": "Все още не сте въвели цялата допълнителна информация. Моля, имайте предвид, че някои от допълнителните данни може да са необходими до първото плащане на заплатата. Можете да ги добавите по всяко време.",
  "screen.hr.summary.month": "Месец",
  "screen.hr.summary.name": "{първо име} {последно име}",
  "screen.hr.summary.netSalary": "Нетна заплата в {валута}",
  "screen.hr.summary.noPreregistration": "Основните данни не са променени. Предаването на данни не е необходимо.",
  "screen.hr.summary.openDialog": "Подаване на заявление",
  "screen.hr.summary.summary": "Резюме",
  "screen.hr.summary.transferDataChange": "Предаване на промяна в основните данни",
  "screen.hr.summary.transferEmployeeDialog.success.subtitle": " е създадена и предадена на вашия данъчен консултант на TPA за регистрация.",
  "screen.hr.summary.transferEmployeeDialog.success.subtitle.elda": "{име} е създаден. Можете да видите и изтеглите протокола ELDA по-долу. Алтернативно, протоколът може да бъде изтеглен от личното досие на служителя.",
  "screen.hr.summary.week": "Седмица",
  "screen.login.button.forgot_password": "ЗАБРАВЕНА ПАРОЛА?",
  "screen.login.button.resendCode": "Изпратете кода отново",
  "screen.login.form.divider": "или",
  "screen.login.form.multiFactorAuth.label": "Код",
  "screen.login.form.multiFactorAuth.validation_error": "Моля, въведете кода.",
  "screen.login.form.password.label": "Парола",
  "screen.login.form.password.validation_error": "Моля, въведете парола",
  "screen.login.form.submit": "Влезте в системата",
  "screen.login.form.title": "Влезте в системата",
  "screen.login.form.username.label": "Идентификатор на потребителя / Адрес на електронна поща",
  "screen.login.form.username.validation_error": "Моля, въведете потребителското си име",
  "screen.login.multiFactorAuth": "Моля, въведете кода, който изпратихме на телефонния ви номер чрез SMS.",
  "screen.login.welcome": "Добре дошли в нашия уебсайт! Моля, въведете вашия имейл адрес.",
  "screen.module.overview.report.empty.message": "Все още не са качени никакви доклади",
  "screen.module.overview.report.empty.title": "Няма налични доклади",
  "screen.module.overview.report.upload": "Качване на доклад",
  "screen.moduleReportUpload.comment": "Текст на предаването",
  "screen.moduleReportUpload.title": "Качване на отчети",
  "screen.not_found.button.text": "Изстрелване от пилотската кабина",
  "screen.not_found.header": "Грешка 404",
  "screen.not_found.message": "Наистина сме добри в числата, но всъщност искахме да ви спестим това.",
  "screen.overview.salutation.female": "Уважаема г-жо {последно име}",
  "screen.overview.salutation.general": "Уважаеми дами и господа",
  "screen.overview.salutation.male": "Уважаеми г-н {последно име}",
  "screen.overview.salutation.notSpecified": "Добър ден {първо име} {последно име}",
  "screen.records.upload.header": "Качване на файл {recordType}",
  "screen.records.upload.message": "Сега започнете да качвате първите файлове.",
  "screen.records.upload.title": "Качване на {recordType}",
  "screen.server_error.button.text": "Към пилотската кабина",
  "screen.server_error.header": "505 Грешка",
  "screen.server_error.message": "Наистина сме добри в числата, но всъщност искахме да ви спестим това.",
  "screen.termsNotAccepted.button": "Обратно към преглед на компанията",
  "screen.termsNotAccepted.buttonAcceptNow": "Приемете сега",
  "screen.termsNotAccepted.header": "Приемане на условията за ползване в очакване",
  "screen.termsNotAccepted.message": "Условията за ползване на вашата компания все още не са приети. Ако имате някакви въпроси, моля, свържете се с вашия съветник по TPA.",
  "screen.termsOfUse.accept": "Знак",
  "screen.termsOfUse.alreadySignedInfo": "Условията за ползване вече са приети или приемането им вече не е необходимо.",
  "screen.termsOfUse.backToLogin": "Обратно към влизането",
  "screen.termsOfUse.checkbox": "С настоящото приемам условията за ползване",
  "screen.termsOfUse.success.sign": "Условията за ползване са приети успешно",
  "screen.termsOfUse.title": "Приемане на условията за ползване",
  "screen.termsOfUseWithCompanyNames.title": "Приемане на условията за ползване за [брой] компании",
  "screen.termsOfUseWithCompanyName.title": "Приемане на условията за ползване",
  "screen.tickets.navbar.tabbar.closed": "Готово",
  "screen.tickets.navbar.tabbar.open": "Отворете",
  "screen.tickets.navbar.tabbar.reported": "Осигурено от мен",
  "screen.upgradeToMicrosoft.error.azureUserAlreadyExists": "Този потребител на Microsoft вече е регистриран с имейл адреса {existingUsername}",
  "screen.upgradeToMicrosoft.feature.projects.text": "Моля, надстройте профила си, за да получите достъп до функцията \"Проекти\".",
  "screen.upgradeToMicrosoft.form.code.description": "Моля, въведете кода, който ви изпратихме с SMS, за да продължите с обновяването на Microsoft.",
  "screen.upgradeToMicrosoft.form.code.label": "Код",
  "screen.upgradeToMicrosoft.form.password.label": "Парола",
  "screen.upgradeToMicrosoft.form.password.validation_error": "Моля, въведете паролата си",
  "screen.upgradeToMicrosoft.needCompany": "Свързването с акаунт в РС може да се извърши само когато е избрана фирма.",
  "screen.upgradeToMicrosoft.resendButton.label": "Изпратете кода отново",
  "screen.upgradeToMicrosoft.title": "Свързване на акаунт в Microsoft",
  "search.caption.numCompanies": "Компания: <b>{count}</b>",
  "search.caption.numDespositedPersons": "Депозирани лица: <b>{count}</b>",
  "search.caption.numDocuments": "Файлове: <b>{count}</b>",
  "search.caption.numPersons": "Лица: <b>{брой}</b>",
  "search.caption.numProjects": "Проекти: <b>{count}</b>",
  "search.caption.numResults": "Резултати: <b>{count}</b>",
  "search.caption.numSelected": "Избрани вписвания: <b>{брой}</b>",
  "search.caption.numTickets": "Запитвания: <b>{count}</b>",
  "search.caption.numUploads": "Качвания: <b>{count}</b>",
  "search.caption.numUsers": "Потребител: <b>{count}</b>",
  "search.placeholder.companies": "Компаниите търсят ...",
  "search.placeholder.projects": "търсене на проект ...",
  "search.placeholder.search": "Търсене ...",
  "search.placeholder.searchForActiveUsers": "Търсене на активни потребители по име...",
  "search.placeholder.searchForEmployees": "търсене на имена...",
  "search.placeholder.searchForEmployeesPersonalNumber": "търсене на имена или персонални номера...",
  "search.placeholder.searchForFileName": "Търсене на име на файл...",
  "search.placeholder.searchForFiles": "Търсене на имена на файлове...",
  "search.placeholder.searchForUsers": "търсене на имена...",
  "search.placeholder.searchForUsersFileName": "Търсене по име или име на файл...",
  "selectQesPosition.message": "Изберете позиция за подписа и щракнете върху {button}.",
  "selectQesPosition.message.collective": "За всеки потребител изберете позиция за неговия подпис и щракнете върху {button} (Напред).",
  "selectQesPosition.title": "Избор на позиция за подпис",
  "settings.navbar.company": "Компания",
  "settings.navbar.companyData": "Основни данни",
  "settings.navbar.features": "Добавки",
  "settings.navbar.general": "Обща информация",
  "settings.navbar.optionalSideBarItems": "Допълнителни елементи от менюто",
  "settings.navbar.userManagement": "Администриране на потребителите",
  "settings.notifications.activateAll": "АКТИВИРАНЕ НА ВСИЧКИ",
  "settings.notifications.assignments": "Разпределения",
  "settings.notifications.deactivateAll": "ДЕАКТИВИРАНЕ НА ВСИЧКИ",
  "settings.notifications.deadlineExceeded": "Пропуснат краен срок",
  "settings.notifications.deadlineReminder": "Напомняне на крайния срок",
  "settings.notifications.generalDocumentUpload": "Качване на общи файлове",
  "settings.notifications.label": "Известия по имейл, когато:",
  "settings.notifications.messages": "Новини",
  "settings.notifications.modules.accounting": "Счетоводство",
  "settings.notifications.modules.hr": "Персонал",
  "settings.notifications.modules.other": "Други области",
  "settings.notifications.project.items.uploaded": "Качване на проекти",
  "settings.notifications.recordProvision": "Качвания на клиента",
  "settings.notifications.release": "Освобождава",
  "settings.notifications.remindAfterHours.label": "Памет след {часове}h",
  "settings.notifications.reportProvision": "Предоставяне на доклад",
  "settings.notifications.tickets": "Запитвания",
  "settings.notifications.title": "Известия",
  "settings.personalData.email": "Адрес на електронна поща",
  "settings.personalData.language": "Език",
  "settings.personalData.location": "Местоположение",
  "settings.personalData.microsoftAccount": "Акаунт в Microsoft",
  "settings.personalData.microsoftUpgrade.info": "Акаунт в Microsoft:{br}{br}Можете да свържете акаунта си в TPA Connect с акаунта си в Microsoft. Това ви позволява да използвате всички функции на TPA Connect.{br}{br}След като свържете акаунта си с Microsoft, ще трябва да влезете в системата, като използвате акаунта си в Microsoft вместо имейл адреса си.",
  "settings.personalData.microsoftUpgrade.label": "УСЪВЪРШЕНСТВАНЕ",
  "settings.personalData.phoneNumber": "Телефонен номер",
  "settings.personalData.role": "Roll",
  "settings.personalData.title": "Лични данни",
  "settings.personalData.username": "Потребителско име",
  "settings.userManagement.permissions.existingUser.navbar.back": "Администриране на потребители / Задаване на роля",
  "settings.userManagement.permissions.existingUser.navbar.backToUser": "Администриране на потребители / Присвояване на роля / {име на служител}",
  "settings.userManagement.permissions.navbar.back": "Администриране на потребители / Избор на лице за контакт / Присвояване на роля",
  "settings.userManagement.permissions.navbar.backToUser": "Администриране на потребители / Избор на лице за контакт / Присвояване на роля / {име на служител}",
  "settings.userManagement.roles.navbar.back": "Администрация на потребителя / Избор на лице за контакт",
  "sidebar.advisor.employees": "Списък на персонала",
  "sidebar.advisor.inbox": "Пощенска кутия",
  "sidebar.advisor.tickets": "Запитвания",
  "sidebar.advisor.uploads": "Качва",
  "sidebar.customerView": "Изглед на клиента",
  "sidebar.faceToFace.button": "Свържете се с",
  "sidebar.faceToFace.title": "Лице в лице с",
  "sidebar.list.accounting": "Счетоводство",
  "sidebar.list.analysis": "",
  "sidebar.list.cockpit": "Кокпит",
  "sidebar.list.documents": "Доклади",
  "sidebar.list.faceToFace": "Лице в лице",
  "sidebar.list.hr": "Персонал",
  "sidebar.list.kpi": "Факти и цифри",
  "sidebar.list.personnelFile": "Лично досие",
  "sidebar.list.projects": "Проекти",
  "sidebar.list.results": "Оценки",
  "sidebar.list.settings": "Настройки",
  "sidebar.list.support": "Подкрепа",
  "sidebar.list.tickets": "Запитвания",
  "signDialog.noQesEnabled.message": "С настоящото потвърждавам издаването на документа.",
  "signDialog.noQesRequired.button.click": "Освобождаване на клик",
  "signDialog.noQesRequired.button.qes": "Електронен подпис",
  "signDialog.noQesRequired.message": "За одобряване можете да избирате между одобряване с кликване и с електронен подпис.",
  "signDialog.qesblocked.message": "Понастоящем подписването с електронен подпис не е възможно, тъй като в този момент процедурата по подписване вече е започнала от друго лице. Моля, опитайте отново след около 15 минути.",
  "signDialog.qesblocked.title": "Освобождаване в момента не е възможно",
  "signDialog.qesRequired.confirm": "Добре, подпишете се електронно",
  "signDialog.qesRequired.message": "За освобождаването е поискан електронен подпис. Сега ще бъдете пренасочени към електронния подпис.",
  "signDialog.title": "Освобождаване",
  "soleEarnerStatus.Alleinerzieher": "Самотен родител",
  "soleEarnerStatus.Alleinverdiener": "Едноличен търговец",
  "soleEarnerStatus.Nein": "Не",
  "staff.documents.navbar.heading": "Моите файлове",
  "staff.personnelFile.navbar.heading": "Здравейте {име}!",
  "stepper.optional": "по избор",
  "stepper.progress": "Прогрес",
  "support.admins.title": "Администратори",
  "support.filter.option.activeChats": "Само активни чатове",
  "support.filter.option.allChats": "Всички чатове",
  "support.filter.option.inactiveChats": "Само неактивни чатове",
  "support.filter.setting.showInactiveColleagues": "Показване на неактивни служители",
  "support.inactiveEmployee": "Бивш служител",
  "support.partners.title": "Партньори",
  "support.privateChat.dialog.markAsPrivate.confirm": "Маркиране като поверително",
  "support.privateChat.dialog.markAsPrivate.message": "Ако даден чат е маркиран като конфиденциален, <b>никой друг човек</b> не може да преглежда чата и да отговаря на съобщения във ваше отсъствие.{br}{br}Клиентът ви <b>няма да бъде информиран</b> за тази настройка.",
  "support.privateChat.dialog.markAsPrivate.title": "Маркирайте чата като поверителен?",
  "support.privateChat.dialog.markAsPublic.confirm": "Отказ от конфиденциалност",
  "support.privateChat.dialog.markAsPublic.message": "Когато даден чат вече не е маркиран като конфиденциален, <b>други хора</b> могат да преглеждат чата и да отговарят на съобщения във ваше отсъствие.{br}{br}Клиентът ви <b>не е</b> информиран</b> за тази настройка.",
  "support.privateChat.dialog.markAsPublic.title": "Вече не отбелязвате чата като конфиденциален?",
  "support.privateChat.info": "Чатът е маркиран като <b>конфиденциален</b> и не може да бъде разглеждан от други лица.",
  "support.privateChat.info.markAsPublic": "ПОВДИГАНЕ НАГОРЕ",
  "support.privateChat.info.mobile": "Чатът е <b>конфиденциален</b>.",
  "support.privateChat.placeholder": "Въведете съобщение ...",
  "support.responsible.title": "Членове на екипа",
  "support.search.navbar.title": "Резултати от търсенето",
  "support.substitute.author": "{substituteName} (заместител на {authorName})",
  "support.substitute.back": "ОБРАТНО КЪМ МОЯ ЧАТ",
  "support.substitute.back.mobile": "МОЯТ ЧАТ",
  "support.substitute.warning": "<b>Внимание:</b> Вие сте в чата на <b>{име}</b>.",
  "support.substitute.warning.mobile": "Чат от <b>{име}</b>",
  "support.yourContacts": "Вашите лица за контакт",
  "svsAccount.transactions.table.contextMenu.download": "",
  "svsAccount.transactions.table.contextMenu.show": "",
  "svsAccount.transactions.table.filters.quarter": "",
  "svsAccount.transactions.table.label.amount": "",
  "svsAccount.transactions.table.label.quarter": "",
  "svsAccount.transactions.table.label.text": "",
  "svsAccount.transactions.table.search.count": "",
  "svsAccount.transactions.table.search.placeholder": "",
  "svsAccount.transactions.title": "",
  "table.button.release": "ИЗПЪЛНЕНИЕ",
  "table.employees.dateOfJoining": "Дата на вписване",
  "table.employees.dateOfJoiningExiting": "Дата на влизане/излизане",
  "table.employees.dateOfLeaving": "Дата на излизане",
  "table.employees.dateOfTransmitting": "Предава се на",
  "table.employees.documents.date": "Дата",
  "table.employees.documents.document": "Файл",
  "table.employees.documents.documentName": "Наименование",
  "table.employees.documents.downloadDocuments": "Изтегляне на файлове",
  "table.employees.documents.uploadedBy": "Качен от",
  "table.employees.employee": "Служител",
  "table.employees.jobDescription": "Длъжност",
  "table.employees.missingDetails": "Липсващи данни",
  "table.employees.personalId": "Номер на персонала",
  "table.employees.reasonForLeaving": "Причина за напускане",
  "table.employees.status": "Статус",
  "table.employees.status.active": "Активен",
  "table.employees.status.inactive": "Неактивен",
  "table.employees.status.inDataChange": "Промяна на основните данни",
  "table.employees.status.inDataChangeNotTransferred": "Изменение (проект)",
  "table.employees.status.inDeregistration": "При дерегистрация",
  "table.employees.status.inDeregistrationNotTransferred": "Дерегистрация (проект)",
  "table.employees.status.inRegistration": "При регистрация",
  "table.employees.status.inRegistrationNotTransferred": "Заявление (проект)",
  "table.label.changes": "Промени",
  "table.label.changeUsername": "Изпращане на имейл за промяна на потребителските имена",
  "table.label.comment": "Коментар:",
  "table.label.companyName": "Име на компанията",
  "table.label.contactPerson": "Свържете се с",
  "table.label.contactPersonId": "Личен номер",
  "table.label.costCenter": "Разходен център",
  "table.label.createdAt": "Дата на качване",
  "table.label.createdAt.variant": "Създаден на",
  "table.label.createdBy": "Създаден от",
  "table.label.date": "Дата",
  "table.label.dateOfJoining": "Дата на вписване",
  "table.label.dateOfJoiningExiting": "Дата на влизане/излизане",
  "table.label.dateOfLeaving": "Дата на излизане",
  "table.label.dateOfTransmitting": "Предава се на",
  "table.label.deleteUser": "Изтриване на потребител",
  "table.label.document": "Файл",
  "table.label.documentName": "Наименование",
  "table.label.documentType": "Файл",
  "table.label.downloadDocuments": "Изтегляне на файлове",
  "table.label.dueDate": "Краен срок",
  "table.label.dueDateForDraft": "Краен срок за освобождаване",
  "table.label.email": "Адрес на електронна поща",
  "table.label.employee": "Служител",
  "table.label.file": "Файл",
  "table.label.fileName": "Наименование",
  "table.label.folder": "Папка",
  "table.label.holiday": "Ваканция",
  "table.label.info": "Информация",
  "table.label.jobDescription": "Длъжност",
  "table.label.lastAuthenticatedAt": "Последно влизане",
  "table.label.lastAuthenticatedAt.registered.tooltip": "Регистриран потребител",
  "table.label.lastAuthenticatedAt.unregistered.tooltip": "Потребителят не е регистриран",
  "table.label.lastCreated": "Последно създаден",
  "table.label.members": "Членове",
  "table.label.message": "Съобщение",
  "table.label.messages": "Новини",
  "table.label.missingDetails": "Липсващи данни",
  "table.label.modulePermissions": "Разрешения на ниво област",
  "table.label.modules": "Области",
  "table.label.needsRelease": "Изисква се освобождаване",
  "table.label.openReleases": "Отворени версии",
  "table.label.otherAbsence": "Други отсъствия",
  "table.label.personalId": "Номер на персонала",
  "table.label.personalNumber": "Номер на персонала",
  "table.label.phoneNumber": "Телефонен номер",
  "table.label.reasonForLeaving": "Причина за напускане",
  "table.label.recordedBy": "Заснет от",
  "table.label.release": "Освобождава",
  "table.label.released": "Предавани",
  "table.label.releasedAt": "Предава се на",
  "table.label.releaseRequestedBy": "Изданието е предоставено от",
  "table.label.role": "Roll",
  "table.label.sentBy": "Изпратено от",
  "table.label.sickLeave": "Отпуск по болест",
  "table.label.status": "Статус",
  "table.label.teamMessages": "Новини за отбора",
  "table.label.ticket": "Консултация",
  "table.label.ticketBy": "Въпрос, зададен от",
  "table.label.ticketDescription": "Наименование",
  "table.label.ticketDueDate": "Краен срок",
  "table.label.tickets": "Запитвания",
  "table.label.transferStatus": "Трансфер",
  "table.label.transmittedAt": "Предава се на",
  "table.label.type": "Изкуство",
  "table.label.updatedAt": "Последна редакция",
  "table.label.updatedAt.variant": "Последна промяна",
  "table.label.uploadedBy": "Качен от",
  "table.label.username": "Потребителско име",
  "table.noResults.message": "Търсенето ви не е довело до резултати.",
  "table.noResults.title": "Няма резултати от търсенето",
  "taxAccount.pay.button": "Плащане на данъчни задължения",
  "taxAccount.transactions.table.filters.date": "Дата",
  "taxAccount.transactions.table.filters.tax": "Данък",
  "taxAccount.transactions.table.label.amount": "Сума",
  "taxAccount.transactions.table.label.date": "Дата",
  "taxAccount.transactions.table.label.due": "Зрялост",
  "taxAccount.transactions.table.label.taxPeriod": "Период",
  "taxAccount.transactions.table.label.text": "Наименование",
  "taxAccount.transactions.table.search.count": "Транзакции: <b>{count}</b>",
  "taxAccount.transactions.table.search.placeholder": "Търсене на транзакции",
  "taxAccount.transactions.title": "Салдо по данъчната сметка: [стойност]",
  "termsOfUse.checkbox.error": "Моля, приемете условията за ползване",
  "termsOfUse.upcoming.navbar.button": "Приемане на",
  "termsOfUse.upcoming.navbar.message": "Условията за използване на приложението TPA Connect ще се променят от {дата}.",
  "ticketList.emptystate.title": "Няма налични запитвания",
  "unpaidAccounts.invoices.pay": "Заплащане",
  "unpaidAccounts.invoices.table.filters.date": "Дата на документа",
  "unpaidAccounts.invoices.table.label.documentDate": "Дата на документа",
  "unpaidAccounts.invoices.table.label.documentNumber": "Номер на документа",
  "unpaidAccounts.invoices.table.label.documents": "",
  "unpaidAccounts.invoices.table.label.documentText": "Текст на документа",
  "unpaidAccounts.invoices.table.label.netAmount": "Нетна сума",
  "unpaidAccounts.invoices.table.label.opAmount": "Открита сума",
  "unpaidAccounts.invoices.table.label.receiptDate": "Дата на документа",
  "unpaidAccounts.invoices.table.label.receiptNumber": "Номер на документа",
  "unpaidAccounts.invoices.table.label.receiptText": "Текст на документа",
  "unpaidAccounts.invoices.table.search.count": "Фактури: <b>{count}</b>",
  "unpaidAccounts.invoices.table.search.placeholder": "Търсене на фактури",
  "unpaidAccounts.invoices.title": "Неизплатени фактури в TPA: [стойност]",
  "upload.category.analysis": "Доклад: Месечна оценка",
  "upload.category.annualReports": "Годишни отчети",
  "upload.category.articles": "Разработки",
  "upload.category.billing": "Доклад: Месечен отчет",
  "upload.category.certificateOfCitizenship": "Доказателство за гражданство",
  "upload.category.certificateOfRegistration": "Потвърждаване на регистрацията",
  "upload.category.contractOfEmployment": "Договор",
  "upload.category.contracts": "Договори",
  "upload.category.correspondence": "Кореспонденция",
  "upload.category.dataChangeConfirmation": "Промяна на основните данни",
  "upload.category.deregisterDocument": "Документи за дерегистрация",
  "upload.category.deregistrationConfirmation": "Дерегистрация на служителите",
  "upload.category.employmentPermit": "Разрешение за работа",
  "upload.category.identityCard": "Карта за самоличност",
  "upload.category.information": "Информация",
  "upload.category.notices": "Известия",
  "upload.category.other": "Други",
  "upload.category.payslip": "платежна бележка",
  "upload.category.registrationConfirmation": "Регистрация на служителите",
  "upload.category.report": "Доклади",
  "upload.category.taxOffice": "Данъчна служба",
  "upload.category.taxReturn": "Данъчни декларации",
  "userManagement.successDialog.changeUsername.message": "<b>{име на потребителя}</b> получава заявка за потвърждаване на новия имейл адрес.",
  "userManagement.successDialog.deleteUser.single.message": "<b>{userName}</b> беше успешно изтрит.",
  "userManagement.successDialog.deleteUser.title": "Потребителят е изтрит успешно.",
  "userManagement.successDialog.existingUser.superAdmin.multi.message": "Приети са настройките за <b>{брой} потребители</b>.",
  "userManagement.successDialog.existingUser.superAdmin.single.message": "Настройките за <b>{userName}</b> са приети.",
  "userManagement.successDialog.existingUser.superAdmin.title": "Потребителските настройки са запазени успешно",
  "userManagement.successDialog.existingUser.title": "Потребителските настройки са успешно предложени",
  "userManagement.successDialog.message": "Сега предложението ви трябва да бъде одобрено от <b>суперадминистратор</b>.",
  "userManagement.successDialog.newUser.superAdmin.multi.message": "<b>{брой} потребители</b> получават покана по имейл.",
  "userManagement.successDialog.newUser.superAdmin.single.message": "<b>{име на потребителя}</b> получава покана по имейл.",
  "userManagement.successDialog.newUser.superAdmin.title": "Потребителят е успешно поканен",
  "userManagement.successDialog.newUser.title": "Потребителят успешно предложи",
  "userManagement.successDialog.rejectUser.title": "Потребителят е успешно отхвърлен",
  "userManagement.successDialog.releaseExistingUser.title": "Потребителят е успешно освободен",
  "userManagement.successDialog.releaseNewUser.title": "Потребителят е успешно освободен и поканен",
  "userManagement.successDialog.sendUsernameChangeEmail.title": "Имейлът за промяна на потребителското име е изпратен успешно.",
  "userProfile.createBmdTodos": "Създаване на задачи в BMD за заявки",
  "userProfile.deleteAccount.message": "Сигурни ли сте, че искате да изтриете профила си? Това действие не може да бъде отменено!",
  "userProfile.deleteAccount.title": "Изтриване на акаунт",
  "userProfile.multiFactorAuth": "Активиране на двуфакторно удостоверяване с телефонен номер",
  "userProfile.navbar.heading": "Профил",
  "userProfile.upgrade.navbar.heading": "Надграждане",
  "userProfile.userInterests.title": "Интереси",
  "userReleaseInfoAdminDialog.releaseInfo.multi": "Моля, освободете новите потребители, за да ги поканите.",
  "userReleaseInfoAdminDialog.releaseInfo.single": "Моля, одобрете новия потребител, за да го поканите.",
  "userReleaseInfoAdminDialog.releaseMessage.multi": "{userName} е подал <b>{count} нови потребители</b>.",
  "userReleaseInfoAdminDialog.releaseMessage.single": "{userName} е подал <b>{count} на нов потребител</b>.",
  "userReleaseInfoAdminDialog.submit": "Преглед и споделяне",
  "userReleaseInfoAdminDialog.title": "Споделяне и покана на потребители",
  "userReleaseInfoDialog.cancel": "За администрацията на потребителите",
  "userReleaseInfoDialog.from": "от",
  "userReleaseInfoDialog.message": "Предложените от вас промени за следните потребители бяха одобрени/отхвърлени:",
  "userReleaseInfoDialog.rejected": "Отхвърлени",
  "userReleaseInfoDialog.released": "пуснат",
  "userReleaseInfoDialog.title": "Промяна на потребителя",
  "viewer.unsupported.message": "{името} не може да бъде показано в момента.",
  "webhook.employee.noSearch": "Изисква се въвеждане на данни за търсене",
  "webhook.employee.placeholder": "Име на служителя",
  "webhook.noCompanies": "Няма разрешения за дружества",
  "webhook.tickets.button": "Създаване на запитване",
  "webhook.tickets.info": "Присвояване на документа в област и избор на категория",
  "webhook.tickets.navbar": "Консултация относно {документ}",
  "webhook.tickets.taskTitle": "Задача за {име}",
  "webhook.tickets.title": "Създаване на запитване",
  "webhook.uploads.navbar": "Предаване на файлове",
  "welcomeCard.accounting.addMoreRecords.button": "Въвеждане на допълнителни документи",
  "welcomeCard.accounting.addRecords.button": "Въвеждане на документи",
  "welcomeCard.hr.addMoreRecords.button": "Предаване на повече файлове",
  "welcomeCard.hr.addRecords.button": "Предаване на файлове",
  "texterify_timestamp": "2024-11-21T10:06:27Z"
} as const;

export { bulgarian };
