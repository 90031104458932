import { observable } from "mobx";
import { POLLING_COMPANY_BADGES_MS } from "../config";
import { GLOBAL_FEATURES } from "../features";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { FeatureFlags, GeneralDocumentType, Knoedels } from "../network/APITypes";
import { ICompany, Module, generalDocumentTypes } from "../types/models";
import { isModuleActive } from "../util/modules";
import { hasAnySubsidiaryWithGroupPermission } from "../util/permissionHelpers";
import { IParsedPermissions } from "../util/permissionParser";
import { generalStore } from "./GeneralStore";
import { KPIStore } from "./KPIStore";
import { ResultsStore } from "./ResultsStore";

export class CompanyStore {
    readonly company: ICompany;
    readonly permissions: IParsedPermissions;
    readonly featureFlags: FeatureFlags;

    readonly kpiStore: KPIStore;
    readonly resultsStore: ResultsStore;

    @observable badgeCounts: Knoedels = {};
    companyBadgesTimer?: number = undefined;

    constructor(company: ICompany, permissions: IParsedPermissions, featureFlags: FeatureFlags) {
        this.company = company;
        this.permissions = permissions;
        this.featureFlags = featureFlags;

        this.kpiStore = new KPIStore(this.company, this.permissions);
        this.resultsStore = new ResultsStore(this.company);
    }

    // easy access to commonly used company properties

    get id() {
        return this.company.id;
    }
    get name() {
        return this.company.name;
    }

    // roles

    get isSuperAdmin() {
        return this.permissions.isSuperAdmin;
    }

    // results

    get canPayAccountTransaction() {
        return (
            this.isModuleActive("accounting") &&
            !!this.permissions.hasGlobalActions("accounting:accountTransaction:payment", ["create"])
        );
    }

    get canReadEmployerPayrollAccount() {
        if (!this.hasHrResults()) {
            return false;
        }

        return !!this.permissions.hasGlobalActions("hr:employerPayrollAccount", ["read"]);
    }

    canReadEmployeePayrollAccount(subsidiaryId?: string) {
        if (!this.hasHrResults(subsidiaryId)) {
            return false;
        }

        return !!this.permissions.hasSubsidiaryActions(subsidiaryId, "hr:employeePayrollAccount", ["read"]);
    }

    get canReadEmployerPayrollAccountDetails() {
        if (!this.hasHrResults()) {
            return false;
        }

        return !!this.permissions.hasGlobalActions("hr:employerPayrollAccount:details", ["read"]);
    }

    hasAccountingResults() {
        if (!GLOBAL_FEATURES.results) {
            return false;
        }

        if (!this.hasModule("accounting")) {
            return false;
        }

        // TPA employees should always see accounting results
        if (this.permissions.isAdvisor || !!this.permissions.raw?.roles.includes("tpa-accounting")) {
            return true;
        }

        // customers need the feature flag and permission to see accounting results
        return (
            this.company.hasAccountingResults === true &&
            !!this.permissions.hasGlobalActions("accounting:company:results", ["read"])
        );
    }

    hasHrResults(subsidiaryId?: string) {
        if (!GLOBAL_FEATURES.results || !GLOBAL_FEATURES.payrollAccounts) {
            return false;
        }

        if (!this.hasModule("hr")) {
            return false;
        }

        // TPA employees should always see HR results
        if (this.permissions.isAdvisor || this.permissions.raw?.roles.includes("tpa-hr")) {
            return true;
        }

        // customers need the feature flag and permission to see HR results
        return (
            this.company.hasHRResults === true &&
            (this.permissions.hasSubsidiaryActions(subsidiaryId, "hr:employeePayrollAccount", ["read"]) ||
                this.permissions.hasGlobalActions("hr:employerPayrollAccount", ["read"]) ||
                this.permissions.hasGlobalActions("hr:employerPayrollAccount:details", ["read"]))
        );
    }

    get canReadResults() {
        return this.hasAccountingResults() || this.hasHrResults();
    }

    get canReadResultsOrIsStaff() {
        return this.canReadResults || this.permissions.isStaff;
    }

    // analysis

    get canReadAnalysis() {
        if (!GLOBAL_FEATURES.analysis) {
            return false;
        }
        return this.canReadPowerBI;
    }

    get canReadPowerBI() {
        return this.featureFlags.powerbi.enabled;
    }

    // banking

    canReadBanking() {
        return this.permissions.hasGlobalActions("banking", ["read"]);
    }
    canCreateBanking() {
        return this.permissions.hasGlobalActions("banking", ["create"]);
    }
    canEditBanking() {
        return this.permissions.hasGlobalActions("banking", ["update"]);
    }
    canDeleteBanking() {
        return this.permissions.hasGlobalActions("banking", ["delete"]);
    }
    canAssignBankAccountTransactionInvoices() {
        if (!this.canReadBanking()) {
            return false;
        }
        return (
            // customers need to have the feature enabled, and CREATE and DELETE banking permissions
            (!!this.company.hasBankAccountTransactionInvoices && this.canCreateBanking() && this.canDeleteBanking()) ||
            // TPA advisors and accountants are always allowed
            this.permissions.isAdvisor ||
            this.isResponsibleForModule("accounting")
        );
    }

    get canPayUnpaidAccount() {
        return (
            this.hasModule("accounting") &&
            !!this.permissions.hasGlobalActions("accounting:unpaidAccount:payment", ["create"])
        );
    }

    // modules

    hasModule(module: Module) {
        // First check if module is configured
        if (!this.isModuleActive(module)) {
            return false;
        }

        // Now check if user has any module permission
        return this.permissions.modules?.includes(module);
    }

    isModuleActive(module: Module) {
        return isModuleActive(this.company, module);
    }

    isResponsibleForModule(module: Module) {
        return !!this.permissions?.map?.roles?.includes(`tpa-${module}`);
    }

    // reports

    get canReadAccountingReports() {
        return (
            this._canReadGlobalReports("accounting") ||
            hasAnySubsidiaryWithGroupPermission(this.permissions.raw, "accounting:reports", "canRead")
        );
    }
    get canReadHrReports() {
        return (
            this._canReadGlobalReports("hr") ||
            hasAnySubsidiaryWithGroupPermission(this.permissions.raw, "hr:reports", "canRead")
        );
    }
    get canReadAnyReports() {
        return this.canReadAccountingReports || this.canReadHrReports;
    }

    private _canReadGlobalReports(module: Module) {
        return this.permissions.hasGlobalActions(`${module}:company:reports`, ["read"]);
    }

    // documents

    canReadGeneralDocuments(documentType: GeneralDocumentType) {
        return this.permissions.hasGlobalPermission(`generalDocuments:${documentType}`, "canRead");
    }

    get canReadAnyGeneralDocuments() {
        return generalDocumentTypes.some(documentType => this.canReadGeneralDocuments(documentType));
    }

    get canReadDocs() {
        if (!this.company.generalDocumentsEnabled) {
            return false;
        }
        return this.canReadAnyGeneralDocuments || this.canReadAnyReports;
    }

    // features

    get canReadFeatures() {
        return this.featureFlags.features.enabled && this.permissions.hasGlobalActions("settings:features", ["read"]);
    }
    get canUpdateFeatures() {
        return this.featureFlags.features.enabled && this.permissions.hasGlobalActions("settings:features", ["update"]);
    }

    // badges

    async startPollingBadges(params?: { showOpenEmployeeDocumentReleasesDialog?: boolean }) {
        this.stopPollingBadges();

        // load the initial badge counts
        await this.loadBadgeCounts();

        // show the dialog if requested and there are open employee document releases
        if (params?.showOpenEmployeeDocumentReleasesDialog && !!this.badgeCounts.openEmployeeDocumentReleases) {
            generalStore.showOpenEmployeeDocumentReleasesDialog = true;
        }

        // start polling the badge counts
        this.companyBadgesTimer = setInterval(this.loadBadgeCounts, POLLING_COMPANY_BADGES_MS);
    }

    stopPollingBadges() {
        if (this.companyBadgesTimer) {
            clearInterval(this.companyBadgesTimer);
        }
    }

    loadBadgeCounts = async () => {
        if (this.permissions.raw) {
            try {
                this.badgeCounts = await API.getBadgeCounts(this.id);
            } catch (error) {
                generalStore.setError(t("error.loadBadges"), error);
            }
        }
    };

    // cleanup

    dispose() {
        this.stopPollingBadges();
    }
}
