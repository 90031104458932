const romanian = {
  "accounting.bankAccount.account.label": "Cont",
  "accounting.bankAccount.account.placeholder": "Cont",
  "accounting.bankAccount.account.title": "Selectați contul",
  "accounting.bankAccount.transactions.assignInvoices.account.error": "Categoriile de documente nu au putut fi încărcate",
  "accounting.bankAccount.transactions.assignInvoices.account.placeholder": "Categoria documentului",
  "accounting.bankAccount.transactions.assignInvoices.filters.bankAccountTransactions.dateRange.required": "Vă rugăm să selectați o dată de început și de sfârșit.",
  "accounting.bankAccount.transactions.assignInvoices.filters.bankAccountTransactions.title": "Tranzacții bancare",
  "accounting.bankAccount.transactions.assignInvoices.filters.records.recordTypes.title": "Simbolul documentului",
  "accounting.bankAccount.transactions.assignInvoices.filters.records.title": "Încasări",
  "accounting.bankAccount.transactions.assignInvoices.records.empty": "Nu s-au găsit dovezi",
  "accounting.bankAccount.transactions.assignInvoices.records.pagination.next": "Documentul următor",
  "accounting.bankAccount.transactions.assignInvoices.records.pagination.previous": "Documentul anterior",
  "accounting.bankAccount.transactions.assignInvoices.subtitle": "Vă rugăm să alocați chitanța unei tranzacții bancare.",
  "accounting.bankAccount.transactions.assignInvoices.title": "Alocarea de vouchere",
  "accounting.bankAccount.transactions.table.action.assignOpenInvoices": "Atribuiți vouchere deschise",
  "accounting.bankAccount.transactions.table.action.downloadBankStatement": "Export pentru postarea extraselor de cont",
  "accounting.bankAccount.transactions.table.action.downloadBuerf": "Export pentru rezervare import în NTCS",
  "accounting.bankAccount.transactions.table.emptyState.title": "Nu s-au găsit tranzacții",
  "accounting.bankAccount.transactions.table.label.amount": "Suma",
  "accounting.bankAccount.transactions.table.label.assignInvoice": "Atribuiți documentul",
  "accounting.bankAccount.transactions.table.label.date": "Data rezervării",
  "accounting.bankAccount.transactions.table.label.invoice": "Chitanță",
  "accounting.bankAccount.transactions.table.label.isPrivate": "Privat",
  "accounting.bankAccount.transactions.table.label.recipient": "Receptor",
  "accounting.bankAccount.transactions.table.label.text": "Textul rezervării",
  "accounting.bankAccount.transactions.table.label.valueDate": "Data valorii",
  "accounting.bankAccount.transactions.table.menu.deleteAssignedInvoice": "Eliminați link-ul documentului",
  "accounting.bankAccount.transactions.table.menu.markAsPrivate": "Marcați ca tranzacție privată",
  "accounting.bankAccount.transactions.table.menu.removePrivate": "Anularea marcării private",
  "accounting.bankAccount.transactions.table.search.count": "Tranzacții: <b>{count}</b>",
  "accounting.bankAccount.transactions.table.search.placeholder": "Căutarea tranzacțiilor",
  "accounting.bankAccount.transactions.table.uploadInvoice.progress.title": "Chitanța este încărcată",
  "accounting.bankAccount.transactions.table.uploadInvoice.subsidiaryAndRecordType.title": "Selectați codul societății și simbolul documentului",
  "accounting.bankAccount.transactions.title": "Tranzacții",
  "accounting.bankConnection.delete.message": "Chiar doriți să ștergeți contul bancar {numele}?",
  "accounting.bankConnection.delete.title": "Ștergeți detaliile bancare",
  "accounting.bankConnections.accountHolder": "Proprietar:",
  "accounting.bankConnections.accountType": "Tipul de cont:",
  "accounting.bankConnections.accountType.Bausparen": "Economiile societății de construcții",
  "accounting.bankConnections.accountType.Checking": "Cont curent",
  "accounting.bankConnections.accountType.CreditCard": "Card de credit",
  "accounting.bankConnections.accountType.Loan": "Cont de împrumut",
  "accounting.bankConnections.accountType.Membership": "Acțiuni ale cooperativelor",
  "accounting.bankConnections.accountType.Savings": "Cont de economii",
  "accounting.bankConnections.accountType.Security": "Cont de valori mobiliare",
  "accounting.bankConnections.addBankConnection": "Adăugați detalii bancare",
  "accounting.bankConnections.balance": "Soldul contului:",
  "accounting.bankConnections.emptyState.description": "Adăugați un nou cont bancar.",
  "accounting.bankConnections.emptyState.title": "Fără detalii bancare",
  "accounting.bankConnections.expired": "Expirat",
  "accounting.bankConnections.expiredAt": "A expirat la {data}",
  "accounting.bankConnections.expiresAt": "Expiră la {data}",
  "accounting.bankConnections.iban": "IBAN:",
  "accounting.bankConnections.lastSuccessfulUpdate": "pe {data}",
  "accounting.bankConnections.pending": "Remarcabil",
  "accounting.bankConnections.refreshBankConnection": "Reînnoiți contul bancar",
  "accounting.bankConnections.refreshPending": "Reînnoiri în curs de desfășurare",
  "accounting.bankConnections.refreshPending.info": "Reînnoirea datelor bancare se desfășoară într-o fereastră separată. Dacă fereastra a fost închisă înainte de finalizarea actualizării, trebuie să faceți din nou clic pe \"Reînnoire date bancare\".",
  "accounting.bankConnections.refreshPending.label": "Procesul de reînnoire este în curs de desfășurare",
  "accounting.bankConnections.upToDate": "Confirmat",
  "accounting.details.edit.header": "Editați documentul",
  "accounting.details.updatedAt": "modificată ultima dată la",
  "accounting.details.upload.files": "Fișiere",
  "accounting.details.upload.skip": "Treci peste toate",
  "accounting.finished.reports": "Rapoarte",
  "accounting.table.label.totalGrossAmount": "Valoarea facturii",
  "addFolderDialog.button.text.create": "Creați",
  "addFolderDialog.folderName": "Numele dosarului",
  "addFolderDialog.info": "Mergeți la Sharepoint pentru a gestiona membrii.",
  "addFolderDialog.title": "Creați un dosar",
  "advisor.uploads.uploadReports.title": "Transmiterea rapoartelor",
  "analysis.powerBI.reports.table.label.description": "",
  "analysis.powerBI.reports.table.label.name": "",
  "analysis.powerBI.reports.table.search.count": "",
  "analysis.powerBI.reports.table.search.placeholder": "",
  "analysis.powerBI.reports.title": "",
  "analysis.powerBI.report.title": "",
  "appNotificationDialog.button.accept": "Înțeles",
  "appNotificationDialog.title.maintenance": "Lucrări de întreținere",
  "appNotificationDialog.title.newFeature": "Caracteristici noi",
  "appNotificationDialog.title.update": "",
  "branchType.allocationGroup": "Zona așezării",
  "branchType.costCenter": "Stăpânul costurilor",
  "branchType.department": "Departamentul",
  "branchType.financialAccountancyCircle": "Cercul FIBU",
  "branchType.other": "Altele",
  "branchType.wageCompany": "Societate de salarizare",
  "branchType.workplace": "Locul de muncă",
  "button.activateMfa": "Activați acum",
  "button.answer": "RĂSPUNS",
  "button.archive": "Arhiva",
  "button.assign": "Atribuiți",
  "button.back_to_login": "Înapoi la autentificare",
  "button.closeTicket": "Marcați ca fiind gata",
  "button.configure": "CONFIGURARE",
  "button.continueInBackground": "Continuați în fundal",
  "button.continue_without_microsoft": "Continuați fără un cont Microsoft",
  "button.copy": "Copiere/Mutare",
  "button.deactivateMfa": "Dezactivați acum",
  "button.enter": "INTRĂ",
  "button.markAsPrivate": "Marcați ca fiind confidențial",
  "button.moreInfo": "MAI MULTE INFORMAȚII",
  "button.move": "Mutați-vă aici",
  "button.nextStep": "pasul următor",
  "button.paste": "Copiați aici",
  "button.refresh": "Reînnoiți",
  "button.release": "Share",
  "button.submitArchiveMultiple": "{count} Mesaje de arhivă",
  "button.submitArchiveSingle": "{count} Mesaj de arhivă",
  "button.toOverview": "La prezentarea generală",
  "button.transfer": "Transmiteți",
  "button.unlock": "Deblocare",
  "button.upload": "Încărcați fișierul",
  "captcha.invalid": "",
  "captcha.placeholderText": "",
  "chatInput.deleteLink": "Ștergeți",
  "chatInput.editLink": "Editați",
  "chatInput.enterLink": "Link",
  "chatInput.openLink": "Deschideți URL-ul",
  "chatInput.saveLink": "Salvați",
  "common.abacus": "Abacus",
  "common.accounting": "Contabilitate",
  "common.accounting.period": "Perioada de rezervare",
  "common.accounting.recordType": "Simbolul documentului",
  "common.accounting.subsidiary": "Codul societății",
  "common.add": "Adăugați",
  "common.albanian": "Shqiptare",
  "common.all": "Toate",
  "common.amount": "Număr",
  "common.anyDateRange": "Orice perioadă",
  "common.apply": "Preia controlul",
  "common.archive": "Arhiva",
  "common.back": "Înapoi",
  "common.bankConnection": "Detalii bancare",
  "common.bankConnections": "Detalii bancare",
  "common.bankContact": "Detalii bancare",
  "common.bic": "BIC",
  "common.bmd": "BMD",
  "common.bulgarian": "Български",
  "common.cancel": "Anulează",
  "common.cancelCaps": "CANCELARE",
  "common.chat": "Chat",
  "common.clientNumber": "Numărul clientului",
  "common.cockpit": "Cabină de pilotaj",
  "common.companies": "Compania",
  "common.company": "Compania",
  "common.companyNumber": "Numărul companiei",
  "common.companyOverview": "Prezentare generală a companiei",
  "common.companyRegisterNumber": "Numărul de înregistrare al societății",
  "common.continue": "Mai departe",
  "common.country": "Țara",
  "common.croatian": "Hrvatski",
  "common.czech": "Česky",
  "common.dateformat": "DD.MM.AAAA",
  "common.dateValidation": "Vă rugăm să introduceți o dată validă",
  "common.defaultSignature": "TPA-ul dumneavoastră",
  "common.delete": "Ștergeți",
  "common.done": "Gata",
  "common.download": "Descărcați",
  "common.downloadAll": "",
  "common.dueDate": "Termen limită",
  "common.dueUntil": " până cel târziu la {dueDate}.",
  "common.emailAddress": "Adresa de e-mail",
  "common.english": "English",
  "common.errorOccurred": "A apărut o eroare",
  "common.events": "Evenimente",
  "common.files": "Fișiere",
  "common.finished": "Completat",
  "common.folder": "Dosar",
  "common.from": "De la",
  "common.fullName": "{firstName} {lastName}",
  "common.generalData": "Date generale",
  "common.generalDocuments": "Dosare generale",
  "common.german": "Deutsch",
  "common.global": "La nivelul întregii companii",
  "common.hr": "Salarizare",
  "common.hr.period": "Perioada contabilă",
  "common.hrPreRegistrations": "Personal - intrări preliminare",
  "common.hr.short": "Personal",
  "common.hr.subsidiary": "Zona așezării",
  "common.hungarian": "Magyar",
  "common.invite": "Invitați",
  "common.kpi": "Fapte și cifre",
  "common.kpi.long": "Date și cifre",
  "common.later": "Mai târziu",
  "common.logout": "Logout",
  "common.me": "I",
  "common.members": "Membri",
  "common.moreInformation": "Aflați mai multe",
  "common.mr": "Domnul {nume}",
  "common.mrs": "Doamna {nume}",
  "common.name": "Nume",
  "common.no": "Nu",
  "common.none": "niciunul",
  "common.noSearchResults": "Nu s-au găsit rezultate",
  "common.notAvailable": "N/A",
  "common.notSpecified": "n.a.",
  "common.numberValidation": "Vă rugăm să introduceți un număr valid",
  "common.ok": "Ok",
  "common.optional": "(opțional)",
  "common.orderBy": "Sortare după",
  "common.partial": "parțial",
  "common.people": "Oameni",
  "common.polish": "Polski",
  "common.profile": "Profil",
  "common.project": "Proiect",
  "common.projects": "Proiecte",
  "common.readingTimeMinutes": "Timp de citire: {timp} Min",
  "common.readingTimeMinutes.short": "{timp} Min",
  "common.recipient": "Receptor",
  "common.refresh": "Reîmprospătați",
  "common.releasedAt": "lansat pe {data}",
  "common.releasedAtBy": "lansată la {data} de {numele}",
  "common.releaseRequired.plural": "{count} Eliberări necesare",
  "common.releaseRequired.singular": "Este necesară o eliberare",
  "common.remainingItems": "+{count} mai mult",
  "common.remainingItemsUnknown": "Încărcați mai mult",
  "common.retry": "Încearcă din nou",
  "common.romanian": "Românesc",
  "common.russian": "Русский",
  "common.save": "Salvați",
  "common.sendCaps": "TRIMITEȚI",
  "common.serbian": "Српски",
  "common.settings": "Setări",
  "common.showAll": "Arată tot",
  "common.slovak": "Slovenská",
  "common.slovene": "Slovenski",
  "common.taskTitle": "Titlu",
  "common.taxNumber": "Numărul de identificare fiscală",
  "common.termsOfUse": "Termeni de utilizare",
  "common.ticket": "Consultare",
  "common.timePeriod": "Perioada",
  "common.timePeriod.olderThan1Month": "Mai vechi de o lună",
  "common.timePeriod.olderThan1Week": "Mai vechi de o săptămână",
  "common.timePeriod.olderThan1Year": "Mai vechi de un an",
  "common.timePeriod.olderThan6Months": "Mai vechi de 6 luni",
  "common.to": "Până la",
  "common.tpaFirstName": "TPA",
  "common.tpaLastName": "Angajați",
  "common.unknown": "necunoscut",
  "common.upcomingTermsOfUse": "Viitorii termeni de utilizare",
  "common.upload": "Încărcare",
  "common.uploadFiles": "Încărcați fișiere",
  "common.user": "Utilizatori",
  "common.vatNumber": "UID",
  "common.yes": "Da",
  "commuterAllowance.IndividuellePendlerpauschale": "Indemnizație individuală de navetă",
  "commuterAllowance.KeinePendlerpauschale": "Fără indemnizație de navetă",
  "commuterAllowance.MehrAls20kmKeinBus": "Mai mult de 20 km, transportul public nu este rezonabil",
  "commuterAllowance.MehrAls40kmBus": "Mai mult de 40 km, transport public rezonabil",
  "commuterAllowance.MehrAls40kmKeinBus": "Mai mult de 40 km, transportul public nu este rezonabil",
  "commuterAllowance.MehrAls60kmBus": "Mai mult de 60 km, transport public rezonabil",
  "commuterAllowance.MehrAls60kmKeinBus": "Mai mult de 60 km, transportul public nu este rezonabil",
  "commuterAllowance.Mindestens20kmBus": "Mai mult de 20 km, transport public rezonabil",
  "commuterAllowance.Mindestens2kmKeinBus": "Mai mult de 2 km, transportul public nu este rezonabil",
  "companiesUserReleaseInfoActionsBar.message": "Aveți {count} utilizatori noi de împărtășit",
  "companiesUserReleaseInfoActionsBar.submit": "Vizualizați și partajați",
  "companiesUserRelease.permissions.navbar.back": "Partajați și invitați utilizatori / Atribuiți roluri",
  "companiesUserRelease.title": "Partajați și invitați utilizatori",
  "companyImages.cover.error": "Imaginea copertei nu a putut fi încărcată",
  "companyImages.cover.title": "Imagine de copertă",
  "companyImages.delete.message": "Chiar vrei să ștergi fotografia?",
  "companyImages.delete.title": "Ștergeți imaginea",
  "companyImages.logo.error": "Logo-ul companiei nu a putut fi încărcat",
  "companyImages.logo.title": "Logo-ul companiei",
  "companyImages.title": "Imaginea de copertă și logo-ul companiei dumneavoastră (opțional)",
  "companyList.remainingCompanies": "+{încă o companie} Mai mult",
  "company.optionalSideBarMenuItems.maxLength.name": "Numele poate conține doar 15 caractere",
  "company.optionalSideBarMenuItems.nameAlreadyinUse": "Numele elementului de meniu opțional există deja",
  "company.optionalSideBarMenuItems.name.label": "Nume",
  "company.optionalSideBarMenuItems.required.name": "Numele trebuie să fie specificat",
  "company.optionalSideBarMenuItems.required.url": "URL-ul trebuie să fie specificat",
  "company.optionalSideBarMenuItems.title": "Elementele opționale din meniul societății",
  "company.optionalSideBarMenuItems.tooltip.removeItem": "Eliminați elementul de meniu",
  "company.optionalSideBarMenuItems.urlAlreadyinUse": "Legătura pentru elementul de meniu opțional există deja",
  "company.optionalSideBarMenuItems.url.label": "URL",
  "company.optionalSideBarMenuItems.urlNotValid": "Vă rugăm să introduceți un URL valid (exemplu: https://www.tpa-connect.app )",
  "companyOverview.emptyState.message": "Vă rugăm să vă contactați consilierul dvs.",
  "companyOverview.emptyState.title": "Nici o societate activă",
  "companyOverview.emptyState.tpa.message": "În prezent nu aveți niciun client activ",
  "companyOverview.emptyState.tpa.title": "Nu există clienți activi",
  "companyOverview.unlockCountry.tooltip": "Deblocați noi țări{br}{br} Deblocați proiectele internaționale din <b>{țară}</b> pentru a utiliza funcția de proiecte a acestei companii și pentru a vizualiza toate interogările.",
  "companyStatus.accountingSetupDone": "0/2 făcut",
  "companyStatus.active": "activ",
  "companyStatus.hrSetupDone": "1/2 făcut",
  "companyStatus.inactive": "inactiv",
  "companyStatus.userSetupDone": "2/2 făcut",
  "config.accounting.accountingByCustomer": "Transmiterea de către clienți a datelor contabile prin TPA Connect",
  "config.accounting.delete.subsidiaries.last.message": "Societatea {name} nu poate fi ștearsă. Trebuie să existe cel puțin o societate.",
  "config.accounting.delete.subsidiaries.message": "Doriți cu adevărat să ștergeți societatea {name}?",
  "config.accounting.delete.subsidiaries.title": "Ștergeți codul societății",
  "config.accounting.description": "Determinați tipul și domeniul de aplicare al domeniului contabil.",
  "config.accounting.description.customer": "Consilierul dumneavoastră TPA a configurat această zonă pentru dumneavoastră. Dacă doriți să faceți modificări, vă rugăm să contactați consilierul dumneavoastră TPA în orice moment.",
  "config.accounting.due.accounting.reports.select.title": "Zile de la transmiterea contabilității",
  "config.accounting.dueDates.title": "Transmiterea documentelor contabile",
  "config.accounting.due.reportRelease.select.subtitle": "De exemplu, la 4 zile de la transmiterea rapoartelor de către TPA. Aceasta înseamnă pe data de 12 a lunii următoare (în cazul în care transmiterea rapoartelor de către TPA a avut loc pe data de 8 a lunii următoare).",
  "config.accounting.due.reportRelease.select.title": "Zile după transmiterea rapoartelor",
  "config.accounting.due.reportRelease.select.value": "{zi} Zilele de la transmiterea rapoartelor",
  "config.accounting.due.reportRelease.title": "Eliberarea rapoartelor de către clienți",
  "config.accounting.due.reports.select.subtitle": "De exemplu, la 3 zile de la transmiterea contabilității. Acest lucru înseamnă pe data de 8 a lunii următoare (în cazul în care transmiterea contabilității a avut loc pe data de 5 a lunii următoare).",
  "config.accounting.due.reports.select.value": "{day} Zile după transmiterea conturilor",
  "config.accounting.due.reports.title": "Transmiterea rapoartelor de către APT",
  "config.accounting.due.transfer.select.subtitle": "De exemplu, pe data de 5 a lunii următoare.",
  "config.accounting.due.transfer.select.title": "Transmisie până la",
  "config.accounting.due.transfer.select.value": "{day} Zile după sfârșitul perioadei",
  "config.accounting.due.transfer.title": "Transmiterea contabilității de către clienți",
  "config.accounting.globalReports.description": "Clientul are rapoarte la nivelul întregii companii",
  "config.accounting.globalReports.title": "Rapoarte la nivelul întregii companii",
  "config.accounting.hasAccounting": "Clientul are contabilitate",
  "config.accounting.hasBankAccountTransactionInvoices": "Alocarea documentelor la tranzacțiile bancare",
  "config.accounting.hasCockpitKpi": "Fapte și cifre în cabina de pilotaj",
  "config.accounting.hasKpi": "Fapte și cifre",
  "config.accounting.hasResults": "Evaluări",
  "config.accounting.incaseoflaw.description": "Clientul are funcția \"În caz de drept",
  "config.accounting.incaseoflaw.label": "În cazul legii",
  "config.accounting.incaseoflaw.title": "În cazul legii",
  "config.accounting.month.description": "Include o durată de o lună",
  "config.accounting.month.title": "Contabilitate lunară",
  "config.accounting.quarter.description": "Include o durată de 3 luni",
  "config.accounting.quarter.title": "Contabilitate pe trimestru",
  "config.accounting.recordTypes": "Încasări contabile",
  "config.accounting.recordTypes.ocrDisabled": "Recunoașterea automată a documentelor (OCR) dezactivată",
  "config.accounting.recordTypes.ocrEnabled": "Recunoașterea automată a documentelor (OCR) activată",
  "config.accounting.requireTwoReportReleases": "Este necesară aprobarea rapoartelor de către doi utilizatori (principiul controlului dublu)",
  "config.accounting.subsidiaries.add": "Adăugați codul companiei",
  "config.accounting.subsidiaries.title": "Definirea codurilor de societate",
  "config.accounting.subsidiaries.title.customer": "Societăți comerciale",
  "config.accounting.subsidiary.maxReached": "Numărul maxim de societăți atins",
  "config.accounting.subsidiary.name": "Numele de cod al companiei",
  "config.accounting.week.description": "Cuprinde o durată de 5 zile lucrătoare și este împărțită în săptămâni calendaristice.",
  "config.accounting.week.title": "Contabilitate săptămânală",
  "config.bulk.button.configure": "Configurați",
  "config.bulk.data.description": "Aici puteți încărca o imagine de copertă și logo-ul companiilor.",
  "config.bulk.data.title": "Date de bază",
  "config.bulk.finish.description": "Companiile sunt în curs de configurare. Acest lucru poate dura câteva minute, vă rugăm să nu închideți fereastra browserului.",
  "config.bulk.finish.start": "Lansare",
  "config.bulk.finish.title": "Finalizarea configurației",
  "config.companies": "Configurația companiei",
  "config.companies.buttonConfig": "Configurați compania",
  "config.companies.count": "{count} Compania",
  "config.companies.description": "În această etapă, se stabilesc procesele specifice companiei. Acestea includ aspectul vizual în TPA Connect, tipul și domeniul de aplicare al contabilității și al salarizării, precum și termenele limită.",
  "config.companyData.cockpit": "Cockpit",
  "config.companyData.description": "Aici puteți încărca o imagine de copertă și logo-ul companiei și puteți seta criteriile de rezervare.",
  "config.companyData.title": "Date de bază",
  "config.companyOverview.inactive": "Prezentare generală a companiei / Inactiv",
  "config.customMenuPoints.deleteCustomMenuPoint.confirmLabel": "Da, ștergeți",
  "config.customMenuPoints.deleteCustomMenuPoint.message": "Chiar doriți să ștergeți acest element de meniu?",
  "config.customMenuPoints.deleteCustomMenuPoint.title": "Element de meniu Ștergere",
  "config.customMenuPoints.noMenuPointsAvailable": "Nu sunt disponibile elemente de meniu",
  "config.employees.assignRole": "Atribuirea rolului",
  "config.employees.emptyState.message": "În prezent nu există angajați activi",
  "config.employees.emptyState.title": "Nu există angajați activi",
  "config.employees.table.missingEmail": "Adresa de e-mail lipsă",
  "config.employees.table.missingEmail.tooltip": "Adresa de e-mail trebuie să fie introdusă în BMD de către un consultant TPA",
  "config.features.alreadyToggledToday.disabled": "Add-on-ul a fost deja dezactivat astăzi",
  "config.features.alreadyToggledToday.enabled": "Add-on-ul a fost deja activat astăzi",
  "config.features.confirm.message.disabled": "Dacă activați add-on-ul, add-on-ul taxabil va fi disponibil imediat și veți fi facturat începând de astăzi. Puteți dezactiva add-on-ul din nou mâine.",
  "config.features.confirm.message.enabled": "Suplimentul taxabil va fi dezactivat la sfârșitul zilei. Veți fi taxat în continuare pentru ziua de astăzi. Puteți reactiva add-on-ul începând de mâine.",
  "config.features.confirm.title.disabled": "Activați add-on-ul",
  "config.features.confirm.title.enabled": "Dezactivați add-on-ul",
  "config.finishConfiguration": "Finalizarea configurației",
  "config.finishConfiguration.cockpit": "În cabina de pilotaj",
  "config.finishConfiguration.continue": "Creați o altă companie",
  "config.finishConfiguration.emailNotification": "Notificarea utilizatorilor prin e-mail",
  "config.finishConfiguration.message": "{compania} a fost configurată cu succes. Acesta poate fi utilizat acum pentru schimbul dintre APT și client.",
  "config.finishSetup": "EXIT SETUP",
  "config.hr.chat.description": "Angajații salariați pot contacta consilierii TPA.",
  "config.hr.chat.title": "Chat",
  "config.hr.delete.subsidiaries.last.message": "Zona de contabilitate {name} nu poate fi ștearsă. Trebuie să existe cel puțin un domeniu contabil.",
  "config.hr.delete.subsidiaries.message": "Chiar doriți să ștergeți zona de contabilitate {numele}?",
  "config.hr.delete.subsidiaries.title": "Ștergeți zona de salarizare",
  "config.hr.description": "Determinați natura și domeniul de aplicare al zonei de salarizare.",
  "config.hr.dueDates.reminder": "Reamintire a termenelor limită",
  "config.hr.dueDates.title": "Termene limită pentru contabilizarea salariilor",
  "config.hr.due.relativeDueDates": "Livrarea documentelor în luna următoare",
  "config.hr.due.reports.select.subtitle": "De exemplu, la 3 zile după transmiterea salariului. Acest lucru înseamnă pe data de 8 a lunii următoare (în cazul în care transmiterea decontului de salarii a avut loc pe data de 5 a lunii următoare).",
  "config.hr.due.reports.select.title": "Zilele de la transmiterea salariului",
  "config.hr.due.reports.select.value": "{zi} Zilele de la data transmiterii declarației de personal",
  "config.hr.due.select.value": "{zi}. a lunii",
  "config.hr.due.transfer.select.subtitle": "De exemplu, pe data de 5 a lunii.",
  "config.hr.due.transfer.select.value.relativeDueDates": "{day} Zile după sfârșitul lunii",
  "config.hr.due.transfer.title": "Transmiterea salariilor de către clienți",
  "config.hr.hasHr": "Clientul are salarizare",
  "config.hr.hasResults": "Analize",
  "config.hr.recordTypes": "Documente de salarizare",
  "config.hr.releaseSteps.oneStep.description": "Toate evaluările lunare sunt transmise clientului de către TPA într-o singură etapă. Clientul trebuie să elibereze toate documentele necesare până la un termen stabilit.",
  "config.hr.releaseSteps.oneStep.title": "într-o singură etapă",
  "config.hr.releaseSteps.title": "Etapele eliberărilor",
  "config.hr.releaseSteps.twoSteps.description": "Transmiterea și eliberarea evaluării lunare se face în două etape. Clientul poate elibera deconturile înainte de a elibera toate celelalte evaluări lunare.",
  "config.hr.releaseSteps.twoSteps.title": "în două etape",
  "config.hr.requireTwoReportReleases": "Este necesară aprobarea rapoartelor de către doi utilizatori (principiul controlului dublu)",
  "config.hr.sendPayroll.description": "Transmiteți declarațiile de venit în format electronic",
  "config.hr.sendPayroll.title": "Registrul electronic al veniturilor",
  "config.hr.sendPayroll.tooltip": "Atunci când se emite o fișă de salariu, fiecare angajat primește automat acces la aplicația TPA Connect, care poate fi utilizată pentru a vizualiza propriile înregistrări de venituri, precum și dosarul electronic al personalului.",
  "config.hr.subsidiaries.add": "Adăugați zona de salarizare",
  "config.hr.subsidiaries.info": "După selectarea primului domeniu de contabilitate, pot fi selectate numai altele de același tip (de exemplu, master de costuri). Pentru a selecta un nou tip (de exemplu, cercul FIBU), trebuie să se șteargă toate suprafețele contabile, cu excepția uneia.",
  "config.hr.subsidiaries.title": "Determinați zonele de salarizare",
  "config.hr.subsidiaries.title.customer": "Circuite contabile",
  "config.hr.subsidiary.maxReached": "Numărul maxim de circuite de contabilizare atins",
  "config.hr.subsidiary.name": "Denumirea domeniului contabil",
  "config.hr.upload.description": "Salariații pot încărca documente în dosarul personal",
  "config.hr.upload.title": "Încărcați documente în dosarul de personal",
  "config.hr.warning.noBranch": "Salarizarea nu poate fi activată. Vă rugăm să creați o companie de salarizare în NTCS",
  "config.moduleTypeDetails.asp.helpInstructions": "Instrucțiuni pentru clienții ASP",
  "config.moduleTypeDetails.asp.helpInstructions.open": "La instrucțiuni",
  "config.moduleTypeDetails.label.database": "Baza de date",
  "config.moduleTypeDetails.label.dataSource": "Sursa datelor",
  "config.moduleTypeDetails.label.url": "URL",
  "config.noDueDate": "Fără termen limită",
  "config.optionalMenuPoints.success.title": "Elementele de meniu salvate cu succes",
  "config.overview": "Prezentare generală a configurației",
  "config.permission.change": "schimbat în \"{permisie}\"",
  "config.permissions.accounting.globalReports": "Rapoarte contabile la nivelul întregii companii",
  "config.permissions.deleted": "(șters)",
  "config.permissions.description": "Selectați zonele la care utilizatorul are acces. Puteți face setări diferite pentru fiecare societate.",
  "config.permissions.employees": "Angajați",
  "config.permissions.existingUser.navbar.back": "Prezentare generală a configurației / Administrarea utilizatorilor / Atribuirea rolului",
  "config.permissions.existingUser.navbar.backToUser": "Prezentare generală a configurației / Administrare utilizatori / Atribuire rol / {employeeName}",
  "config.permissions.global": "La nivelul întregii companii",
  "config.permissions.internationalProjects.info": "Proiecte Permisiunile nu pot fi retrase: Membrii proiectelor internaționale le primesc automat.",
  "config.permissions.navbar.back": "Prezentare generală a configurației / Administrarea utilizatorilor / Selectarea persoanei de contact / Atribuirea rolului",
  "config.permissions.navbar.backToUser": "Prezentare generală a configurației / Administrarea utilizatorilor / Selectați persoana de contact / Atribuiți un rol / {employeeName}",
  "config.permissions.noPermissionSelected.message": "Selectați cel puțin o autorizație.",
  "config.permissions.noPermissionSelected.title": "Nicio autorizație selectată",
  "config.permissions.title": "Adaptați permisiunile",
  "config.roles.change": "Rol schimbat de la {oldRole} la {newRole}",
  "config.roles.customizePermissions": "Adaptați permisiunile",
  "config.roles.description": "Selectați un rol cu un set predefinit de permisiuni. Dacă este necesar, puteți ajusta permisiunile într-o etapă ulterioară.",
  "config.roles.multipleCompaniesDialog.message": "Utilizatorul {name} există în următoarele companii. Doriți să modificați autorizațiile în toate societățile?",
  "config.roles.multipleCompaniesDialog.title": "Aplicați modificări în mai multe companii",
  "config.roles.multipleCompaniesDialog.warning": "Companiile nu au putut fi încărcate. Modificările se aplică numai la societatea curentă.",
  "config.roles.navbar.back": "Prezentare generală a configurației / Administrarea utilizatorilor / Selectarea persoanelor de contact",
  "config.roles.navbar.heading": "{employeeCount} Utilizator selectat",
  "config.roles.option.copy.description": "Introduceți numele utilizatorului pentru a prelua rolul și permisiunile acestuia. Dacă este necesar, puteți ajusta permisiunile în etapa următoare.",
  "config.roles.option.copy.label": "Preluarea rolului de la un alt utilizator",
  "config.roles.option.copy.placeholder": "Numele utilizatorului",
  "config.roles.releaseInformation": "Pentru a edita setările de utilizator ale <b>{NumeUtilizator}</b>, un <b>superadmin</b> trebuie să aprobe/respingă modificările.",
  "config.roles.releaseInformation.info.message": "Dacă cineva (în afară de superadmin) face modificări la setările utilizatorului, aceste modificări trebuie să fie aprobate sau respinse de un superadmin. Până atunci, setările utilizatorului sunt blocate și nu pot fi modificate.",
  "config.roles.releaseInformation.info.title": "De ce nu pot fi editate setările utilizatorului?",
  "config.roles.releaseInformation.superAdmin": "{name} a făcut modificări la <b>{userName}</b>. Vă rugăm să aprobați modificările aduse de utilizator pentru a continua.",
  "config.roles.sidebar.title": "Utilizatori aleși",
  "config.roles.submit": "Sugerați un utilizator",
  "config.roles.submit.existingUser": "Propuneți modificări",
  "config.roles.submit.superAdmin": "Invitați utilizatorul",
  "config.roles.title": "Atribuirea rolului",
  "config.roles.viewPermissions": "Vezi permisiuni",
  "config.terms.allSigneesAcceptedWarningDialog.confirmLabel": "OK, salvați",
  "config.terms.allSigneesAcceptedWarningDialog.message": "Atenție: Deoarece toate persoanele adăugate au semnat deja, compania va fi activată după salvare.",
  "config.terms.allSigneesAcceptedWarningDialog.title": "Salvați termenii de utilizare",
  "config.terms.alreadySigned": "Condițiile de utilizare ale companiei lor au fost deja semnate.",
  "config.terms.button.revokeActivation": "Revocarea activării",
  "config.terms.changeDocumentVersionDialog.confirmLabel": "Da, modificați documentul",
  "config.terms.changeDocumentVersionDialog.message": "Dacă continuați, toate adresele de e-mail deja adăugate trebuie să accepte din nou condițiile de utilizare.",
  "config.terms.changeDocumentVersionDialog.title": "Document de modificare",
  "config.terms.deleteEmailDialog.confirmLabel": "Da, ștergeți adresa de e-mail",
  "config.terms.deleteEmailDialog.message": "Chiar doriți să ștergeți adresa de e-mail?",
  "config.terms.deleteEmailDialog.title": "Ștergeți adresa de e-mail",
  "config.terms.documentVersion": "Versiunea documentului",
  "config.terms.emailAddressesInfo": "Adăugați adresele de e-mail ale persoanelor dorite cărora li se va cere prin e-mail să semneze condițiile de utilizare respective. Adăugați numai adresele de e-mail ale semnatarilor autorizați! Condițiile de utilizare sunt trimise la adresele de e-mail de mai jos numai după finalizarea configurării companiei și a utilizatorului.",
  "config.terms.emailAddressesTitle": "Adrese de e-mail",
  "config.terms.emailAlreadyUsed": "Această adresă de e-mail a fost deja adăugată",
  "config.terms.invalidEmail": "Adresa de e-mail invalidă",
  "config.terms.noDocumentsAvailable": "În prezent nu există documente disponibile",
  "config.terms.noSigneesAvailable": "Nu există încă semnatari",
  "config.termsOfUse.navbar.back": "Prezentare generală a configurației / date de bază",
  "config.terms.removeEmail": "Ștergeți adresa de e-mail",
  "config.terms.resendEmail.header": "E-mail trimis",
  "config.terms.resendEmail.message": "E-mailul de acceptare a condițiilor de utilizare a fost trimis din nou",
  "config.terms.resendMail": "Reîntoarceți e-mailul",
  "config.terms.revokeActivationDialog.confirmLabel": "da, activare revocată",
  "config.terms.revokeActivationDialog.message": "Sunteți sigur că doriți să revocați activarea companiei? Toate persoanele înregistrate trebuie să semneze din nou termenii de utilizare pentru a reactiva societatea.",
  "config.terms.revokeActivationDialog.title": "Revocarea activării",
  "config.terms.signedVersion": "versiunea semnată:",
  "config.terms.skip.button": "skip",
  "config.terms.skip.dialog.message": "Utilizatorii din partea clientului se pot autentifica numai după ce au fost acceptați termenii de utilizare. Până atunci, compania este disponibilă doar în scopuri interne TPA în aplicația Connect.",
  "config.terms.skip.dialog.title": "Informații",
  "config.terms.success.header": "Salvat cu succes",
  "config.terms.termsOfUseInfo": "Selectați versiunea corespunzătoare a documentului",
  "config.terms.termsOfUseTitle": "Termeni de utilizare",
  "config.users": "Administrarea utilizatorilor",
  "config.users.active.title": "Utilizatori activi",
  "config.users.assignRole": "Atribuirea rolului",
  "config.users.buttonConfig": "Gestionați utilizatorii",
  "config.users.changeUsername": "E-mail nou",
  "config.users.changeUsername.tooltip": "Noua adresă de e-mail a utilizatorului este {newUsername}. Prin apăsarea butonului, utilizatorului i se cere să confirme noua adresă de e-mail.",
  "config.users.description": "Atribuiți utilizatori sau grupuri de utilizatori unor procese individuale. Dumneavoastră stabiliți cine are acces la ce zone și în ce măsură.",
  "config.users.edit": "Schimbare",
  "config.users.emptyState.message": "În prezent nu există utilizatori activi{br}Pentru a crea un superadmin, vă rugăm să utilizați BMD NTCS.",
  "config.users.emptyState.title": "Nu există utilizatori activi",
  "config.users.inviteAgain": "Invitați din nou",
  "config.users.navBar.back": "Prezentare generală a configurației / administrare utilizatori",
  "config.users.new": "Nou",
  "config.users.newUsers": "Creați un nou utilizator",
  "config.users.newUsers.description": "Selectați persoanele de contact pe care doriți să le creați ca utilizatori TPA Connect. În etapa următoare, puteți atribui un rol persoanelor selectate și puteți ajusta permisiunile predefinite, dacă este necesar.",
  "config.users.newUsers.title": "Selectați persoana de contact",
  "config.users.reject": "Declin",
  "config.users.release": "Share",
  "config.users.releaseAndInvite": "Împărtășiți și invitați",
  "config.users.table.invalidEmail": "Adresa de e-mail invalidă",
  "config.users.table.invalidEmail.tooltip": "Adresa de e-mail trebuie să fie corectată de către un consultant TPA din BMD",
  "config.users.waiting.title": "Așteptând eliberarea",
  "cookies.acceptAll": "Permiteți toate cookie-urile",
  "cookies.acceptFunctional": "Utilizați numai cookie-urile necesare",
  "cookies.cookieList": "Declarație privind cookie-urile",
  "cookies.text": "Utilizăm module cookie pentru a ne optimiza serviciile. Puteți găsi mai multe detalii despre acest lucru în {link}.",
  "country.al": "Albania",
  "country.at": "Austria",
  "country.bg": "Bulgaria",
  "country.cz": "Republica Cehă",
  "country.hr": "Croația",
  "country.hu": "Ungaria",
  "country.me": "Muntenegru",
  "country.pl": "Polonia",
  "country.ro": "România",
  "country.rs": "Serbia",
  "country.si": "Slovenia",
  "country.sk": "Slovacia",
  "deleteDialog.message.plural": "Doriți cu adevărat să ștergeți documentele <b>{documente}</b>?{br}{br}Acestă acțiune nu poate fi anulată.",
  "deleteDialog.message.singular": "Doriți cu adevărat să ștergeți documentul <b>{documente}</b>?{br}{br}Acestă acțiune nu poate fi anulată.",
  "deleteDialog.title": "Ștergeți documentele?",
  "deleteUser.message": "Sunteți sigur că doriți să ștergeți utilizatorul <b>{name}</b>?",
  "deleteUser.multipleCompanies.confirmLabel": "Ștergeți utilizatorul din toate companiile",
  "deleteUser.multipleCompanies.message": "Utilizatorul este prezent la următoarele companii:",
  "deleteUser.title": "Ștergeți utilizatorul",
  "deleteUser.warning.superadminskipped": "Atenție! Companiile în care utilizatorii au rolul Superadmin sunt ignorate la ștergere.",
  "dialog.archive.checkbox_pdf": "Salvați PDF la nivel local",
  "dialog.archive.subject": "Subiect",
  "dialog.archive.success.text": "Acum puteți accesa mesajele arhivate în {bmd}.",
  "dialog.archive.success.title": "Mesajele au fost arhivate cu succes!",
  "dialog.archive.text": "Selectați o companie pentru care doriți să stocați mesajele selectate în BMD.",
  "dialog.archive.title_multiple": "{count} Mesaje de arhivă",
  "dialog.archive.title_single": "1 Mesaj de arhivă",
  "dialog.assignTicket.header": "Atribuiți o cerere de informații",
  "dialog.assignTicket.message": "Selectați o persoană căreia doriți să îi atribuiți consultarea.",
  "dialog.button.understood": "Am înțeles",
  "dialog.closeTicket.success.header": "Consultare efectuată",
  "dialog.companyConfig.success.header": "Configurația companiei a fost salvată cu succes.",
  "dialog.companyConfig.success.message": "Zonele pentru {name} sunt acum pregătite.",
  "dialog.eldaTransfer.andNext": " și în continuare",
  "dialog.eldaTransfer.downloadLater": "Descărcați mai târziu",
  "dialog.eldaTransfer.success.header": "Cerere depusă cu succes",
  "dialog.loginError.multiFactorCodeWrong.title": "Codul introdus este din păcate incorect",
  "dialog.loginError.subtitle": "Vă rugăm să încercați din nou.",
  "dialog.loginError.title": "Adresă de e-mail necunoscută sau parolă greșită",
  "dialog.noPhoneNumberWarning.message": "Cel puțin una dintre persoanele de contact selectate nu are un număr de telefon setat. Fără un număr de telefon, autentificarea cu doi factori nu este disponibilă, iar o invitație poate să nu fie sigură.",
  "dialog.noPhoneNumberWarning.title": "Nu este setat niciun număr de telefon",
  "dialog.passwordExpired.message": "Parola dvs. a expirat și trebuie să fie schimbată. Veți primi în scurt timp un e-mail pentru a vă schimba parola. Dacă aveți întrebări, vă rugăm să contactați TPA la {telephoneNumber}. Vă mulțumim foarte mult!",
  "dialog.passwordExpired.title": "Parolă expirată",
  "dialog.password_reset.success.header": "Parola a fost schimbată cu succes",
  "dialog.registration.success.button": "Conectează-te acum",
  "dialog.registration.success.header": "V-ați înregistrat cu succes!",
  "dialog.registration.success.message": "Conectați-vă cu Microsoft pentru a utiliza TPA Connect.",
  "dialog.transferReports.success.message": "Clientul dvs. va vizualiza acum fișierele și vă va contacta pentru orice întrebări.",
  "dialog.transfer.success.header": "Fișiere transmise cu succes",
  "dialog.userConfig.success.header": "Setări de utilizator salvate",
  "dialog.user_not_found.button": "Am înțeles",
  "dialog.user_not_found.subtitle": "Contactați administratorul de sistem sau conectați-vă cu contul dumneavoastră TPA Connect.",
  "dialog.user_not_found.title": "Nu se cunoaște niciun cont de utilizator cu această adresă de e-mail",
  "documentUpload.error.amount": "<b>{failedUploads}</b> de fișiere {uploads} nu au putut fi încărcate:",
  "documentUpload.error.button.text.continue": "Continuați fără aceste fișiere",
  "documentUpload.error.button.text.retry": "Încearcă din nou",
  "documentUpload.error.message": "Eroare de încărcare a fișierelor",
  "documentUpload.error.rejection.amount": "<b>{failedUploads}</b> de fișiere {uploads} nu pot fi încărcate:",
  "documentUpload.error.tooBigImages.amount": "<b>{failedUploads}</b> Imaginile sunt mai mari decât maximul de pixeli {maxImageDimension}. Acestea vor fi reduse automat în dimensiune. Continuă?",
  "documentUpload.error.tooBigImages.amount.singular": "Imaginea este mai mare decât max. {maxImageDimension} pixeli. Dimensiunea acesteia este redusă automat. Continuă?",
  "documentUpload.error.tooBigImages.button.text.continue": "Reducere și continuare",
  "documentUpload.error.tooBigImages.pdf": "Imaginile din PDF sunt mai mari decât maxim {maxImageDimension} pixeli. Vă rugăm să reduceți dimensiunea imaginilor.",
  "documentUpload.error.tooManyFiles.plural": "Pot fi încărcate maximum <b>{count}</b> fișiere:",
  "documentUpload.error.tooManyFiles.singular": "Poate fi încărcat cel mult <b>un</b> fișier:",
  "documentUpload.max.size": "Dimensiunea maximă a documentului {maxUploadSizeMb} Mb",
  "documentUpload.tooBigImages.message": "Imagini prea mari",
  "documentUpload.type.file.formats": "Formate acceptate",
  "dropzone.button.label.search": "căutați-o.",
  "dropzone.image.text": "Pur și simplu trageți imaginea în această zonă sau",
  "dropzone.info.redo": "Încărcați din nou",
  "dropzone.info.text": "Pur și simplu trageți și fixați fișierele în această zonă sau",
  "dueDate.answerAt": "să se răspundă până la {data}",
  "dueDate.closedAt": "finalizată la {data}",
  "dueDate.sentAt": "trimisă la {data}",
  "dueDate.sentAtWithTime": "trimis la {data} la {ora}",
  "dueDate.transferredAt": "transmise la {data}",
  "dueDate.until": "până la {data}",
  "employmentInsuranceType.apprentice": "Ucenic",
  "employmentInsuranceType.fullTime": "complet asigurat",
  "employmentInsuranceType.independentContractor": "DN gratuit",
  "employmentInsuranceType.marginalIndependentContractor": "Minor liber DN",
  "employmentInsuranceType.marginally": "marginal",
  "employmentRelationship.employee": "Angajat",
  "employmentRelationship.worker": "Lucrători",
  "error.accounting.postSubsidiary": "Nu s-a putut crea codul societății",
  "error.accounting.subsidiaryDoesNotExist": "Codul societății nu a fost găsit",
  "error.activate": "Eroare de activare",
  "error.addBankAccountTransactionInvoices": "Tranzacția și documentele nu au putut fi legate",
  "error.addBankConnection": "Nu au putut fi adăugate detalii bancare",
  "error.addCompanyToUserFavorites": "Compania nu a putut fi adăugată la favoritele tale",
  "error.addFolderBadRequest": "Nu s-a putut crea un dosar. Asigurați-vă că nu ați folosit caractere speciale neautorizate: \" * : < > ? / \\ |",
  "error.archiveDisabledForCompany": "Arhivarea nu este posibilă cu această companie",
  "error.archiveFailed": "Eroare în timpul arhivării",
  "error.archiveProjectFailed": "Proiectul nu a putut fi arhivat",
  "error.avatarUploadFailed": "Imaginea de profil nu a putut fi încărcată. Asigurați-vă că formatul este JPEG sau PNG și că dimensiunea fișierului este de maxim {maxSize}MB",
  "error.avatarUploadOnlyOneImage": "Puteți încărca doar o singură imagine",
  "error.changeUsernameEmail": "Nu s-a putut trimite e-mailul pentru schimbarea numelui de utilizator",
  "error.changeUsername.newUsernameConflict": "Noul e-mail nu corespunde e-mailului stocat.",
  "error.changeUsername.newUsernameDuplicate": "E-mail deja utilizat.",
  "error.changeUsername.noNewUsername": "Nu a fost atribuită o nouă adresă de e-mail.",
  "error.changeUsername.tokenExpired": "Acest link a expirat.",
  "error.changeUsername.tokenNotFound": "Link invalid, noua adresă de e-mail a fost deja confirmată?",
  "error.chatForbidden": "Nu este activată pentru dvs. o întâlnire față în față cu această companie.",
  "error.chatNoCompany": "Mesaj fără contextul companiei.",
  "error.chatNoRecipient": "Mesajul nu are destinatar.",
  "error.chatSearch": "Căutarea a eșuat. Vă rugăm să încercați din nou.",
  "error.completeSign": "Semnătura electronică nu a putut fi completată",
  "error.config.accounting.duplicate": "Ați introdus mai multe societăți cu același nume",
  "error.config.accounting.noSubsidiary": "Vă rugăm să definiți cel puțin o societate pentru a continua.",
  "error.config.activateCompanyFailed": "Acest client nu a fost încă pregătit din punct de vedere tehnic pentru utilizarea TPA Connect. Vă rugăm să contactați serviciul de asistență IT pentru a crea un utilizator corespunzător.",
  "error.config.corruptSubsidiary": "Codul societății fără ID",
  "error.config.hr.duplicate": "Ați specificat mai multe zone de salarizare cu același nume",
  "error.config.hr.noSubsidiary": "Vă rugăm să setați cel puțin un domeniu contabil pentru a continua.",
  "error.copy.alreadyExists.file": "Eroare la copiere: Un fișier cu acest nume există deja",
  "error.copy.alreadyExists.folder": "Eroare la copiere: Un folder cu acest nume există deja",
  "error.copy.alreadyExists.unknown": "Eroare la copiere: Un folder sau fișier cu acest nume există deja",
  "error.create": "Eroare în timpul creării",
  "error.create.alreadyExists.project": "Există deja un proiect cu acest nume",
  "error.createEmployee": "Angajatul nu a putut fi creat",
  "error.delete": "Eroare în timpul ștergerii",
  "error.deleteBankAccountTransactionInvoices": "Legătura nu a putut fi ștearsă",
  "error.deleteBankConnection": "Datele bancare nu au putut fi șterse",
  "error.delete.fileLocked": "Fișierul este în curs de editare și, prin urmare, nu poate fi șters.",
  "error.deleteUser": "Utilizatorul nu a putut fi șters. Vă rugăm să încercați din nou la o dată ulterioară.",
  "error.deregisterEmployee": "Angajatul nu a putut fi deconectat",
  "error.documentForbidden": "Nu aveți acces la document",
  "error.documentInProgress": "Documentul este trimis către TPA și nu poate fi descărcat. Încercați din nou în scurt timp.",
  "error.documentNotFound": "Documentul nu a fost găsit",
  "error.documentsInProgress": "Documentele sunt trimise la TPA și nu pot fi descărcate. Încercați din nou în scurt timp.",
  "error.download": "Eroare de descărcare",
  "error.downloadTermsOfUseDocument": "S-a produs o eroare în timpul descărcării condițiilor de utilizare",
  "error.download.transferInProgress": "Documentul este în curs de transfer. Vă rugăm să încercați din nou puțin mai târziu.",
  "error.dueDate.minDate": "Data limită nu trebuie să fie în trecut.",
  "error.duplicateName": "Nume deja atribuit",
  "error.duplicateUsername": "Adresa de e-mail nou atribuită este deja în uz",
  "error.edit": "Eroare în timpul editării",
  "error.employeeWithoutEmail": "Angajatul nu are o adresă de e-mail validă",
  "error.folderIsEmpty": "Dosarul este gol și, prin urmare, nu poate fi descărcat",
  "error.foldersAreEmpty": "Toate dosarele selectate sunt goale și, prin urmare, nu pot fi descărcate",
  "error.forbidden": "Fără autorizație",
  "error.general": "Ne pare rău, a apărut o eroare. Vă rugăm să încercați din nou mai târziu.",
  "error.getSign": "A apărut o eroare la încărcarea informațiilor necesare pentru semnătura electronică",
  "error.hr.noSubsidiarySelected": "Nu este selectată nicio zonă de salarizare",
  "error.hr.postSubsidiary": "Zona de salarizare nu a putut fi creată",
  "error.hr.subsidiaryDoesNotExist": "Zona de salarizare nu a fost găsită",
  "error.imageCrop": "Erori în prelucrarea imaginilor",
  "error.imageTooBig": "Imagine prea mare",
  "error.invalidCharacters": "Intrarea conține caractere invalide",
  "error.invalidCompanyId": "ID-ul companiei invalid",
  "error.invalid_credentials": "ID de utilizator sau parolă incorectă",
  "error.invalidDateFormat": "Format de dată invalid",
  "error.invalidEmployee": "Date incomplete sau incorecte privind angajatul",
  "error.invalidPeriodId": "Perioadă invalidă pentru această societate",
  "error.invalidRolePermissions": "Permisiunile de rol nu sunt valide",
  "error.invalidSsn": "Număr de asigurare națională invalid",
  "error.invalidTicket": "Bilet invalid",
  "error.invalidWebhookParams": "Parametrii webhook nevalabili.",
  "error.invitationNoCompany": "Invitație fără ID-ul companiei",
  "error.linkExpired": "Acest link a expirat. Vă rugăm să solicitați unul nou.",
  "error.linkInvalid": "Acest link nu este valabil.",
  "error.loadAccount": "Contul nu a putut fi încărcat",
  "error.loadAccountListing": "Lista de solduri nu a putut fi încărcată",
  "error.loadAccounts": "Conturile nu au putut fi încărcate",
  "error.loadAccountTransactions": "Rezervările nu au putut fi încărcate",
  "error.loadArchiveDocuments": "Nu au putut fi încărcate toate documentele de arhivă",
  "error.loadBadges": "Eroare la încărcarea notificărilor",
  "error.loadBalanceSheet": "Balanța nu a putut fi încărcată",
  "error.loadBankAccountTransactions": "Tranzacțiile nu au putut fi încărcate",
  "error.loadBankConnections": "Datele bancare nu au putut fi încărcate",
  "error.loadBranches": "Ramurile nu au putut fi încărcate",
  "error.loadCashAccounting": "Contul de venituri și cheltuieli nu a putut fi încărcat",
  "error.loadCompany": "Compania nu a putut fi încărcată",
  "error.loadCompanyFeatures": "Add-ons nu a putut fi încărcat",
  "error.loadContacts": "Contacte nu a putut fi încărcat",
  "error.loadCostCenters": "Centrele de cost nu au putut fi încărcate",
  "error.loadCountries": "Datele privind țara nu au putut fi încărcate",
  "error.loadCustomerListing": "Lista debitorilor nu a putut fi încărcată",
  "error.loadDestinations": "Locurile de ocupare nu au putut fi încărcate. Vă rugăm să încercați din nou mai târziu",
  "error.loadDocument": "Documentul nu a putut fi încărcat",
  "error.loadDocuments": "Documentele nu au putut fi încărcate",
  "error.loadDraft": "Versiunea nu a putut fi încărcată",
  "error.loadEmployee": "Angajatul nu a putut fi încărcat",
  "error.loadEmployeeDocuments": "Fișierele nu au putut fi încărcate",
  "error.loadEmployees": "Angajații nu au putut fi invitați",
  "error.loadEvents": "Evenimentele nu au putut fi încărcate, încercați din nou mai târziu",
  "error.loadFeatureFlags": "Steagurile de caracteristici nu au putut fi încărcate",
  "error.loadFeedCountries": "Țările nu au putut fi încărcate.",
  "error.loadFinancialAccountancies": "",
  "error.loadFormData": "Datele din formular nu au putut fi încărcate",
  "error.loadFunders": "Unitatea de cost nu a putut fi încărcată",
  "error.loadGeneralDocumentsTimeout": "Rapoartele nu sunt disponibile temporar. Vă rugăm să încercați din nou mai târziu.",
  "error.loading": "Eroare de încărcare",
  "error.loadInsuranceCarrierBalance": "Eroare la încărcarea soldului asigurării de sănătate",
  "error.loadInsuranceCarriers": "Eroare la încărcarea fondurilor de asigurări de sănătate",
  "error.loadInvitationResults": "Eroare la încărcarea modificărilor de utilizator eliberate",
  "error.loadKpiConfigurations": "Fapte și cifre Configurația nu a putut fi încărcată",
  "error.loadKpis": "Datele și cifrele nu au putut fi încărcate, încercați din nou mai târziu",
  "error.loadMessages": "Mesajele nu au putut fi încărcate",
  "error.loadModuleTypeDetails": "Eroare la încărcarea detaliilor modulului",
  "error.loadNews": "Mesajele nu au putut fi încărcate, încercați din nou mai târziu",
  "error.loadNews.noSource": "Fluxul de știri nu este disponibil în prezent.",
  "error.loadNotifications": "Setările de notificare nu au putut fi încărcate",
  "error.loadOptionalMenuItems": "Elementele opționale din meniu nu au putut fi încărcate",
  "error.loadPayrollAccount": "Contul de salarii nu a putut fi încărcat",
  "error.loadPayrollAccountDetails": "Detaliile contului de salarii nu au putut fi încărcate",
  "error.loadPendingMicrosoftActions": "Invitații și informații de reîmprospătare nu au putut fi încărcate",
  "error.loadPeriod": "Eroare la încărcarea perioadei",
  "error.loadPeriodData": "Eroare la încărcarea datelor perioadei",
  "error.loadPermissions": "Permisiunile nu au putut fi încărcate",
  "error.loadPersonnelFile": "Fișierul de personal nu a putut fi încărcat",
  "error.loadPersonnelFile.notStaff": "Utilizatorul nu este un salariat",
  "error.loadPowerBIReportEmbedToken": "",
  "error.loadPowerBIReports": "",
  "error.loadPreRegistration": "Preînregistrarea nu a putut fi încărcată.",
  "error.loadProfile": "Profilul nu a putut fi încărcat",
  "error.loadProfitAndLoss": "G&V nu a putut fi încărcat",
  "error.loadProjects": "Proiectele nu au putut fi încărcate",
  "error.loadProjectsCompanies": "Companiile pentru proiecte nu au putut fi încărcate",
  "error.loadPromotions": "Articolele nu au putut fi încărcate, încercați din nou mai târziu",
  "error.loadPublications": "Publicațiile nu au putut fi încărcate, încercați din nou mai târziu",
  "error.loadRecord": "Documentul nu a putut fi încărcat",
  "error.loadRecords": "Chitanțele nu au putut fi încărcate",
  "error.loadRecordTypes": "Eroare la încărcarea tipurilor de rezervare",
  "error.loadReports": "Rapoartele nu au putut fi încărcate",
  "error.loadResponsibleUsers": "Consilierii nu au putut fi încărcați",
  "error.loadRolePermissions": "Eroare la încărcarea permisiunilor de rol",
  "error.loadSubsidiaries": "Eroare la încărcarea codurilor societății",
  "error.loadSubsidiary": "Eroare la încărcarea codului societății",
  "error.loadTeam": "Membrii echipei nu au putut fi încărcați",
  "error.loadTermsofUse": "A apărut o eroare la încărcarea condițiilor de utilizare",
  "error.loadTicketDetails": "Consultarea nu a putut fi încărcată",
  "error.loadTickets": "Întrebările nu au putut fi încărcate",
  "error.loadUserCompanySettings": "Eroare la încărcarea setărilor utilizatorului",
  "error.loadUsers": "Utilizatorii nu au putut fi încărcați",
  "error.loadVendorListing": "Lista furnizorilor nu a putut fi încărcată",
  "error.loadWaitingUsers": "Eroare la încărcarea utilizatorilor care urmează să fie eliberați",
  "error.loginAccountSetup": "Sunteți aici pentru prima dată? Vă pregătim contul de utilizator. Vă rugăm să încercați din nou în câteva minute.",
  "error.logout": "Eroare de deconectare",
  "error.markAsDone": "Puteți marca ca fiind finalizate numai interogările pe care le-ați creat.",
  "error.markBankAccountTransactionAsPrivate": "Tranzacția nu a putut fi marcată ca privată",
  "error.maxCharacters": "Numărul maxim de caractere {count} permise",
  "error.mfaActivation.codeNotMatched": "Codul introdus este greșit",
  "error.mfaCodeAlreadySent": "Un cod a fost deja trimis, vă rugăm să așteptați cel puțin 20 de secunde înainte de a-l solicita pe următorul.",
  "error.mfaCodeWrong": "Ne pare rău, codul pe care l-ați introdus este incorect. Vă rugăm să încercați din nou.",
  "error.mfaSettingChange": "2FA nu poate fi activat sau dezactivat deoarece a fost deja solicitat un cod de activare. Vă rugăm să așteptați cel puțin 20 de secunde înainte de a încerca din nou.",
  "error.mfaSettingChange.codeAlreadySent": "Un nou cod a fost deja solicitat. Vă rugăm să așteptați cel puțin 20 de secunde înainte de a încerca din nou.",
  "error.mfaSettingChange.codeNotMatched": "Codul introdus este greșit",
  "error.microsoftLogin": "A apărut o problemă în timpul comunicării cu Microsoft. Vă rugăm să încercați să vă conectați din nou.",
  "error.missingDocument": "Document lipsă",
  "error.missingEmail": "Adresa de e-mail lipsă",
  "error.missingEmployee": "Angajat lipsă",
  "error.missingParams": "Parametrii lipsă",
  "error.move": "Eroare de mutare",
  "error.move.alreadyExists.unknown": "Eroare la mutare: Un dosar sau fișier cu acest nume există deja",
  "error.msTokenExpired": "Tokenul MS a expirat. Vă rugăm să îl reînnoiți.",
  "error.multipleArchives": "Mai multe arhive de clienți disponibile pentru această companie",
  "error.noArchive": "Nu există arhivă de clienți disponibilă pentru această companie",
  "error.noCompanySelected": "Nicio societate selectată",
  "error.noDocumentSelected": "Vă rugăm să selectați un document pentru termenii de utilizare",
  "error.noDotAtEnd": "Ultimul caracter nu trebuie să fie un punct",
  "error.noFinancialAccountancies": "Societatea nu are FIBU",
  "error.noMsAccountAvailable": "Utilizatorul nu poate fi invitat. Este de așteptat ca personalul TPA să aibă deja un cont Microsoft.",
  "error.noRecordTypes": "Nu s-au găsit categorii de documente",
  "error.noSignees": "Vă rugăm să adăugați adresele de e-mail",
  "error.noSubsidiaries.accounting": "Nu s-au găsit coduri de firmă",
  "error.noSubsidiaries.hr": "Nu s-au găsit domenii contabile",
  "error.noSubsidiaryWithEmployeeRead": "Nu există un cerc de salarizare cu drepturi de citire pentru angajați.",
  "error.notAdvisor": "Nu există autorizații de consultant",
  "error.notAllowed": "Acțiunea nu trebuie să fie executată",
  "error.notificationCenter.load": "Notificările nu au putut fi încărcate",
  "error.notificationCenter.markAllAsRead": "Notificările nu au putut fi marcate ca citite",
  "error.notificationCenter.markAsRead": "Notificarea nu a putut fi marcată ca citită",
  "error.oauthApi": "A existat o problemă cu autentificarea. Vă rugăm să încercați din nou.",
  "error.oauthCompany": "Nu există nicio societate selectată. Vă rugăm să selectați o companie și să încercați din nou.",
  "error.oauthUrl": "A existat o problemă la apelarea datelor și cifrelor. Vă rugăm să încercați din nou.",
  "error.overlappingSignature": "Semnătură care se suprapune. Vă rugăm să selectați o nouă poziție",
  "error.passwordReset.tokenExpired": "Acest link de resetare a parolei a expirat",
  "error.passwordReset.tokenNotFound": "Link de resetare a parolei invalide",
  "error.payAccountTransaction": "",
  "error.payTaxAccount": "Erori la plata obligațiilor fiscale",
  "error.payUnpaidAccount": "Eroare la plata unei facturi TPA",
  "error.periodClosed": "Perioada deja încheiată",
  "error.permission.corruptRoles": "Roluri nevalabile",
  "error.permission.onlyOneRoleAllowed": "Se poate seta doar un singur rulou",
  "error.postTicket": "Consultarea nu a putut fi creată.",
  "error.projectForbidden": "Nu aveți acces la proiecte",
  "error.projectIsEmpty": "Proiectul este gol și, prin urmare, nu poate fi descărcat",
  "error.projectPermissions": "Permisiunile proiectului nu au putut fi încărcate",
  "error.projects.cantMove": "Fișierele nu au putut fi mutate. Vă rugăm să încercați din nou mai târziu.",
  "error.projects.cantPaste": "Fișierele nu au putut fi introduse. Vă rugăm să încercați din nou mai târziu.",
  "error.projects.openBookmarkUrlFailed": "URL-ul nu a putut fi deschis",
  "error.qes.missingSignaturePositions": "Vă rugăm să adăugați pozițiile de semnătură necesare",
  "error.recordForbidden": "Nu aveți acces la voucher",
  "error.recordWithNotAllowedCompany": "Nu există autorizație pentru societatea căreia îi este atribuit documentul.",
  "error.refreshBankConnection": "Contul bancar nu a putut fi reînnoit",
  "error.releaseEmployeeDocument": "Eroare de eliberare",
  "error.releaseReport": "Eroare de eliberare",
  "error.removeBankAccountTransactionPrivateMark": "Nu s-a putut deselecta",
  "error.removeCompanyFromUserFavorites": "Compania nu a putut fi eliminată din favoritele tale",
  "error.rename.alreadyExists.file": "Eroare la redenumire: Un fișier cu acest nume există deja",
  "error.rename.alreadyExists.folder": "Eroare la redenumire: Există deja un dosar cu acest nume",
  "error.requiredField": "Intrarea necesară",
  "error.resendTermsOfUseEmail": "A apărut o eroare la retrimiterea e-mailului",
  "error.save": "Eroare la salvare",
  "error.saveKpiConfigurations": "Fapte și cifre Configurația nu a putut fi salvată",
  "error.saveOptionalMenuItems": "Elementele opționale din meniu nu au putut fi salvate. Vă rugăm să încercați din nou mai târziu",
  "error.saveTermsOfUse": "A apărut o eroare în timpul salvării condițiilor de utilizare. Vă rugăm să încercați din nou mai târziu",
  "error.send": "Eroare la expediere.",
  "error.setDocumentOrSignees": "S-a produs o eroare în timpul salvării documentului sau a persoanelor care urmează să semneze",
  "error.settings": "Eroare la modificarea setărilor",
  "error.signFileTooLarge": "Fișierul este prea mare și nu poate fi semnat electronic.",
  "error.signReadOnlyDocument": "Documentul este numai pentru citire și, prin urmare, nu poate fi semnat.",
  "error.signTermsOfUse": "A apărut o eroare în timpul acceptării condițiilor de utilizare. Vă rugăm să încercați din nou mai târziu",
  "error.subsidiaryWithoutRecordTypes": "{subsidiary} nu are niciun simbol de document activ",
  "error.termsOfUseDuplicateFile": "Același fișier cu un nume diferit există deja",
  "error.termsOfUseFolderNotFound": "Încă nu a fost creat niciun dosar cu termenii de utilizare",
  "error.timeout": "În prezent, acest proces durează ceva mai mult. Vă rugăm să încercați din nou mai târziu.",
  "error.toggleCompanyFeature": "Eroare la activarea/dezactivarea add-on-ului",
  "error.tokenRefresh": "Eroare de autentificare",
  "error.tooManyCharacters": "Numărul maxim de caractere {maxCharacters} permise pentru fiecare mesaj.",
  "error.transfer": "Eroare de transfer",
  "error.transferEmployee": "Angajatul nu a putut fi transferat",
  "error.transferEmployeeDirect": "Transmiterea către fondul de asigurări de sănătate a eșuat. Vă rugăm să vă contactați consilierul dumneavoastră.",
  "error.transferEmployee.validation.general": "",
  "error.transferEmployee.validation.general.zipcode": "",
  "error.unknown": "Eroare necunoscută ({statusCode})",
  "error.unsupportedDocumentType": "Acest tip de document nu este acceptat",
  "error.updateBankAccountAccountNumber": "Contul nu a putut fi salvat.",
  "error.upgradeToMicrosoft": "Upgrade-ul nu a putut fi efectuat. Vă rugăm să vă verificați parola.",
  "error.upload": "Eroare de încărcare",
  "error.userDeactivated": "Acest utilizator Microsoft a fost dezactivat.",
  "error.userNotRegistered": "Utilizatorul nu este înregistrat",
  "error.userUnknown": "Utilizator necunoscut",
  "externalLinkDialog.content": "",
  "externalLinkDialog.title": "",
  "failedUserDialog.amount": "<b>{failedUserCount}</b> din {userCount} utilizatorii nu au putut fi creați/modificați:",
  "failedUserDialog.button.text.continue": "Continuă totuși",
  "failedUserDialog.button.text.retry": "Încearcă din nou",
  "failedUserDialog.error.emailAlreadyExists": "Adresa de e-mail deja utilizată",
  "failedUserDialog.error.pendingPermissionChanges": "Așteptând deja eliberarea",
  "failedUserDialog.message": "Eroare la crearea/modificarea",
  "features.default.prices.from": "",
  "features.default.prices.fromFlat": "",
  "features.default.prices.fromTo": "",
  "features.default.prices.fromToFlat": "",
  "filterDialog.apply": "Aplicați filtrul",
  "filterDialog.category.advanced": "Filtre avansate",
  "filterDialog.category.assigned": "Zona atribuită",
  "filterDialog.header": "Opțiuni de filtrare",
  "filterDialog.required": "Vă rugăm să selectați o valoare",
  "filterDialog.reset": "RESETARE FILTRU",
  "finishProjectDialog.text": "Sunteți sigur că doriți să finalizați proiectul <b>{NumeProiect}</b>?",
  "finishProjectDialog.title": "Proiect complet",
  "footer.copyright": "Toate drepturile rezervate",
  "footer.disclaimer": "Disclaimer",
  "footer.generalBusinessTerms": "AGB",
  "footer.legalInformation": "Imprimare",
  "footer.privacy": "Protecția datelor",
  "helpcenter.ideas": "Idei sau dorințe",
  "helpcenter.problems": "Raportați problemele",
  "helpcenter.problems.email": "Adresa de e-mail",
  "helpcenter.problems.send": "Raportați o problemă",
  "helpcenter.problems.upload.failed": "Problema nu a putut fi transmisă",
  "helpcenter.problems.upload.success": "Problema a fost transmisă cu succes",
  "helpcenter.problems.upload.tooManyRequests": "",
  "helpcenter.problems.whathappened": "Ce s-a întâmplat în schimb?",
  "helpcenter.problems.whatshouldhappen": "Ce ar fi trebuit să se întâmple?",
  "helpcenter.problems.whatwasdone": "Ce s-a încercat să se facă?",
  "helpCenter.title": "Centru de asistență",
  "helpcenter.videos": "Videoclipuri de ajutor",
  "helpcenter.videos.tpaonly": "Ajutor video pentru angajații TPA",
  "hr.casualEmployment": "Angajate de la caz la caz",
  "hr.disclaimer.additionalData": "Pentru transmisia directă mai avem nevoie de câteva date suplimentare. Vă rugăm să le completați.",
  "hr.disclaimer.employmentDate": "Data angajării",
  "hr.disclaimer.subtitle": "Doriți să trimiteți datele către TPA sau direct către compania de asigurări de sănătate?",
  "hr.disclaimer.title": "Transmisie",
  "hr.disclaimer.transfer.direct": "Transferul de date se va face direct către fondul austriac de asigurări de sănătate.",
  "hr.disclaimer.transfer.direct.subtitle": "Precizăm că TPA va verifica datele doar în următoarele zile, cel mai devreme când biroul este deschis.",
  "hr.disclaimer.transfer.tpa": "Transmiterea datelor se face către TPA",
  "hr.disclaimer.transfer.tpa.subtitle": "Menționăm că înregistrarea angajaților la Fondul austriac de asigurări de sănătate va avea loc numai după ce TPA a procesat datele.",
  "hr.documents.backLabel": "Personal/File",
  "hr.documents.emptyState.header": " captură",
  "hr.documents.emptyState.message": "Începeți să vă încărcați fișierele acum.",
  "hr.documents.list.backLabel": "Personal/ Documente",
  "hr.documents.nonWorkingTimes": "Timpii de neexecutare",
  "hr.documents.nonWorkingTimesUpload": "Timpii de nefuncționare a încărcării fișierelor",
  "hr.documents.upload.navHeader": "{documentType}",
  "hr.documents.upload.navLabel": "Personal/Documente",
  "hr.employee.employment": ". Relația de muncă",
  "hr.employee.employmentRelationship": "Relația de muncă",
  "hr.employee.jobDescription": "Titlul postului",
  "hr.employee.payslip.upload.date": "Perioada",
  "hr.employee.payslip.upload.title": "Încărcați fluturașul de salariu",
  "hr.employee.payslip.upload.type": "Tipul fișei de plată",
  "hr.employee.payslip.upload.type.monthly": "Buletin de salariu lunar",
  "hr.employee.payslip.upload.type.yearly": "Buletin de salariu anual",
  "hr.employee.reasonForLeaving": "Motivul plecării",
  "hr.employee.workplaceState": "Starea locului de muncă",
  "hr.finished.analysis": "Evaluări lunare",
  "hr.finished.billing": "Declarații lunare",
  "hr.finished.globalAnalysis": "Evaluarea la nivelul întregii companii",
  "hr.finished.globalBilling": "Contabilitatea la nivelul întregii societăți",
  "hr.insuranceCarrier": "Transportator de asigurări",
  "hr.overview.continuousActions": "Funcții continue",
  "hr.recordTypes.names.benefitsInKindCar": "Beneficii în natură mașină",
  "hr.recordTypes.names.contractAdjustmentsAndSpecialPayments": "Ajustări contractuale și plăți speciale",
  "hr.recordTypes.names.mealDeductions": "Deducerile de masă",
  "hr.recordTypes.names.nonWorkingTimes": "Orele de neprezentare",
  "hr.recordTypes.names.overtimeAndBonusPayments": "Plata orelor suplimentare și a bonusurilor",
  "hr.recordTypes.names.supplementaryDocuments": "Documente suplimentare",
  "hr.recordTypes.names.timeRecords": "Înregistrări de timp",
  "hr.recordTypes.names.travelExpenseReport": "Raport de cheltuieli de călătorie",
  "hr.tickets.preRegistration.NavBar": "Întrebare despre {preÎnregistrare}",
  "hr.tickets.preRegistration.NavBarLabel": "Personal / Angajați / Întrebări",
  "hr.tickets.preRegistration.toOverview": "LA DOSARUL PERSONAL",
  "imageCrop.description": "Trageți pentru a muta imaginea",
  "imageCrop.title": "Imagine de poziție",
  "imageCrop.zoom": "Zoom",
  "language.german": "Germană",
  "maritalStatus.divorced": "divorțat",
  "maritalStatus.livingInPartnership": "Trăind în parteneriat",
  "maritalStatus.married": "Căsătorit/parteneriat înregistrat",
  "maritalStatus.separated": "Trăind separat",
  "maritalStatus.unknown": "necunoscut",
  "maritalStatus.unmarried": "unic",
  "maritalStatus.widowed": "văduvă",
  "menu.answer": "Răspuns",
  "menu.assign": "Atribuiți",
  "menu.cancelRelease": "Retragerea eliberării",
  "menu.delete": "Ștergeți",
  "menu.details": "Detalii",
  "menu.download": "Descărcați",
  "menu.draft": "Cerere de aprobare",
  "menu.edit": "Editați",
  "menu.editRelease": "Solicitați din nou eliberarea",
  "menu.finish": "Încuietoare",
  "menu.markAsDone": "Marcați ca fiind gata",
  "menu.move": "Mutarea",
  "menu.openInBrowser": "Deschideți în browser",
  "menu.openInSharepoint": "Deschideți în Sharepoint",
  "menu.rename": "Redenumiți",
  "menu.requestRelease": "Cerere de eliberare",
  "menu.showReleases": "Afișează lansări",
  "menu.view": "Vezi",
  "mfa.activation.dialog.code.required": "Vă rugăm să introduceți codul",
  "mfa.activation.dialog.description": "Vă rugăm să introduceți codul pe care vi l-am trimis la numărul de telefon pentru a activa autentificarea cu doi factori.",
  "mfa.activation.dialog.title": "Activați 2FA",
  "mfa.activation.success.dialog.title": "2FA a fost activat cu succes",
  "mfa.changeSetting.dialog.code.field.label": "Cod",
  "mfa.changeSetting.dialog.resendCode": "Trimiteți din nou codul",
  "mfa.codeResend.success.dialog.title": "Codul a fost trimis din nou",
  "mfa.deactivation.dialog.description": "Vă rugăm să introduceți codul pe care vi l-am trimis la numărul de telefon pentru a dezactiva autentificarea cu doi factori.",
  "mfa.deactivation.dialog.title": "Dezactivați 2FA",
  "mfa.deactivation.success.dialog.title": "2FA a fost dezactivat cu succes",
  "mfa.validation.code.length": "Codul are 6 cifre",
  "mfa.validation.code.required": "Codul trebuie introdus",
  "mfa.validation.code.type": "Sunt permise numai numere",
  "microsoft_oauth.button.login.title": "Conectați-vă cu Microsoft",
  "microsoft_oauth.button.send_code.title": "Trimiteți codul",
  "microsoft_oauth.button.upgrade.title": "Efectuați actualizarea",
  "missingEmailDialog.description": "Adresa de e-mail a lui <b>{name}</b> nu este înregistrată. Vă rugăm să introduceți o adresă de e-mail validă pentru a putea trimite invitația.",
  "missingEmailDialog.title": "Adresa de e-mail lipsă",
  "missingEmail.email.error": "Vă rugăm să introduceți o adresă de e-mail corectă",
  "missingEmail.email.label": "Introduceți adresa de e-mail",
  "module.finished.globalReports": "Rapoarte la nivelul întregii companii",
  "modules.delete.report.message": "Chiar doriți să ștergeți raportul {name}?",
  "modules.delete.report.title": "Ștergeți raportul",
  "navbar.week": "KW {săptămână} {an}",
  "newUsername.checkbox.error": "Vă rugăm să confirmați noua dvs. adresă de e-mail",
  "notificationCenter.emptyState": "Nu există notificări",
  "notificationCenter.filter.accounting": "Contabilitate",
  "notificationCenter.filter.appNotification:maintenance": "Mesaje de întreținere",
  "notificationCenter.filter.appNotification:newFeature": "Caracteristici noi",
  "notificationCenter.filter.appNotification:update": "",
  "notificationCenter.filter.companySpecific": "Specific companiei",
  "notificationCenter.filter.employeeDocument": "Documente pentru angajați",
  "notificationCenter.filter.file": "Documente",
  "notificationCenter.filter.general": "Generalități",
  "notificationCenter.filter.generalDocument": "Dosare generale",
  "notificationCenter.filter.hr": "Contabilitatea salariilor",
  "notificationCenter.filter.invoice": "Încasări",
  "notificationCenter.filter.login": "Autentificare",
  "notificationCenter.filter.module": "Modul",
  "notificationCenter.filter.notificationType": "Tip de notificare",
  "notificationCenter.filter.other": "Altele",
  "notificationCenter.filter.payslip": "fluturaș de salariu",
  "notificationCenter.filter.project": "Proiecte",
  "notificationCenter.filter.report": "Rapoarte",
  "notificationCenter.filter.ticket": "Cerere de informații",
  "notificationCenter.filter.unread": "Numai necitit",
  "notificationCenter.markAllAsRead": "Marcați toate ca citite",
  "notificationCenter.title": "Centrul de notificări",
  "openEmployeeDocumentReleasesDialog.releaseMessage.multi": "Aveți <b>{numărul} de documente noi</b> în dosarul dvs. personal care necesită aprobarea dvs.",
  "openEmployeeDocumentReleasesDialog.releaseMessage.single": "Aveți <b>un nou document</b> în dosarul dvs. personal care necesită aprobarea dvs.",
  "openEmployeeDocumentReleasesDialog.submit": "Vizualizați și partajați",
  "openEmployeeDocumentReleasesDialog.title": "Partajați documentele",
  "paymentDialog.button.pay": "",
  "paymentDialog.field.amount": "",
  "paymentDialog.field.amount.maxError": "",
  "paymentDialog.field.amount.minError": "",
  "paymentDialog.field.amount.typeError": "",
  "paymentDialog.field.iban": "",
  "paymentDialog.field.iban.invalidError": "",
  "paymentDialog.field.purpose": "",
  "paymentDialog.title": "",
  "pdfView.error.caption": "Documentul nu a putut fi încărcat, vă rugăm să încercați din nou",
  "pendingUserCreationDialog.message": "Crearea utilizatorilor în Sharepoint poate dura până la 30 de minute. Vă rugăm să aveți răbdare până când permisiunile au fost create.",
  "pendingUserCreationDialog.title": "Se creează utilizatori",
  "permission.accounting.banking": "Detalii bancare",
  "permission.accounting.global.reports": "Rapoarte contabile la nivelul întregii companii",
  "permission.accounting.global.results": "Evaluări contabile la nivelul întregii companii",
  "permission.accounting.incaseoflaw": "În caz de drept",
  "permission.accounting.records": "Anunțarea contabilității",
  "permission.accounting.reports": "Rapoarte contabile",
  "permission.accountTransaction.payment": "",
  "permission.expenses.cockpit": "Vizualizați faptele și cifrele din cabina de pilotaj",
  "permission.expenses.kpi": "Utilizarea modulului de date și cifre",
  "permission.hr.employeePayrollAccount": "Vizualizați contul de salarizare al angajatului",
  "permission.hr.employerPayrollAccount": "Contul de salarii al angajatorului",
  "permission.hr.employerPayrollAccount.details": "Vezi detalii despre tipul de salariu",
  "permission.hr.global.reports": "Rapoarte contabile de salarizare la nivelul întregii companii",
  "permission.hr.global.results": "Analize ale contabilității salariilor la nivelul întregii companii",
  "permission.hr.personnel": "Angajați",
  "permission.hr.records": "Anunțul privind soluționarea situației personalului",
  "permission.hr.reports": "Rapoarte de evidență a salariilor",
  "permission.option.canAccess": "pot accesa",
  "permission.option.canEdit": "poate edita",
  "permission.option.canEdit.all": "poate edita/debloca totul",
  "permission.option.canEdit.none": "nu poate edita/debloca nimic",
  "permission.option.canEdit.some": "Poate edita/elibera parțial",
  "permission.option.canPay": "poate plăti",
  "permission.option.canRead": "poate vedea",
  "permission.option.canRead.all": "poate vedea totul",
  "permission.option.canRead.none": "nu pot vedea nimic",
  "permission.option.canRead.some": "pot vedea parțial",
  "permission.option.canRelease": "poate elibera",
  "permission.option.canUpdate": "poate edita",
  "permission.option.canUpload": "poate încărca",
  "permission.option.mixed": "mixt",
  "permission.option.none": "fără autorizație",
  "permission.projects": "Utilizarea modulului de proiecte",
  "permission.settings.company": "Vizualizați setările companiei",
  "permission.settings.features": "Modificarea suplimentelor",
  "permission.settings.images": "Modificarea imaginii de copertă și a logo-ului",
  "permission.settings.user": "Sugerați modificări ale drepturilor utilizatorilor",
  "permission.taxAccount.payment": "Plata datoriilor fiscale",
  "permission.unpaidAccount.payment": "Plata facturilor restante",
  "project.invitation": "Invitație de proiect",
  "projectItemPermission.owner": "Acces complet",
  "projectItemPermission.owner.description": "Toate drepturile de a edita dosare și fișiere. Editați și completați proiectul și gestionați membrii și permisiunile.",
  "projectItemPermission.read": "Citește",
  "projectItemPermission.read.description": "Vizualizarea folderelor și a fișierelor și descărcarea fișierelor",
  "projectItemPermission.readNoDownload": "Doar citire",
  "projectItemPermission.write": "Editați",
  "projectItemPermission.write.description": "Vizualizați, creați și redenumiți dosare. Vizualizați, încărcați, editați și ștergeți fișiere.",
  "projects.activate": "RESTAURARE",
  "projects.active.emptystate.buttonlabel": "Creați un proiect",
  "projects.active.emptystate.message": "Nu există încă niciun proiect pentru această companie",
  "projects.addCompanies": "Adăugați compania",
  "projects.addCompanies.tooltip": "Creați proiectul în mai multe companii",
  "projects.addFolder": "Creați un dosar",
  "projects.addMembers": "Adăugați membri",
  "projects.addMembers.bulk.success.title": "Membri adăugați cu succes",
  "projects.bulkDeleteDialog.message": "Sunteți sigur că doriți să ștergeți fișierele și folderele selectate?",
  "projects.bulkDeleteDialog.title": "Ștergerea fișierelor și folderelor",
  "projects.cancelRequestedReleaseConfirmationDialog.confirm": "Retragerea",
  "projects.cancelRequestedReleaseConfirmationDialog.message": "Sunteți sigur că doriți să retrageți share-ul deschis la <b>{fileName}</b>?",
  "projects.cancelRequestedReleaseConfirmationDialog.title": "Retragerea eliberării existente?",
  "projects.closed.emptystate.message": "Nu există încă niciun proiect finalizat pentru această companie",
  "projects.companies.emptyState.title": "Nu există companii disponibile",
  "projects.companies.submit": "Adăugați",
  "projects.copySuccessDialog.message": "Fișierele selectate au fost copiate în clipboard-ul dvs.",
  "projects.copySuccessDialog.title": "Copiat în clipboard",
  "projects.create.bulk.backToProjects": "Înapoi la Proiecte",
  "projects.create.bulk.description": "Proiectele sunt în curs de creare. Acest lucru poate dura câteva minute, vă rugăm să nu închideți fereastra browserului.",
  "projects.create.bulk.start": "Lansare",
  "projects.deleted.emptystate.message": "Nu există încă niciun proiect șters pentru această companie",
  "projects.deleteDialog.message.file": "Sunteți sigur că doriți să ștergeți fișierul <b>{name}</b>?",
  "projects.deleteDialog.message.folder": "Sunteți sigur că doriți să ștergeți folderul <b>{name}</b>?",
  "projects.deleteDialog.message.project": "Sunteți sigur că doriți să ștergeți proiectul <b>{name}</b>?",
  "projects.deleteDialog.title.file": "Ștergeți fișierul",
  "projects.deleteDialog.title.folder": "Ștergeți dosarul",
  "projects.deleteDialog.title.project": "Ștergeți proiectul",
  "projects.emptystate.title": "Nu există proiecte disponibile",
  "projects.externalId": "Număr de referință:",
  "projects.finish.successDialog.title": "Proiect finalizat cu succes",
  "projects.folder.emptystate.message": "Acum începeți să umpleți dosarul.",
  "projects.folder.emptystate.title": "Creați și încărcați conținut",
  "projects.importUsers.add": "Adăugați",
  "projects.importUsers.replace": "Înlocuiți",
  "projects.importUsers.switch": "Importați membri dintr-un alt proiect",
  "projects.international": "Proiect internațional",
  "projects.international.activate": "Pentru a transforma un proiect într-unul internațional, trebuie să activați această funcționalitate.",
  "projects.international.confirmButton": "Conversia proiectului",
  "projects.international.disabled.refresh": "Autentificarea dvs. internațională MS a expirat, vă rugăm să o reînnoiți. După aceea, puteți schimba \"{proiect}\" într-un proiect internațional.",
  "projects.international.disabled.unlock": "Pentru a transforma un proiect într-unul internațional, trebuie să activați această funcționalitate în prezentarea generală a proiectului.",
  "projects.international.info.message": "Transformarea unui proiect într-un proiect internațional permite invitarea personalului TPA din alte țări la proiect pentru a lucra cu clientul dincolo de granițe. În acest scop, toate fișierele și documentele stocate ca parte a proiectului pe Microsoft SharePoint sunt transferate între diferiți chiriași Microsoft și, în acest sens, sunt refăcute toate autorizațiile utilizatorilor pentru lucrul la proiect. Orice permisiune cu grad fin de detaliere poate fi pierdută în acest proces. Prin urmare, se recomandă să verificați opțiunile de acces după conversia la un proiect internațional. Această etapă nu poate fi revocată.",
  "projects.international.info.subtitle": "Vă rugăm să rețineți următoarele:",
  "projects.international.invite.message": "Ați fost adăugat la noi proiecte internaționale. Activați următoarele țări o dată pentru a putea vizualiza proiectele din aceste țări.",
  "projects.international.invite.title": "Deblocați noi țări",
  "projects.international.migrating": "Proiect internațional (conversie în curs)",
  "projects.international.migrating.message": "În prezent, proiectul este în curs de transformare într-un proiect internațional. Editarea nu va fi posibilă din nou până când conversia nu va fi finalizată.",
  "projects.international.plural": "Proiecte internaționale",
  "projects.international.switch": "Transformarea acestui proiect într-un proiect internațional",
  "projects.international.undoWarning.message": "Sunteți sigur că doriți să transformați acest proiect într-un proiect internațional? Toate datele proiectului vor fi copiate în TPA AT sharepoint. Această acțiune nu poate fi anulată odată ce proiectul a fost creat.",
  "projects.international.undoWarning.message.at": "Sunteți sigur că doriți să transformați acest proiect într-un proiect internațional? Această acțiune nu poate fi anulată odată ce proiectul a fost creat.",
  "projects.international.undoWarning.title": "Această acțiune nu poate fi anulată",
  "projects.invite.international": "Ați fost adăugat la proiectele internaționale. Deblocați-le acum.",
  "projects.invite.message": "Ați fost adăugat la proiecte din țările {numCountries}. Deblocați-le acum.",
  "projects.invite.message.singular": "Ați fost adăugat la proiectele din {country}. Deblocați-le acum.",
  "projects.members.assignPermission": "Atribuirea autorizației",
  "projects.membersIncluded.multi": "Inclusiv {name} și {name}.",
  "projects.membersIncluded.single": "Inclusiv {nume}.",
  "projects.moveConfirmationDialog.message": "Fișierele mutate sunt șterse din dosarul original!",
  "projects.moveConfirmationDialog.title": "Mutarea fișierelor?",
  "projects.moveSuccessDialog.message": "Fișierele au fost mutate cu succes",
  "projects.moveSuccessDialog.title": "Fișiere mutate",
  "projects.navbar.activeProjects": "Activ",
  "projects.navbar.deletedProjects": "Șters",
  "projects.navbar.fileUpload": "Încărcarea fișierelor",
  "projects.navbar.finishedProjects": "Completat",
  "projects.navbar.heading": "Proiecte",
  "projects.navbar.notConfiguredProjects": "Neconfigurat",
  "projects.navbar.overview": "Prezentare generală{path}",
  "projects.navbar.project.fileTree": "Structura dosarelor",
  "projects.navbar.project.heading": "Proiect: {projectName}",
  "projects.navbar.project.releases": "Eliberări",
  "projects.navbar.releases.heading": "Acțiuni pentru {fileName}",
  "projects.navbar.ticket.heading": "Întrebare despre {fileName}",
  "projects.new": "Proiect nou",
  "projects.new.createNewProject": "Creați un proiect",
  "projects.new.externalId": "Numărul de referință",
  "projects.new.name": "Numele proiectului",
  "projects.new.permissionContextMenu.remove": "Eliminați",
  "projects.new.permissions.text": "Adăugați noi membri și atribuiți drepturi de utilizator.",
  "projects.new.title": "Denumire proiect",
  "projects.new.userSearch.placeholder": "Invitați membrul...",
  "projects.notConfigured.emptystate.message": "Nu există încă un proiect de configurat pentru această companie",
  "projects.openReleases.plural": "Eliberări deschise",
  "projects.openReleases.singular": "Eliberare deschisă",
  "projects.pasteProgressDialog.title": "Fișierele sunt inserate",
  "projects.pasteSuccessDialog.message": "Fișierele au fost introduse cu succes",
  "projects.pasteSuccessDialog.title": "Fișiere introduse",
  "projects.refreshDialog.message": "Autentificările Microsoft pentru următoarele țări au expirat. Vă rugăm să le reînnoiți.",
  "projects.refreshDialog.title": "Conexiunile Microsoft au expirat",
  "projects.refresh.message": "Autentificările Microsoft pentru țările {numCountries} au expirat. Vă rugăm să le reînnoiți.",
  "projects.refresh.message.singular": "Autentificarea Microsoft pentru {țară} a expirat. Vă rugăm să o reînnoiți.",
  "projects.releaseConfirmationDialog.message": "Vă rugăm să rețineți următorul comentariu de la {name}:",
  "projects.releaseConfirmationDialog.title": "Împărtășiți documentul",
  "projects.releases.emptystate.message": "În prezent nu aveți nicio versiune.",
  "projects.releases.emptystate.title": "Nu există versiuni disponibile",
  "projects.releases.reject": "Declin",
  "projects.releases.status.canceled": "retrasă",
  "projects.releases.status.canceledFrom": "retrasă de {name}",
  "projects.releases.status.canceled.tooltip": "retrasă pe {performedAt} de către {name}",
  "projects.releases.status.open": "Așteptând eliberarea",
  "projects.releases.status.openFrom": "în așteptarea eliberării lui {name}",
  "projects.releases.status.open.tooltip": "în așteptarea eliberării (termen limită: {data scadentă}) a {numelui}.",
  "projects.releases.status.rejected": "Respins",
  "projects.releases.status.rejectedFrom": "respinsă de {name}",
  "projects.releases.status.rejected.tooltip": "respinsă pe {performedAt} de către {name}",
  "projects.releases.status.released": "eliberat",
  "projects.releases.status.releasedFrom": "eliberat de {name}",
  "projects.releases.status.released.tooltip": "eliberat pe {performedAt} de către {name}",
  "projects.releaseSuccessDialog.message": "<b>{name}</b> va analiza acum fișierele și vă va contacta pentru orice întrebări.",
  "projects.releaseSuccessDialog.multi.message": "Persoanele desemnate vor examina dosarele și vă vor contacta pentru orice întrebări.",
  "projects.releaseSuccessDialog.title": "Eliberare solicitată cu succes",
  "projects.requestReleaseConfirmationDialog.confirm": "Retrageți și recreați",
  "projects.requestReleaseConfirmationDialog.message": "Există deja un share deschis pentru <b>{Nume fișier}</b>. Pentru a crea o nouă acțiune, acțiunea existentă trebuie să fie retrasă.",
  "projects.requestReleaseConfirmationDialog.multi.message": "Există deja lansări deschise în selecția dumneavoastră. Pentru a crea noi acțiuni, acțiunile existente trebuie să fie retrase.",
  "projects.requestReleaseConfirmationDialog.multi.title": "Retragerea eliberărilor existente?",
  "projects.requestReleaseConfirmationDialog.title": "Retragerea eliberării existente?",
  "projects.restoreDialog.message.project": "Sunteți sigur că doriți să activați proiectul <b>{name}</b>?",
  "projects.restoreDialog.title.project": "Activați proiectul",
  "projects.statusClosed": "Finalizat pe",
  "projects.statusClosed.personalized": "Completat de {name} la data de",
  "projects.statusDeleted": "Șters pe",
  "projects.statusDeleted.personalized": "Șterse de {name} pe",
  "projects.statusInProgress": "Ultima modificare la",
  "projects.statusInProgress.personalized": "Ultima modificare de {name} la",
  "projects.tickets.plural": "Întrebări",
  "projects.tickets.singular": "Consultare",
  "records.details.upload.header": "Înregistrare {recordType}",
  "recordType.statusClosed": "Transmisă pe",
  "recordType.statusInProgress": "Editat pe",
  "recordType.statusOpen": "Inedit",
  "renameDialog.button.text.rename": "Redenumiți",
  "renameDialog.label.document": "Desemnare",
  "renameDialog.label.file": "Numele fișierului",
  "renameDialog.label.folder": "Numele dosarului",
  "renameDialog.title.document": "Redenumiți documentul",
  "renameDialog.title.file": "Redenumiți fișierul",
  "renameDialog.title.folder": "Redenumiți dosarul",
  "renameProjectItemDialog.button.text.rename": "Redenumiți",
  "renameProjectItemDialog.itemName.file": "Numele fișierului",
  "renameProjectItemDialog.itemName.folder": "Numele dosarului",
  "renameProjectItemDialog.title.file": "Redenumiți fișierul",
  "renameProjectItemDialog.title.folder": "Redenumiți dosarul",
  "renameRecordDialog.button.text.rename": "Redenumiți",
  "renameRecordDialog.name": "Desemnare",
  "renameRecordDialog.title": "Redenumiți documentul",
  "requestReleaseProjectItemDialog.button.text.request": "Întrebări",
  "requestReleaseProjectItemDialog.info": "Când am nevoie de o semnătură calificată?",
  "requestReleaseProjectItemDialog.label.comment": "Comentariu",
  "requestReleaseProjectItemDialog.label.dueDate": "Termen limită",
  "requestReleaseProjectItemDialog.label.requiresQes": "Eliberarea prin semnătură electronică (numai fișiere PDF)",
  "requestReleaseProjectItemDialog.placeholder.searchForUsers": "Introduceți numele...",
  "requestReleaseProjectItemDialog.qes.collective.subtitle": "Toate persoanele trebuie să elibereze",
  "requestReleaseProjectItemDialog.qes.collective.title": "Eliberare colectivă",
  "requestReleaseProjectItemDialog.qes.single.subtitle": "Doar o singură persoană trebuie să elibereze",
  "requestReleaseProjectItemDialog.qes.single.title": "Eliberare unică",
  "requestReleaseProjectItemDialog.searchForUsers.error": "Vă rugăm să selectați un nume din listă",
  "requestReleaseProjectItemDialog.text": "Selectați persoanele cărora doriți să le atribuiți eliberarea documentelor enumerate și introduceți un termen limită.",
  "requestReleaseProjectItemDialog.title.addResponsibleUser": "Adăugați o altă persoană",
  "requestReleaseProjectItemDialog.title.create": "Cerere de eliberare",
  "requestReleaseProjectItemDialog.title.edit": "Editați eliberarea",
  "results.accountSheet.accountDetails.pay": "",
  "results.accountSheet.accountTransaction.lastCompletedPayment": "",
  "results.accountSheet.accountTransaction.lastCompletedPaymentWithAmount": "",
  "results.accountSheet.accountTransaction.lastCompletedPaymentWithAmount.detailed": "",
  "results.accountSheet.backLabel.accountSheet": "Fișa contului {accountNr} \"{name}\"",
  "results.accountSheet.backLabel.results.accountListing": "Lista de solduri",
  "results.accountSheet.backLabel.results.balanceSheet": "Bilanț",
  "results.accountSheet.backLabel.results.cashAccounting": "Contul de venituri și cheltuieli",
  "results.accountSheet.backLabel.results.customerListing": "Lista debitorilor",
  "results.accountSheet.backLabel.results.profitAndLoss": "G&V",
  "results.accountSheet.backLabel.results.vendorListing": "Lista conturilor de plătit",
  "results.accountSheet.columns.amount": "Suma",
  "results.accountSheet.columns.documentDate": "Data documentului",
  "results.accountSheet.columns.documentNumber": "Documentul nr.",
  "results.accountSheet.columns.documents": "Încasări",
  "results.accountSheet.columns.offsettingAccounts": "Contra-conturi",
  "results.accountSheet.columns.openItemAmount": "Suma deschisă",
  "results.accountSheet.columns.postingSymbol": "BS",
  "results.accountSheet.columns.postingText": "Text de postare",
  "results.accountSheet.details.costCenter": "Centrul de costuri",
  "results.accountSheet.details.discount": "Reducere de numerar",
  "results.accountSheet.details.externalDocumentNumber": "Document extern nr.",
  "results.accountSheet.details.foreignCurrency": "Monedă străină / curs de schimb",
  "results.accountSheet.details.period": "Perioada",
  "results.accountSheet.details.serialNumber": "Jurnalul nr.",
  "results.accountSheet.details.tax": "Impozit",
  "results.accountSheet.details.taxCode.Ausfuhrlieferungen": "Livrări la export",
  "results.accountSheet.details.taxCode.BauleistungenMitVSt": "Lucrări de construcții cu TVA",
  "results.accountSheet.details.taxCode.BauleistungenOhneVSt": "Lucrări de construcții fără TVA",
  "results.accountSheet.details.taxCode.EUSt": "EUSt",
  "results.accountSheet.details.taxCode.igEMitVSt": "igE cu TVA",
  "results.accountSheet.details.taxCode.igEOhneVst": "igE fără TVA",
  "results.accountSheet.details.taxCode.igLieferung": "livrare ig",
  "results.accountSheet.details.taxCode.Kleinunternehmer": "Afaceri mici",
  "results.accountSheet.details.taxCode.RCMitVSt": "RC cu VSt",
  "results.accountSheet.details.taxCode.RCOhneVSt": "RC fără TVA",
  "results.accountSheet.details.taxCode.sonstigeLeistungenEuZm": "Alte servicii UE (în funcție de ZM)",
  "results.accountSheet.details.taxCode.TeilabzugsfaehigeVSt": "TVA parțial deductibilă",
  "results.accountSheet.details.taxCode.Ust": "TVA",
  "results.accountSheet.details.taxCode.VSt": "VSt",
  "results.accountSheet.details.taxPercent": "Impozit procentual",
  "results.accountSheet.details.vatPeriod": "Perioada UVA",
  "results.accountSheet.documents.downloadAll": "Descărcați toate fișierele",
  "results.accountSheet.filters.amount": "Suma",
  "results.accountSheet.filters.documentDate": "Data documentului",
  "results.accountSheet.filters.postingSymbol": "Simbol de rezervare",
  "results.accountSheet.noResults.message": "Nu au putut fi găsite elemente pentru contul selectat",
  "results.accountSheet.noResults.open.message": "Nu au putut fi găsite elemente deschise pentru contul selectat",
  "results.accountSheet.noResults.open.title": "Nu s-au găsit articole deschise",
  "results.accountSheet.noResults.title": "Nu s-au găsit posturi",
  "results.accountSheet.offsettingAccounts.more": "{count} mai mult",
  "results.accountSheet.paid": "plătit",
  "results.accountSheet.search.count": "Rezervări: <b>{count}</b>",
  "results.accountSheet.search.placeholder": "Căutați textul de rezervare...",
  "results.accountSheet.sum.title": "Σ Total",
  "results.accountSheet.tabs.all": "Toate articolele",
  "results.accountSheet.tabs.open": "Numai articole deschise",
  "results.accountSheet.title": "Fișa contului pentru contul {accountNr} \"{name}\"",
  "results.columns.changeAbsolute": "Abw.",
  "results.columns.changePercentage": "Dev. (%)",
  "results.columns.credit": "Au",
  "results.columns.debit": "Țintă",
  "results.common.accounts": "Conturi",
  "results.common.quickExpand.expandAccounts": "Toate (inclusiv conturi)",
  "results.common.quickExpand.expandNthDepth": "Nivel {profunditate}",
  "results.common.quickExpand.expandRoot": "Nivelul principal de defalcare",
  "results.common.quickExpand.expandRows": "Se îndoaie liniile:",
  "results.disclaimer": "Notă importantă",
  "results.disclaimerMessage": "Pentru perioadele pentru care situațiile financiare anuale nu au fost încă finalizate, analizele prezentate se bazează pe datele din lista curentă a soldurilor la data respectivă de raportare. Analizele au fost pregătite pe baza documentelor care ne-au fost furnizate fără o revizuire materială a acestora și oferă clientului informații suplimentare cu privire la evoluția curentă a activității în timp util. În special, acumulările și reportările, modificările provizioanelor, modificările stocurilor, evaluarea creanțelor și amortizarea au fost luate în considerare numai în exercițiile financiare pentru care situațiile financiare anuale nu au fost încă finalizate, în măsura în care acest lucru a fost convenit cu clientul. Acest lucru duce la diferențe între analizele prezentate și situațiile financiare anuale finale.\n\nPrin urmare, nu ne asumăm nicio răspundere pentru acuratețea și exhaustivitatea analizelor față de client sau față de părți terțe. În toate celelalte privințe, Condițiile generale de contract pentru profesiile contabile publice (AAB) se aplică misiunilor și responsabilităților noastre, inclusiv în relația cu terții.",
  "results.navbar.buttons.accountListing": "Lista de solduri",
  "results.navbar.buttons.balanceSheet": "Bilanț",
  "results.navbar.buttons.cashAccounting": "Contul de venituri și cheltuieli",
  "results.navbar.buttons.customerListing": "Lista debitorilor",
  "results.navbar.buttons.payrollAccount": "Cont de salarii",
  "results.navbar.buttons.profitAndLoss": "G&V",
  "results.navbar.buttons.vendorListing": "Lista conturilor de plătit",
  "results.noFinancialAccountancies.message": "În prezent, nu sunt disponibile evaluări.",
  "results.noFinancialAccountancies.title": "Nu există evaluări",
  "results.payrollAccountSheet.dataTable.month": "lună",
  "results.payrollAccountSheet.dataTable.number": "Nu.",
  "results.payrollAccountSheet.dataTable.payrollType": "Tipul de salariu",
  "results.payrollAccountSheet.dataTable.total": "Total",
  "results.payrollAccountSheet.dataTable.totalPreviousYear": "Total anul precedent",
  "results.payrollAccountSheet.noPermission.message": "Nu sunteți autorizat să vizualizați contul de salarii.",
  "results.payrollAccountSheet.noPermission.title": "Nu există autorizație",
  "results.payrollAccountSheet.noResults.details": "Acest cont de salarii nu conține niciun detaliu",
  "results.payrollAccountSheet.noResultsYear.message": "Nu s-au găsit date pentru anul selectat.",
  "results.payrollAccountSheet.noResultsYear.title": "Nu s-au găsit date",
  "results.settings.accountsVisibility.hideAccounts": "Ascundeți conturile",
  "results.settings.accountsVisibility.showAccounts": "Afișați conturile",
  "results.settings.orderBy.accountNameAsc": "Numele contului în ordine crescătoare",
  "results.settings.orderBy.accountNameDesc": "Numele contului descrescător",
  "results.settings.orderBy.accountNrAsc": "Numărul de cont în ordine crescătoare",
  "results.settings.orderBy.accountNrDesc": "Numărul de cont descrescător",
  "results.settings.orderBy.amountAsc": "Suma ascendentă",
  "results.settings.orderBy.amountDesc": "Suma descrescătoare",
  "results.settings.orderBy.title": "Sortează după: {orderBy}",
  "results.settings.previousYearRanges.fullYear": "Afișați totalul anului precedent",
  "results.settings.previousYearRanges.partialYear": "Luați în considerare anul anterior numai până la {previousYearRangeUntil}.",
  "results.settings.variant.compactView": "Vedere compactă",
  "results.settings.variant.currentAndPastYear": "Noutăți și anul precedent",
  "results.settings.variant.currentAndPastYearAbsoluteChange": "Anul curent și anul precedent cu variație absolută",
  "results.settings.variant.currentAndPastYearPercentageChange": "Anul curent și anul precedent cu variația procentuală",
  "results.settings.variant.currentYear": "Anul curent",
  "results.settings.variant.detailView": "Vedere detaliată",
  "results.title": "Evaluări",
  "role.accountingAssistent": "Asistent contabil",
  "role.accountingAssistent.description": "Accesul la contabilitate fără drepturi de eliberare",
  "role.accountingManagement": "Management financiar",
  "role.accountingManagement.description": "Accesul la contabilitate, inclusiv drepturile de eliberare",
  "role.admin": "Administrare / Management",
  "role.admin.description": "Toate drepturile de acces la toate zonele",
  "role.assistent": "Asistență",
  "role.assistent.description": "Acces la contabilitate și salarizare fără drepturi de eliberare{br}Puteți sugera schimbarea utilizatorilor",
  "role.custom": "Personalizat",
  "role.custom.description": "Drepturi de acces definite de utilizator",
  "role.custom.jobTitle": "Angajați",
  "role.custom.jobTitle.female": "Personal",
  "role.hrAssistent": "Asistent salarizare",
  "role.hrAssistent.description": "Accesul la contabilitatea salarizării fără drepturi de eliberare",
  "role.hrManagement": "Managementul resurselor umane",
  "role.hrManagement.description": "Accesul la contabilitatea salarizării, inclusiv drepturile de eliberare",
  "role.internationalProjects": "Personalul proiectului",
  "role.internationalProjects.female": "Personalul proiectului",
  "role.legalAdvisor": "Consilier juridic:în",
  "role.noRole": "nici o parte",
  "role.noRole.description": "Nu aveți drepturi de acces la TPA Connect",
  "role.staff": "Angajat",
  "role.staff.description": "Primește declarații electronice de venituri",
  "role.staff.female": "Angajat",
  "role.superAdmin": "Superadmin",
  "role.superAdmin.description": "Acces la toate zonele, inclusiv la gestionarea utilizatorilor",
  "role.tpaAccounting": "Contabilitate",
  "role.tpaAccounting.description": "Accesul consultantului la contabilitate",
  "role.tpaAdvisor": "Consilier",
  "role.tpaAdvisor.description": "Accesul consultantului în toate zonele",
  "role.tpaAdvisor.female": "Consilier",
  "role.tpaAnnualAccounts": "Conturile anuale",
  "role.tpaAnnualAccounts.description": "Responsabil pentru conturile anuale",
  "role.tpaAuditor": "Audit",
  "role.tpaBusinessConsultant": "Consultanță în management",
  "role.tpaBusinessConsultant.description": "Responsabil pentru consultanță în management",
  "role.tpaChat": "Contactați",
  "role.tpaChatSecret": "Contactați",
  "role.tpaHr": "Salarizare",
  "role.tpaHr.description": "Accesul consultantului la contabilitatea salarizării",
  "role.tpaOther": "Personalul de proiect",
  "role.tpaOther.female": "Angajat de proiect",
  "role.tpaPartner": "Partener",
  "role.tpaPartner.description": "Accesul consultantului în toate zonele",
  "role.tpaPartner.female": "Partener",
  "role.tpaPayrollAccountant": "Salarizare",
  "role.tpaReporting": "Raportarea",
  "role.tpaReporting.description": "Responsabil de raportare",
  "role.tpaSpecialAdvisor": "Consultație specială",
  "role.tpaSpecialAdvisor.description": "Responsabil cu consilierea specială",
  "role.tpaTaxAccountant": "Consultanță fiscală",
  "role.tpaTaxAccountant.description": "Responsabil pentru consultanță fiscală",
  "screen.accounting.navbar.bankConnections": "Detalii bancare",
  "screen.accounting.navbar.heading": "Contabilitate",
  "screen.accounting.navbar.overview": "Prezentare generală",
  "screen.accounting.navbar.records": "Încasări",
  "screen.accounting.overview.bankConnection": "{salutation}, {br} {br} Unul sau mai multe dintre datele dumneavoastră bancare vor expira în această perioadă contabilă. Vă rugăm să le reînnoiți.{br} {br} Cu deosebită considerație{br} {semnatura}",
  "screen.accounting.overview.bankConnection.expired": "{salutation}, {br} {br} Unul sau mai multe dintre datele dumneavoastră bancare au expirat. Vă rugăm să le reînnoiți.{br} {br} Cu deosebită considerație{br} {semnatura}",
  "screen.accounting.overview.bankConnection.expired.title": "Detalii bancare expirate",
  "screen.accounting.overview.bankConnections.refreshBankConnections": "Reînnoirea conturilor bancare",
  "screen.accounting.overview.bankConnection.title": "Expirarea conturilor bancare",
  "screen.accounting.overview.card.analysis": "Eliberarea rapoartelor pentru",
  "screen.accounting.overview.card.globalAnalysis": "Eliberarea de rapoarte la nivelul întregii companii",
  "screen.accounting.overview.card.overview": "Anunțul contabilizării pentru",
  "screen.accounting.overview.finished.download": "Descărcați toate fișierele",
  "screen.accounting.overview.finished.message": "{salut}, {br} {br} Vă mulțumim pentru verificarea și aprobarea conturilor. Vă rugăm să găsiți mai jos rapoartele dumneavoastră.{br} {br} Cu deosebită considerație, {br}{semnatura}",
  "screen.accounting.overview.reports": "{salutare}, {br} {br} În anexă, vă rugăm să găsiți următoarele documente pentru {perioada}. Documentele au fost întocmite pe baza informațiilor pe care le-ați furnizat și a documentelor pe care le-ați prezentat. Dacă găsiți inexactități sau incompletitudini în aceste documente, vă rugăm să ne anunțați pentru a putea face corecturile necesare. {br} {br} Dacă aveți întrebări, vă rugăm să nu ezitați să ne contactați.{br} {br} Cu deosebită considerație, {br}{semnatura}",
  "screen.accounting.overview.ticketsWelcome.inProgress": "{salut}, {br} {br} Vă mulțumim pentru transmiterea bonurilor. Au apărut unele întrebări pe parcursul contabilității:",
  "screen.accounting.overview.welcome.closed": "{salutare}, {br} {br} Vă mulțumim pentru că ați prezentat toate documentele justificative relevante. Le vom procesa în conformitate cu termenele convenite și vă vom răspunde la orice întrebări.",
  "screen.accounting.overview.welcome.inProgress": "{{br} {br} Vă solicităm să încărcați documentele de rezervare pentru {perioada}{data scadentă}. Vă rugăm să ne trimiteți toate documentele necesare. {br} {br}Cele mai bune salutări, {br}{semnatura}",
  "screen.accounting.records.banner.selectedAll": "Sunt selectate toate fișierele {count} din \"{recordType}\".",
  "screen.accounting.records.banner.selectedAll.transferFailed": "Sunt selectate toate {numărul} fișierelor nereușite din \"{recordType}\".",
  "screen.accounting.records.banner.selectedCount": "Toate fișierele {count} de pe această pagină sunt selectate",
  "screen.accounting.records.banner.totalCount": "Selectați toate fișierele {count} din \"{recordType}\".",
  "screen.accounting.records.banner.totalCount.transferFailed": "Selectați toate {numărul} fișierelor nereușite din \"{recordType}\".",
  "screen.accounting.records.banner.unselect": "Anulare selecție",
  "screen.accounting.records.documents": "Fișiere:",
  "screen.accounting.records.empty": "Fișierul este gol",
  "screen.accounting.records.filter.transferFailed": "Transfer eșuat",
  "screen.accounting.records.format.error": "Verificați formatul",
  "screen.accounting.records.move.dialog.button.text": "Mutarea",
  "screen.accounting.records.move.multiple.dialog.title": "Mutarea documentelor",
  "screen.accounting.records.move.single.dialog.title": "Mutarea documentului",
  "screen.accounting.records.navbar.tab.list": "Încasări ({număr})",
  "screen.accounting.records.navbar.tab.tickets": "Interogări ({count})",
  "screen.accounting.records.person.responsible": "Responsabilitate",
  "screen.accounting.records.serverUpload.error": "Fișierul nu a putut fi încărcat pe server",
  "screen.accounting.records.serverUpload.typeError": "Format neacceptat",
  "screen.accounting.records.sizeAndFormat.error": "Verificați dimensiunea și formatul fișierului",
  "screen.accounting.records.size.error": "Verificați dimensiunea fișierului",
  "screen.accounting.records.tickets": "| Întrebări: {numTickets}",
  "screen.accounting.records.transfer.button.text": "Transmiterea către TPA",
  "screen.accounting.records.transfer.dialog.text": "Doriți să transferați <b>toate fișierele contabile</b> pentru perioada {periodStart} - {periodEnd} la TPA pentru a fi înregistrate?",
  "screen.accounting.records.transfer.dialog.textRecordType": "Doriți să transferați <b>toate dosarele lui \"{nume}\"</b> pentru perioada {periodStart} - {periodEnd} la TPA pentru a fi înregistrate?",
  "screen.accounting.records.transfer.dialog.title": "Transmiterea contabilității",
  "screen.accounting.records.transfer.retryDialog.button.text": "Încearcă din nou.",
  "screen.accounting.records.transfer.retryDialog.file": "Eroare în transmiterea lui {failedFile}",
  "screen.accounting.records.transfer.retryDialog.text": "Oops. Ceva a mers prost aici. Vă rugăm să încercați din nou.",
  "screen.accounting.records.transfer.retryDialog.title": "Transmisia a eșuat",
  "screen.accounting.records.transfer.successDialog.text": "Consilierul dumneavoastră TPA va analiza datele și vă va contacta pentru orice întrebări.",
  "screen.accounting.records.transfer.successDialog.title": "Date transmise cu succes",
  "screen.accounting.records.types.header": "Contabilitate",
  "screen.accounting.records.unknown.error": "Eroare necunoscută",
  "screen.accounting.records.upload.button": "Încărcați chitanțele",
  "screen.accounting.records.upload.navbar.back": "Contabilitate / Bonuri fiscale",
  "screen.accounting.recordTypes.title": "Selectați tipul de cupoane",
  "screen.advisor.tabbar.active": "Activ",
  "screen.advisor.tabbar.notConfigured": "Neconfigurat",
  "screen.advisor.upload.approve.info": "Când am nevoie de o semnătură calificată?",
  "screen.advisor.uploads.upload.approve": "Eliberarea clientului",
  "screen.advisor.uploads.upload.approve.option.click": "Eliberare pe click",
  "screen.advisor.uploads.upload.approve.option.none": "Fără aprobarea clientului",
  "screen.advisor.uploads.upload.approve.option.qes": "Eliberarea prin semnătură electronică",
  "screen.advisor.uploads.upload.buttonText": "Transmiteți",
  "screen.advisor.uploads.upload.category": "Categoria",
  "screen.advisor.uploads.upload.category.validation": "Vă rugăm să selectați o categorie",
  "screen.advisor.uploads.upload.comment": "Textul transmisiei",
  "screen.advisor.uploads.upload.comment.downloadError": "Eroare la încărcarea comentariului",
  "screen.advisor.uploads.upload.dueDate": "Cu scadență la",
  "screen.advisor.uploads.upload.dueDate.validation": "Vă rugăm să introduceți o dată scadentă",
  "screen.advisor.uploads.upload.global": "La nivelul întregii companii",
  "screen.advisor.uploads.upload.module": "Zona",
  "screen.advisor.uploads.upload.module.validation": "Vă rugăm să selectați o zonă",
  "screen.advisor.uploads.upload.navBack": "Încărcături",
  "screen.advisor.uploads.upload.navHeader": "Încărcați fișiere: {categorie}",
  "screen.advisor.uploads.upload.notifyCustomer": "Notificarea clienților",
  "screen.advisor.uploads.upload.subsidiary.validation": "Vă rugăm să selectați un cod de companie",
  "screen.advisor.uploads.upload.visibleToEmployee": "Eliberare pentru MA",
  "screen.auth.forgot_password_confirm.subtitle.section_1": "Dacă am găsit un utilizator cu adresa de e-mail {email}, veți primi în scurt timp un e-mail cu un link pentru a vă reseta parola.",
  "screen.auth.forgot_password_confirm.subtitle.section_2": "Nu ați primit nici o scrisoare? Verificați dosarul de spam sau contactați administratorul de sistem.",
  "screen.auth.forgot_password_confirm.title": "Veți primi un e-mail în scurt timp",
  "screen.auth.forgot_password_email.form.button": "Trimiteți un link pentru resetare",
  "screen.auth.forgot_password_email.form.label.email": "ID-ul utilizatorului / Adresa de e-mail",
  "screen.auth.forgot_password_email.subtitle": "Introduceți adresa dvs. de e-mail și vă vom trimite un e-mail cu un link pentru a vă reseta parola.",
  "screen.auth.forgot_password_email.title": "Resetare parolă",
  "screen.auth.forgot_password_email.validation_error": "Vă rugăm să introduceți adresa dvs. de e-mail",
  "screen.auth.forgot_password_reset.error.password_not_set": "Vă rugăm să introduceți o parolă",
  "screen.auth.forgot_password_reset.error.passwords_do_not_match": "Parolele nu se potrivesc",
  "screen.auth.forgot_password_reset.error.password_weak": "Parola ta este încă prea slabă.",
  "screen.auth.forgot_password_reset.form.button": "Modificați parola",
  "screen.auth.forgot_password_reset.form.button.mfa": "Trimiteți codul",
  "screen.auth.forgot_password_reset.form.button.mfa.resend_code": "Trimiteți din nou codul",
  "screen.auth.forgot_password_reset.form.mfa": "Cod",
  "screen.auth.forgot_password_reset.form.password": "Parola",
  "screen.auth.forgot_password_reset.form.repeat_password": "Repetați parola",
  "screen.auth.forgot_password_reset.subtitle": "Setați o nouă parolă pentru contul TPA Connect.",
  "screen.auth.forgot_password_reset.subtitle.mfa": "Vă rugăm să introduceți codul pe care vi l-am trimis la numărul de telefon prin SMS.",
  "screen.auth.forgot_password_reset.title": "Modificați parola",
  "screen.auth.forgot_password_reset.title.mfa": "Confirmați schimbarea parolei cu autentificarea cu doi factori",
  "screen.auth.registration.form.button": "Înregistrare",
  "screen.auth.registration.form.button.mfa": "Trimiteți codul",
  "screen.auth.registration.form.button.mfa.resendCode": "Trimiteți din nou codul",
  "screen.auth.registration.form.label.mfa": "Cod",
  "screen.auth.registration.form.label.password": "Parola",
  "screen.auth.registration.form.label.repeat_password": "Repetați parola",
  "screen.auth.registration.form.section_1": "Folosiți cel puțin 8 caractere.",
  "screen.auth.registration.form.section_2": "Puteți utiliza majuscule și minuscule, numere și caractere speciale pentru a vă întări parola.",
  "screen.auth.registration.form.section_3": "Gândiți-vă la o metodă mnemotehnică care să vă ajute să vă amintiți parola.",
  "screen.auth.registration.mfa.subtitle": "Vă rugăm să introduceți codul pe care vi l-am trimis la numărul de telefon prin SMS.",
  "screen.auth.registration.subtitle": "Setați o parolă pentru contul TPA Connect.",
  "screen.auth.registration.title": "Setați și înregistrați parola",
  "screen.auth.welcome.subtitle": "Conectați-vă contul TPA Connect la contul Microsoft pentru a beneficia de toate funcțiile. De asemenea, vă puteți conecta contul la o dată ulterioară.",
  "screen.auth.welcome.subtitle.msOnly": "Conectați-vă contul TPA Connect la contul Microsoft pentru a beneficia de toate funcțiile.",
  "screen.auth.welcome.title": "Bine ați venit la TPA Connect!",
  "screen.changeUsername.checkbox": "Prin prezenta confirm noua mea adresă de e-mail",
  "screen.changeUsername.message": "Inima de calcul a aplicației TPA Connect a observat că adresa dvs. de e-mail s-a schimbat în <b>{newUsername}</b>. Pentru ca noi să fim absolut siguri că această modificare este corectă, este esențial să confirmați acest lucru cu un singur clic.",
  "screen.changeUsername.title": "Noua adresă de e-mail",
  "screen.cockpit.allEvents": "Evenimente: Toate evenimentele",
  "screen.cockpit.allNews": "Știri: Toate articolele",
  "screen.cockpit.dueDates.export": "Termene de export",
  "screen.cockpit.dueDates.navbar.heading": "Termene deschise",
  "screen.cockpit.dueDates.projects.text": "Eliberare pentru {documentName}",
  "screen.cockpit.dueDates.records.accounting.text": "Prezentarea informațiilor contabile pentru {subsidiaryName} - {perioada}",
  "screen.cockpit.dueDates.records.hr.text": "Publicarea datelor de salarizare pentru {subsidiaryName} - {perioada}.",
  "screen.cockpit.dueDates.reports.global.text": "Eliberarea raportului pentru \"rapoarte la nivelul întregii societăți\" - {perioada}",
  "screen.cockpit.dueDates.reports.subsidiary.text": "Eliberarea raportului pentru {subsidiaryName} - {perioada}",
  "screen.cockpit.dueDates.reportUpload.bmd.message": "Vă rugăm să utilizați BMD NTCS pentru a furniza rapoarte",
  "screen.cockpit.dueDates.reportUpload.bmd.title": "Încărcarea raportului",
  "screen.cockpit.dueDates.reportUpload.global.text": "Încărcarea rapoartelor pentru \"rapoarte la nivelul întregii companii\" - {perioada}",
  "screen.cockpit.dueDates.reportUpload.subsidiary.text": "Reportupload pentru {subsidiaryName} - {perioada}",
  "screen.cockpit.dueDates.tickets.global.text": "Termen limită pentru consultarea documentului {documentName}",
  "screen.cockpit.dueDates.tickets.subsidiary.text": "Termen limită pentru consultarea documentului {documentName} pentru {subsidiaryName}.",
  "screen.cockpit.emptyState.message": "Reveniți mai târziu.",
  "screen.cockpit.emptyState.title": "Nu există nici o știre în acest moment",
  "screen.cockpit.eventDetail": "Detaliile evenimentului",
  "screen.cockpit.eventDetails.heading": "Detalii și înregistrare",
  "screen.cockpit.eventDetails.lecturer.email": "E: {email}",
  "screen.cockpit.eventDetails.lecturers": "Prezentator",
  "screen.cockpit.eventDetails.lecturer.telephone": "T: {telefon}",
  "screen.cockpit.eventDetails.register": "Înregistrați-vă acum",
  "screen.cockpit.event.moreInformation": "INFORMAȚII ȘI ÎNREGISTRARE",
  "screen.cockpit.kpis.add": "Adăugați cifra cheie",
  "screen.cockpit.kpis.chart.legend": "în € 1000",
  "screen.cockpit.kpis.configurationDialog.insuranceCarrierBalance.label": "Asigurarea de sănătate",
  "screen.cockpit.kpis.configurationDialog.metric.label": "KPI",
  "screen.cockpit.kpis.configurationDialog.months.label": "Luni",
  "screen.cockpit.kpis.configurationDialog.title": "Configurați widgetul KPI",
  "screen.cockpit.kpis.ebit.chart.name": "Evoluția EBIT",
  "screen.cockpit.kpis.ebit.kpi.name": "EBIT până în prezent",
  "screen.cockpit.kpis.ebit.kpi.tooltip": "Afișează valoarea EBIT a tuturor lunilor deja înregistrate în totalitate de la începutul exercițiului financiar.",
  "screen.cockpit.kpis.empty": "Codul companiei dvs. \"{name}\" nu este disponibil în prezent.",
  "screen.cockpit.kpis.insuranceCarrier.balance.kpi.name": "Soldul asigurării de sănătate",
  "screen.cockpit.kpis.noneConfigured": "Adăugați cifra cheie",
  "screen.cockpit.kpis.revenue.chart.name": "Evoluția cifrei de afaceri",
  "screen.cockpit.kpis.revenue.kpi.name": "Cifra de afaceri până în prezent",
  "screen.cockpit.kpis.revenue.kpi.tooltip": "Afișează cifra de afaceri a tuturor lunilor deja rezervate în totalitate de la începutul exercițiului financiar.",
  "screen.cockpit.kpis.save": "Aplicați modificările",
  "screen.cockpit.kpis.svsAccount.kpi.info": "",
  "screen.cockpit.kpis.svsAccount.kpi.label": "",
  "screen.cockpit.kpis.svsAccount.kpi.name": "",
  "screen.cockpit.kpis.taxAccount.kpi.arrears": "Lista de așteptare",
  "screen.cockpit.kpis.taxAccount.kpi.credit": "Notă de credit",
  "screen.cockpit.kpis.taxAccount.kpi.label": "Situația la {data}",
  "screen.cockpit.kpis.taxAccount.kpi.lastCompletedPayment": "[suma] plătită ultima dată la {data}",
  "screen.cockpit.kpis.taxAccount.kpi.name": "Soldul contului de impozit",
  "screen.cockpit.kpis.unpaidAccount.kpi.lastCompletedPayment": "Deja plătit la {data}",
  "screen.cockpit.kpis.unpaidAccounts.kpi.caption": "{invoicesCount} Facturi",
  "screen.cockpit.kpis.unpaidAccounts.kpi.label": "Situația de la {data}",
  "screen.cockpit.kpis.unpaidAccounts.kpi.name": "Facturi restante la TPA",
  "screen.cockpit.navbar.heading": "Cabină de pilotaj",
  "screen.cockpit.overview.kpis.title": "Date și cifre",
  "screen.cockpit.overview.newestPublications": "Publicații recente",
  "screen.cockpit.overview.notifications.dueDates": "Termene deschise",
  "screen.cockpit.overview.notifications.messages": "Știri",
  "screen.cockpit.overview.notifications.tickets": "Întrebări",
  "screen.cockpit.overview.notifications.title": "Notificări",
  "screen.cockpit.overview.promotions.title": "Știri",
  "screen.cockpit.overview.relevantNews.title": "Știri relevante",
  "screen.cockpit.overview.relevantPublications.description": "Puteți solicita fie o publicație tipărită a TPA, care vă va fi trimisă în câteva zile. Sau: Puteți descărca o versiune electronică (PDF) a publicației TPA dorite.",
  "screen.cockpit.overview.relevantPublications.title": "Publicații relevante",
  "screen.cockpit.promotion": "Știri: Articol",
  "screen.cockpit.promotion.additionalNews": "Știri",
  "screen.cockpit.relevantNews": "Știri: Articolul",
  "screen.cockpit.relevantNews.additionalNews": "Mai multe știri",
  "screen.cockpit.tickets.navbar.heading": "Interogări deschise",
  "screen.hr.additionaldata.addBonus": "Adăugați încă o alocație",
  "screen.hr.additionaldata.additionaldata": "Informații suplimentare",
  "screen.hr.additionaldata.additionalInformation": "Informații suplimentare",
  "screen.hr.additionaldata.amount": "Suma în {valută} pe lună",
  "screen.hr.additionaldata.amount_error": "Vă rugăm să introduceți o sumă",
  "screen.hr.additionaldata.bankDetails": "Detalii bancare",
  "screen.hr.additionaldata.bic": "BIC",
  "screen.hr.additionaldata.bic.validation": "BIC nevalabil",
  "screen.hr.additionaldata.commuterAllowance": "Indemnizație de navetă",
  "screen.hr.additionaldata.costCenter": "Centrul de costuri",
  "screen.hr.additionaldata.costCenterNote": "Notă privind centrul de cost",
  "screen.hr.additionaldata.description": "Următoarele informații pot fi completate, de asemenea, la începerea serviciului, dacă este cazul.",
  "screen.hr.additionaldata.designator": "Alocație",
  "screen.hr.additionaldata.designator_error": "Vă rugăm să specificați o alocație",
  "screen.hr.additionaldata.emailAddress": "Adresa de e-mail",
  "screen.hr.additionaldata.email_error": "Vă rugăm să introduceți o adresă de e-mail corectă",
  "screen.hr.additionaldata.familyBonusPlusAmount": "Fabo Plus Suma în EUR",
  "screen.hr.additionaldata.familyBonusPlusAmount_error": "Vă rugăm să introduceți cel puțin 0",
  "screen.hr.additionaldata.funder": "Unitatea de cost",
  "screen.hr.additionaldata.gross": "Brut",
  "screen.hr.additionaldata.hours_error": "Vă rugăm să introduceți un număr între {minHours} și {maxHours}.",
  "screen.hr.additionaldata.iban": "IBAN",
  "screen.hr.additionaldata.iban_error": "IBAN nevalabil",
  "screen.hr.additionaldata.jobDescription": "Titlul postului",
  "screen.hr.additionaldata.kilometersForCommuterEuro": "Kilometri pentru euro pentru navetiști",
  "screen.hr.additionaldata.kilometersForCommuterEuro_error": "Vă rugăm să introduceți cel puțin 0",
  "screen.hr.additionaldata.kilometersForCommuterEuro_typeerror": "Kilometrii pot fi dați numai în numere întregi",
  "screen.hr.additionaldata.maritalStatus": "Starea civilă",
  "screen.hr.additionaldata.monthlySalary": "Salariu în {monedă} pe lună",
  "screen.hr.additionaldata.monthlySalary_error": "Vă rugăm să introduceți cel puțin 0",
  "screen.hr.additionaldata.net": "Net",
  "screen.hr.additionaldata.nextPage": "Continuați la Documente",
  "screen.hr.additionaldata.number_error": "Vă rugăm să introduceți un număr",
  "screen.hr.additionaldata.per": "pe",
  "screen.hr.additionaldata.personaldata": "Detalii personale",
  "screen.hr.additionaldata.religiousConfession": "Mărturisirea religioasă",
  "screen.hr.additionaldata.salarayAndPosition": "Remunerare și funcție",
  "screen.hr.additionaldata.saveChanges": "Salvați modificările",
  "screen.hr.additionaldata.soleEarnerStatus": "Unic salariat",
  "screen.hr.additionaldata.titelAndAcademicDegrees": "Titluri și grade academice",
  "screen.hr.additionaldata.titlePrefix": "Prefixat",
  "screen.hr.additionaldata.titleSuffix": "Sub",
  "screen.hr.additionaldata.wageAgreementClassification": "Contractul colectiv de clasificare",
  "screen.hr.additionaldata.weeklyWorkDays": "Numărul de zile lucrătoare pe săptămână",
  "screen.hr.additionaldata.weeklyWorkDays_error": "Vă rugăm să selectați o valoare validă",
  "screen.hr.additionaldata.workingHours": "Număr de ore",
  "screen.hr.additionaldata.workingHoursInterval": "Perioada",
  "screen.hr.additionaldata.workingTimes": "Ore de lucru",
  "screen.hr.cancelRegistration.dataSaved": "Dacă anulați, datele introduse vor rămâne salvate. Înregistrarea poate fi continuată ulterior.",
  "screen.hr.cancelRegistration.header": "Anulează înregistrarea",
  "screen.hr.cancelRegistration.question1": "Sunteți sigur că doriți să anulați procesul de înregistrare? Puteți utiliza datele pe care le-ați introdus până acum pentru a",
  "screen.hr.cancelRegistration.question2": " și trimiteți-o consilierului fiscal TPA pentru înregistrare.",
  "screen.hr.deregistration.data.navback": "Personal / Angajați / Înregistrat / {firstname} {lastname}",
  "screen.hr.deregistration.documents.navback": "Personal / Angajați / Înregistrat / {prenume} {prenume de familie} / Date de dezînregistrare",
  "screen.hr.deregistration.navheader": "{prenume} {denume} deconectează-te",
  "screen.hr.deregistration.validation.deregistrationDate": "Vă rugăm să introduceți o dată de ieșire",
  "screen.hr.deregistration.validation.deregistrationDateMin": "Data de ieșire trebuie să fie mai mare decât data de intrare.",
  "screen.hr.deregistration.validation.deregistrationReason": "Vă rugăm să precizați motivul plecării",
  "screen.hr.deregistration.validation.remainingHolidays": "Vă rugăm să indicați concediul rămas",
  "screen.hr.deregistration.validation.remainingOvertime": "Vă rugăm să indicați orele suplimentare deschise",
  "screen.hr.documents.citizenship": "Certificat de cetățenie",
  "screen.hr.documents.contract": "Serviciucontract",
  "screen.hr.documents.description": "Încărcați documente suplimentare, cum ar fi: contract de muncă, carte de identitate, dovadă de cetățenie, formular de înregistrare sau alte documente.",
  "screen.hr.documents.emptystate.buttonlabel": "Încărcați documente",
  "screen.hr.documents.emptystate.message": "Nu există documente disponibile pentru acest angajat.",
  "screen.hr.documents.emptystate.title": "Nu există documente disponibile",
  "screen.hr.documents.enterManually": "Introducerea manuală a datelor",
  "screen.hr.documents.idcard": "Carte de identitate personalăpersonală",
  "screen.hr.documents.logTime": "Timpuri record",
  "screen.hr.documents.needsRelease.notReleased": "Așteptând eliberarea",
  "screen.hr.documents.needsRelease.released": "eliberat",
  "screen.hr.documents.needsRelease.released.tooltip": "lansat pe {releasedAt} de către {name}",
  "screen.hr.documents.nextButton": "La rezumat",
  "screen.hr.documents.nonworkingtimes.emptystate.message": "Începeți să încărcați fișierele acum sau introduceți manual timpii de neexecutare.",
  "screen.hr.documents.others": "Altele",
  "screen.hr.documents.registrationslip": "Mesajfoaie",
  "screen.hr.documents.uploadDocuments": "Încărcați documente",
  "screen.hr.edit.flow.navback": "Personal / Angajați / TODO: Stare / {firstName} {lastName}",
  "screen.hr.edit.flow.navheader": "{firstName} {lastName} edita",
  "screen.hr.employee.current.all": "Toate",
  "screen.hr.employee.current.anyDateOfJoining": "Orice dată de intrare",
  "screen.hr.employee.current.anyDateOfJoiningExiting": "Orice dată de intrare/ieșire",
  "screen.hr.employee.current.cancellation": "Radieri",
  "screen.hr.employee.current.changeUsername": "E-mail nou",
  "screen.hr.employee.current.changeUsername.successDialog.message": "<b>{userName}</b> primește o cerere de confirmare a adresei de e-mail nou furnizate.",
  "screen.hr.employee.current.changeUsername.successDialog.title": "E-mailul pentru schimbarea adresei de e-mail a fost trimis cu succes.",
  "screen.hr.employee.current.changeUsername.tooltip": "Noua adresă de e-mail a angajatului este {newUsername}. Făcând clic pe buton, angajatul este rugat să confirme noua adresă de e-mail.",
  "screen.hr.employee.current.dataChange": "Modificări ale datelor de bază",
  "screen.hr.employee.current.notTransferred": "Nu se transmite",
  "screen.hr.employee.current.registration": "Înregistrări",
  "screen.hr.employee.deregistration.data.date": "Data ieșirii",
  "screen.hr.employee.deregistration.data.header": "Date de deconectare",
  "screen.hr.employee.deregistration.data.reason": "Motivul plecării",
  "screen.hr.employee.deregistration.data.remainingHolidays": "Concediu rămas în zile",
  "screen.hr.employee.deregistration.data.remainingOvertime": "Ore suplimentare deschise",
  "screen.hr.employee.deregistration.data.subtitle": "În cazul în care acestea sunt disponibile, încărcați documentele de ieșire pentru decontarea finală.",
  "screen.hr.employee.deregistration.documents.submitButton": "Transmiterea dezînregistrării",
  "screen.hr.employee.deregistration.stepper.data": "Date de deconectare",
  "screen.hr.employee.deregistration.stepper.documents": "Documente de ieșire",
  "screen.hr.employee.details.addFiles": "Adăugați documente",
  "screen.hr.employee.details.currentEmployment": "Raportul de muncă actual",
  "screen.hr.employee.details.deregisterEmployee": "Anularea înregistrării angajatului",
  "screen.hr.employee.details.employments": "Relații de muncă",
  "screen.hr.employee.details.fileProvidedBy": "furnizat de {name}",
  "screen.hr.employee.details.fileReleased": "Vizibil pentru angajați",
  "screen.hr.employee.details.linkedFiles": "Fișiere legate",
  "screen.hr.employee.details.navBack": "Personal / {list}",
  "screen.hr.employee.details.pastEmployments": "Angajarea anterioară",
  "screen.hr.employee.details.payrollAccount.navBack": "Personal / DN curent / {fullName}",
  "screen.hr.employee.details.personnelFile": "Dosar personal",
  "screen.hr.employee.details.personnelNumber": "Numărul personal:",
  "screen.hr.employee.details.records": "Dovada veniturilor",
  "screen.hr.employee.details.reregisterEmployee": "Reînregistrarea angajatului",
  "screen.hr.employee.details.showAll": "Arată tot",
  "screen.hr.employee.details.showEmployeePayrollAccount": "Afișați contul de salarii",
  "screen.hr.employee.details.status": "Stare",
  "screen.hr.employee.details.weeklyWorkDays": "{weeklyWorkDays} zile pe săptămână",
  "screen.hr.employee.details.workingHours": "{workingHours} ore pe {workingHoursInterval};",
  "screen.hr.employee.documents.all": "Toate",
  "screen.hr.employee.documents.annualPayslips": "Fișa anuală de salariu",
  "screen.hr.employee.documents.anyDate": "Orice perioadă",
  "screen.hr.employee.documents.backTargetDescription": "Personal / Angajați / {status} / {firstName} {lastName}",
  "screen.hr.employee.documents.certificateOfCitizenship": "Dovada cetățeniei",
  "screen.hr.employee.documents.certificatesOfRegistration": "Confirmări de înregistrare",
  "screen.hr.employee.documents.contracts": "Contract de servicii",
  "screen.hr.employee.documents.deregisterDocument": "Documente de radiere",
  "screen.hr.employee.documents.employeeDocuments": "Fișiere de la {firstName} {lastName}",
  "screen.hr.employee.documents.employmentPermit": "Permis de muncă",
  "screen.hr.employee.documents.generalDocuments": "Altele",
  "screen.hr.employee.documents.identityCard": "Carte de identitate",
  "screen.hr.employee.documents.payslips": "Dovada veniturilor",
  "screen.hr.employee.emptystate.buttonlabel": "Înregistrați angajații",
  "screen.hr.employee.emptystate.message": "Începeți acum înregistrarea noilor angajați",
  "screen.hr.employee.emptystate.title": "Înregistrarea angajaților",
  "screen.hr.employee.former.anyDateOfLeaving": "Orice dată de ieșire",
  "screen.hr.employee.registration.stepper.additional": "Informații suplimentare",
  "screen.hr.employee.registration.stepper.documents": "Încărcați documente",
  "screen.hr.employee.registration.stepper.personal": "Date cu caracter personal",
  "screen.hr.employee.registration.stepper.status": "Etapa {activeStep} din {totalEtape}",
  "screen.hr.employee.registration.stepper.summary": "Rezumat",
  "screen.hr.employees.current.registerEmployee": "Înregistrare angajat",
  "screen.hr.employees.current.uploadPayslip": "Încărcați fluturașul de salariu",
  "screen.hr.employees.documents.recordType.title": "Fișiere încărcate în fișierul personal în {data}",
  "screen.hr.employees.documents.title": "Fișiere în dosarul personal",
  "screen.hr.navbar.documents": "Fișiere",
  "screen.hr.navbar.employees": "Angajați",
  "screen.hr.navbar.heading": "Personal",
  "screen.hr.navbar.overview": "Prezentare generală",
  "screen.hr.navbar.tabbar.current": "Curent DN",
  "screen.hr.navbar.tabbar.documents": "Fișiere",
  "screen.hr.navbar.tabbar.employees": "Angajați",
  "screen.hr.navbar.tabbar.former": "Pensionat DN",
  "screen.hr.navbar.tabbar.inprogress": "În curs de desfășurare",
  "screen.hr.navbar.tabbar.preRegistartionTickets": "Cereri de informații ({preRegistrationTicketCount})",
  "screen.hr.overview.card.analysis": "Eliberarea evaluărilor lunare pentru",
  "screen.hr.overview.card.billing": "Eliberarea declarațiilor lunare pentru",
  "screen.hr.overview.card.globalAnalysis": "Publicarea evaluărilor la nivelul întregii companii",
  "screen.hr.overview.card.globalBilling": "Eliberarea deconturilor la nivelul întregii companii",
  "screen.hr.overview.card.overview": "Publicarea datelor lunare privind personalul pentru",
  "screen.hr.overview.card.registration": "Înregistrarea/înregistrarea angajaților",
  "screen.hr.overview.finished.message": "{salut}, {br} {br} Vă mulțumim pentru verificarea și aprobarea salariilor. Vă rugăm să găsiți mai jos rapoartele dumneavoastră.{br} {br} Cu deosebită considerație, {br}{semnatura}",
  "screen.hr.overview.registration": "{salutare}, {br} {br} vă rugăm să ne anunțați despre orice modificare a utilizatorilor serviciilor, astfel încât să putem efectua înregistrarea și radierea.{br} {br} Cu stimă,{br} Cu stimă,{br} {semnatura}.",
  "screen.hr.overview.registration.button": "Înregistrarea/dezînregistrarea angajaților",
  "screen.hr.overview.ticketsWelcome.inProgress": "{salutation}, {br} {br}Vă mulțumim pentru transmiterea datelor cu caracter personal. În cursul prelucrării au apărut unele întrebări:",
  "screen.hr.overview.welcome.closed": "{salutation}, {br} {br} Vă mulțumim pentru că ați transmis toate datele relevante privind personalul. Le vom procesa în conformitate cu termenele convenite și vă vom contacta în cazul în care aveți întrebări.",
  "screen.hr.overview.welcome.inProgress": "{salariu}, {br} {br} Vă solicit să îmi furnizați toate detaliile necesare privind personalul pentru {perioada}{data scadentă}. {br}{br}Cu sinceritate,{br}{semnatura}",
  "screen.hr.personaldata.address": "Strada și numărul casei",
  "screen.hr.personaldata.addressAddition": "Sufixul adresei",
  "screen.hr.personaldata.addressHead": "Adresa",
  "screen.hr.personaldata.area.validation": "Vă rugăm să specificați o locație",
  "screen.hr.personaldata.birthdate.validation": "Vă rugăm să introduceți o dată de naștere",
  "screen.hr.personaldata.citizenship": "Cetățenie",
  "screen.hr.personaldata.citizenship.validation": "Vă rugăm să indicați o cetățenie",
  "screen.hr.personaldata.city": "Locație",
  "screen.hr.personaldata.country": "Țara",
  "screen.hr.personaldata.country.validation": "Vă rugăm să selectați o țară",
  "screen.hr.personaldata.dateOfBirth": "Data nașterii",
  "screen.hr.personaldata.dateOfJoining": "Data intrării",
  "screen.hr.personaldata.description": "Următoarele informații sunt obligatorii pentru înregistrare înainte de începerea serviciului.",
  "screen.hr.personaldata.diverse": "Divers",
  "screen.hr.personaldata.employmentInsuranceType": "Grupul de asigurări",
  "screen.hr.personaldata.employmentRelationship": "Relația de muncă",
  "screen.hr.personaldata.employmenttypeheader": "Tipul de angajare",
  "screen.hr.personaldata.employmenttypeinformationbody": "Toate veniturile lunare brute care depășesc pragul de venituri mici sunt considerate ca fiind asigurate în mod obligatoriu în totalitate.",
  "screen.hr.personaldata.employmenttypeinformationhead": "Asigurare completă / asigurare marginală:",
  "screen.hr.personaldata.employmenttype.validation": "Vă rugăm să selectați un raport de muncă",
  "screen.hr.personaldata.entrydate.invalid": "Data de intrare invalidă",
  "screen.hr.personaldata.entrydate.validation": "Vă rugăm să introduceți o dată de intrare",
  "screen.hr.personaldata.female": "Femeie",
  "screen.hr.personaldata.firstname": "Prenume",
  "screen.hr.personaldata.firstname.validation": "Vă rugăm să introduceți un prenume",
  "screen.hr.personaldata.fullTime": "Complet asigurat",
  "screen.hr.personaldata.houseNumber": "Numărul casei",
  "screen.hr.personaldata.houseNumber.validation": "Vă rugăm să introduceți un număr de casă",
  "screen.hr.personaldata.insurancedata": "Date de asigurare",
  "screen.hr.personaldata.insurancedatainformationbody": "În cazul în care nu există un număr de asigurare socială, vă rugăm să lăsați câmpul \"National Insurance Number\" gol.",
  "screen.hr.personaldata.insurancedatainformationhead": "Sfat:",
  "screen.hr.personaldata.insurancetype.validation": "Vă rugăm să selectați o asigurare",
  "screen.hr.personaldata.lastname": "Numele de familie",
  "screen.hr.personaldata.lastname.validation": "Vă rugăm să introduceți un nume de familie",
  "screen.hr.personaldata.male": "Masculin",
  "screen.hr.personaldata.marginally": "Minor",
  "screen.hr.personaldata.navback": "Personal / Angajați",
  "screen.hr.personaldata.navheader": "Înregistrarea angajaților",
  "screen.hr.personaldata.nextpage": "În urma informațiilor suplimentare",
  "screen.hr.personaldata.notSpecified": "Nespecificat",
  "screen.hr.personaldata.personaldata": "Date cu caracter personal",
  "screen.hr.personaldata.personalinformation": "Detalii personale",
  "screen.hr.personaldata.previouslyEmployed": "Această persoană a fost vreodată angajată de dumneavoastră în ultimele 12 luni?",
  "screen.hr.personaldata.socialsecuritynumber.validation": "Vă rugăm să furnizați un număr de asigurare națională",
  "screen.hr.personaldata.ssn": "Numărul de asigurare națională",
  "screen.hr.personaldata.state.validation": "Vă rugăm să introduceți un stat federal",
  "screen.hr.personaldata.streetnr.validation": "Vă rugăm să introduceți o adresă",
  "screen.hr.personaldata.workplaceState": "Starea locului de muncă",
  "screen.hr.personaldata.zipCode": "COD POȘTAL",
  "screen.hr.personaldata.zipcode.length.validation": "",
  "screen.hr.personaldata.zipcode.validation": "Vă rugăm să introduceți un cod poștal",
  "screen.hr.records.move.multiple.dialog.title": "Mutarea fișierelor",
  "screen.hr.records.move.single.dialog.title": "Mutarea fișierului",
  "screen.hr.records.navbar.tab.list": "Fișiere ({count})",
  "screen.hr.records.navbar.tab.tickets": "Interogări ({count})",
  "screen.hr.records.transfer.dialog.text": "Doriți să transferați <b>toate fișierele și informațiile de salarizare</b> pentru perioada {periodStart} - {periodEnd} către TPA pentru a fi înregistrate?",
  "screen.hr.records.transfer.dialog.textRecordType": "Doriți să transferați <b>toate fișierele și informațiile de la \"{nume}\"</b> pentru perioada {periodStart} - {periodEnd} la TPA pentru a fi postate?",
  "screen.hr.records.transfer.dialog.title": "Transmiterea fișierelor",
  "screen.hr.records.types.header": "Personal",
  "screen.hr.records.upload.button": "Încărcați fișiere",
  "screen.hr.records.upload.navbar.back": "Personal / Dosare",
  "screen.hr.recordTypes.title": "Selectați tipul de documente",
  "screen.hr.registration.flow.navback": "Pasul înapoi",
  "screen.hr.registration.flow.navheader": "{firstName} {lastName} log in",
  "screen.hr.staff.payrollAccount.navBack": "",
  "screen.hr.summary.additionalinformation": "Informații suplimentare",
  "screen.hr.summary.bonuses": "Alocații",
  "screen.hr.summary.changeData": "Adăugați la listă",
  "screen.hr.summary.completeAdditionalData": "Ați furnizat toate informațiile suplimentare.",
  "screen.hr.summary.completePersonalData": "Ați introdus toate informațiile obligatorii pentru înregistrarea la fondul regional de asigurări de sănătate.",
  "screen.hr.summary.day": "Ziua",
  "screen.hr.summary.daysPerWeek": "Zile pe săptămână",
  "screen.hr.summary.description": "Verificați dacă informațiile furnizate sunt corecte pentru a le transmite către TPA în etapa următoare.",
  "screen.hr.summary.dialogQuestion1": "Vrei să",
  "screen.hr.summary.dialogQuestion2": " și să o trimiteți consilierului dumneavoastră fiscal TPA pentru înregistrare?",
  "screen.hr.summary.documents": "Fișiere",
  "screen.hr.summary.gender": "Sex",
  "screen.hr.summary.grossSalary": "Salariul brut în {valută}",
  "screen.hr.summary.hours": "Ore",
  "screen.hr.summary.incompleteAdditionalData": "Nu ați introdus încă toate informațiile suplimentare. Vă rugăm să rețineți că unele dintre datele suplimentare pot fi solicitate până la prima plată a salariului. Le puteți adăuga în orice moment.",
  "screen.hr.summary.month": "Luna",
  "screen.hr.summary.name": "{firstName} {lastName}",
  "screen.hr.summary.netSalary": "Salariul net în {valută}",
  "screen.hr.summary.noPreregistration": "Nu au fost modificate date de bază. Nu este necesară transmiterea datelor.",
  "screen.hr.summary.openDialog": "Trimiteți cererea",
  "screen.hr.summary.summary": "Rezumat",
  "screen.hr.summary.transferDataChange": "Transmiterea modificării datelor de bază",
  "screen.hr.summary.transferEmployeeDialog.success.subtitle": " a fost creată și transmisă consilierului fiscal al APT pentru înregistrare.",
  "screen.hr.summary.transferEmployeeDialog.success.subtitle.elda": "{name} a fost creat. Puteți vizualiza și descărca protocolul ELDA mai jos. Alternativ, protocolul poate fi descărcat din dosarul personal al angajatului.",
  "screen.hr.summary.week": "Săptămâna",
  "screen.login.button.forgot_password": "AȚI UITAT PAROLA?",
  "screen.login.button.resendCode": "Trimiteți din nou codul",
  "screen.login.form.divider": "sau",
  "screen.login.form.multiFactorAuth.label": "Cod",
  "screen.login.form.multiFactorAuth.validation_error": "Vă rugăm să introduceți codul.",
  "screen.login.form.password.label": "Parola",
  "screen.login.form.password.validation_error": "Vă rugăm să introduceți o parolă",
  "screen.login.form.submit": "Autentificare",
  "screen.login.form.title": "Autentificare",
  "screen.login.form.username.label": "ID-ul utilizatorului / Adresa de e-mail",
  "screen.login.form.username.validation_error": "Vă rugăm să introduceți ID-ul dvs. de utilizator",
  "screen.login.multiFactorAuth": "Vă rugăm să introduceți codul pe care vi l-am trimis la numărul dvs. de telefon prin SMS.",
  "screen.login.welcome": "Bine ați venit pe site-ul nostru! Vă rugăm să introduceți adresa dvs. de e-mail.",
  "screen.module.overview.report.empty.message": "Nu au fost încărcate încă rapoarte",
  "screen.module.overview.report.empty.title": "Nu există rapoarte disponibile",
  "screen.module.overview.report.upload": "Încărcați raportul",
  "screen.moduleReportUpload.comment": "Textul transmisiei",
  "screen.moduleReportUpload.title": "Încărcați rapoarte",
  "screen.not_found.button.text": "Lansarea din cabina de pilotaj",
  "screen.not_found.header": "Eroare 404",
  "screen.not_found.message": "Ne pricepem foarte bine la cifre, dar am vrut să vă scutim de asta.",
  "screen.overview.salutation.female": "Stimată doamnă {nume de familie}",
  "screen.overview.salutation.general": "Stimate doamne și domnișoare",
  "screen.overview.salutation.male": "Stimate domnule {nume de familie}",
  "screen.overview.salutation.notSpecified": "Bună ziua {prenume} {nume de familie}",
  "screen.records.upload.header": "Încărcare fișier {recordType}",
  "screen.records.upload.message": "Acum începeți să încărcați primele fișiere.",
  "screen.records.upload.title": "Încărcați {recordType}",
  "screen.server_error.button.text": "În cabina de pilotaj",
  "screen.server_error.header": "505 Eroare",
  "screen.server_error.message": "Ne pricepem foarte bine la cifre, dar am vrut să vă scutim de asta.",
  "screen.termsNotAccepted.button": "Înapoi la prezentarea generală a companiei",
  "screen.termsNotAccepted.buttonAcceptNow": "Acceptați acum",
  "screen.termsNotAccepted.header": "Acceptarea condițiilor de utilizare în așteptare",
  "screen.termsNotAccepted.message": "Termenii de utilizare ai companiei dumneavoastră nu au fost încă semnate de toate părțile responsabile. Dacă aveți întrebări, vă rugăm să contactați consilierul dumneavoastră TPA.",
  "screen.termsOfUse.accept": "Semnează",
  "screen.termsOfUse.alreadySignedInfo": "Condițiile de utilizare au fost deja acceptate sau acceptarea nu mai este necesară",
  "screen.termsOfUse.backToLogin": "Înapoi la autentificare",
  "screen.termsOfUse.checkbox": "Prin prezenta accept termenii de utilizare",
  "screen.termsOfUse.success.sign": "Termeni de utilizare acceptați cu succes",
  "screen.termsOfUse.title": "Acceptați termenii de utilizare",
  "screen.termsOfUseWithCompanyNames.title": "Acceptați termenii de utilizare pentru [număr] companii",
  "screen.termsOfUseWithCompanyName.title": "Accept termenii de utilizare pentru {companyName}",
  "screen.tickets.navbar.tabbar.closed": "Realizat",
  "screen.tickets.navbar.tabbar.open": "Deschideți",
  "screen.tickets.navbar.tabbar.reported": "Furnizat de mine",
  "screen.upgradeToMicrosoft.error.azureUserAlreadyExists": "Acest utilizator Microsoft este deja înregistrat cu adresa de e-mail {existingUsername}",
  "screen.upgradeToMicrosoft.feature.projects.text": "Vă rugăm să vă actualizați contul pentru a avea acces la funcția \"Proiecte\".",
  "screen.upgradeToMicrosoft.form.code.description": "Vă rugăm să introduceți codul pe care vi l-am trimis prin SMS pentru a continua cu actualizarea Microsoft.",
  "screen.upgradeToMicrosoft.form.code.label": "Cod",
  "screen.upgradeToMicrosoft.form.password.label": "Parola",
  "screen.upgradeToMicrosoft.form.password.validation_error": "Vă rugăm să introduceți parola dvs.",
  "screen.upgradeToMicrosoft.needCompany": "Legătura cu un cont MS se poate face numai atunci când este selectată o societate.",
  "screen.upgradeToMicrosoft.resendButton.label": "Trimiteți din nou codul",
  "screen.upgradeToMicrosoft.title": "Conectați contul Microsoft",
  "search.caption.numCompanies": "Compania: <b>{count}</b>",
  "search.caption.numDespositedPersons": "Persoane depuse: <b>{număr}</b>",
  "search.caption.numDocuments": "Fișiere: <b>{count}</b>",
  "search.caption.numPersons": "Persoane: <b>{count}</b>",
  "search.caption.numProjects": "Proiecte: <b>{număr}</b>",
  "search.caption.numResults": "Rezultate: <b>{count}</b>",
  "search.caption.numSelected": "Selecție de intrări: <b>{count}</b>",
  "search.caption.numTickets": "Întrebări: <b>{count}</b>",
  "search.caption.numUploads": "Încărcări: <b>{count}</b>",
  "search.caption.numUsers": "Utilizator: <b>{count}</b>",
  "search.placeholder.companies": "Companiile sunt în căutarea de ...",
  "search.placeholder.projects": "căutați un proiect ...",
  "search.placeholder.search": "Căutare ...",
  "search.placeholder.searchForActiveUsers": "Căutați utilizatorii activi după nume...",
  "search.placeholder.searchForEmployees": "căutare de nume...",
  "search.placeholder.searchForEmployeesPersonalNumber": "căutați nume sau numere de personal...",
  "search.placeholder.searchForFileName": "Căutați numele fișierului...",
  "search.placeholder.searchForFiles": "Căutați nume de fișiere...",
  "search.placeholder.searchForUsers": "căutare de nume...",
  "search.placeholder.searchForUsersFileName": "Căutați după nume sau după numele fișierului...",
  "selectQesPosition.message": "Selectați o poziție pentru semnătură și apoi faceți clic pe {button}.",
  "selectQesPosition.message.collective": "Pentru fiecare utilizator, selectați o poziție pentru semnătura acestuia și apoi faceți clic pe Next (Următorul).",
  "selectQesPosition.title": "Selectați poziția semnăturii",
  "settings.navbar.company": "Compania",
  "settings.navbar.companyData": "Date de bază",
  "settings.navbar.features": "Suplimente",
  "settings.navbar.general": "General",
  "settings.navbar.optionalSideBarItems": "Elemente de meniu opționale",
  "settings.navbar.userManagement": "Administrarea utilizatorilor",
  "settings.notifications.activateAll": "ACTIVAȚI TOATE",
  "settings.notifications.assignments": "Alocări",
  "settings.notifications.deactivateAll": "DEZACTIVARE TOATE",
  "settings.notifications.deadlineExceeded": "Termen limită depășit",
  "settings.notifications.deadlineReminder": "Reamintim termenul limită",
  "settings.notifications.generalDocumentUpload": "Încărcați fișiere generale",
  "settings.notifications.label": "Notificări prin e-mail atunci când:",
  "settings.notifications.messages": "Știri",
  "settings.notifications.modules.accounting": "Contabilitate",
  "settings.notifications.modules.hr": "Personal",
  "settings.notifications.modules.other": "Alte domenii",
  "settings.notifications.project.items.uploaded": "Încărcări de proiecte",
  "settings.notifications.recordProvision": "Încărcări ale clientului",
  "settings.notifications.release": "Eliberări",
  "settings.notifications.remindAfterHours.label": "Memorie după {ore}h",
  "settings.notifications.reportProvision": "Furnizarea raportului",
  "settings.notifications.tickets": "Întrebări",
  "settings.notifications.title": "Notificări",
  "settings.personalData.email": "Adresa de e-mail",
  "settings.personalData.language": "Limba",
  "settings.personalData.location": "Locație",
  "settings.personalData.microsoftAccount": "Contul Microsoft",
  "settings.personalData.microsoftUpgrade.info": "Contul Microsoft:{br}{br}Puteți să vă conectați contul TPA Connect la contul Microsoft. Acest lucru vă permite să utilizați toate caracteristicile TPA Connect.{br}{br}După ce v-ați conectat contul la Microsoft, va trebui să vă conectați folosind contul Microsoft în loc de adresa de e-mail.",
  "settings.personalData.microsoftUpgrade.label": "UPGRADE",
  "settings.personalData.phoneNumber": "Număr de telefon",
  "settings.personalData.role": "Rolă",
  "settings.personalData.title": "Date cu caracter personal",
  "settings.personalData.username": "Nume utilizator",
  "settings.userManagement.permissions.existingUser.navbar.back": "Administrare utilizatori / Atribuire rol",
  "settings.userManagement.permissions.existingUser.navbar.backToUser": "Administrare utilizatori / Atribuire rol / {employeeName}",
  "settings.userManagement.permissions.navbar.back": "Administrarea utilizatorilor / Selectarea persoanei de contact / Atribuirea rolului",
  "settings.userManagement.permissions.navbar.backToUser": "Administrare utilizatori / Selectare persoană de contact / Atribuire rol / {employeeName}",
  "settings.userManagement.roles.navbar.back": "Administrarea utilizatorilor / Selectați persoana de contact",
  "sidebar.advisor.employees": "Lista personalului",
  "sidebar.advisor.inbox": "Căsuța poștală",
  "sidebar.advisor.tickets": "Întrebări",
  "sidebar.advisor.uploads": "Încărcături",
  "sidebar.customerView": "Opinia clientului",
  "sidebar.faceToFace.button": "Contactați",
  "sidebar.faceToFace.title": "Față în față cu",
  "sidebar.list.accounting": "Contabilitate",
  "sidebar.list.analysis": "",
  "sidebar.list.cockpit": "Cabină de pilotaj",
  "sidebar.list.documents": "Rapoarte",
  "sidebar.list.faceToFace": "Față în față",
  "sidebar.list.hr": "Personal",
  "sidebar.list.kpi": "Fapte și cifre",
  "sidebar.list.personnelFile": "Dosar personal",
  "sidebar.list.projects": "Proiecte",
  "sidebar.list.results": "Evaluări",
  "sidebar.list.settings": "Setări",
  "sidebar.list.support": "Suport",
  "sidebar.list.tickets": "Întrebări",
  "signDialog.noQesEnabled.message": "Prin prezenta confirm eliberarea documentului.",
  "signDialog.noQesRequired.button.click": "Share",
  "signDialog.noQesRequired.button.qes": "Semnătura electronică",
  "signDialog.noQesRequired.message": "Pentru aprobare, puteți alege între aprobarea prin clic sau prin semnătură electronică.",
  "signDialog.qesblocked.message": "Semnarea prin semnătură electronică nu este posibilă în prezent, deoarece o procedură de semnare a fost deja inițiată de o altă persoană în acest moment. Vă rugăm să încercați din nou în aproximativ 15 minute.",
  "signDialog.qesblocked.title": "Eliberarea nu este posibilă în prezent",
  "signDialog.qesRequired.confirm": "OK, semnați electronic",
  "signDialog.qesRequired.message": "A fost solicitată o semnătură electronică pentru această eliberare. În acest moment veți fi direcționat către semnătura electronică.",
  "signDialog.title": "Eliberare",
  "soleEarnerStatus.Alleinerzieher": "Părinte singur",
  "soleEarnerStatus.Alleinverdiener": "Unic salariat",
  "soleEarnerStatus.Nein": "Nu",
  "staff.documents.navbar.heading": "Fișierele mele",
  "staff.personnelFile.navbar.heading": "Bună ziua {name}!",
  "stepper.optional": "opțional",
  "stepper.progress": "Progresul",
  "support.admins.title": "Administratori",
  "support.filter.option.activeChats": "Numai chat-urile active",
  "support.filter.option.allChats": "Toate chat-urile",
  "support.filter.option.inactiveChats": "Numai chat-urile inactive",
  "support.filter.setting.showInactiveColleagues": "Afișați angajații inactivi",
  "support.inactiveEmployee": "Fost angajat",
  "support.partners.title": "Parteneri",
  "support.privateChat.dialog.markAsPrivate.confirm": "Marcați ca fiind confidențial",
  "support.privateChat.dialog.markAsPrivate.message": "Dacă un chat a fost marcat ca fiind confidențial, <b>nici o altă persoană</b> nu poate vizualiza chat-ul și răspunde la mesaje în absența dumneavoastră.{br}{br}Clientul dumneavoastră nu va fi <b>nu</b> informat</b> despre această setare.",
  "support.privateChat.dialog.markAsPrivate.title": "Marcați chat-ul ca fiind confidențial?",
  "support.privateChat.dialog.markAsPublic.confirm": "Renunțarea la confidențialitate",
  "support.privateChat.dialog.markAsPublic.message": "Atunci când un chat nu mai este marcat ca fiind confidențial, <b>alte persoane</b> pot vizualiza chat-ul și pot răspunde la mesaje în absența dumneavoastră.{br}{br}Clientul dumneavoastră nu este <b>nu</b> informat</b> despre această setare.",
  "support.privateChat.dialog.markAsPublic.title": "Nu mai marcați chat-ul ca fiind confidențial?",
  "support.privateChat.info": "Chat-ul este marcat ca fiind <b>confidențial</b> și nu poate fi vizualizat de alte persoane.",
  "support.privateChat.info.markAsPublic": "LIFT UP",
  "support.privateChat.info.mobile": "Chat-ul este <b>confidențial</b>.",
  "support.privateChat.placeholder": "Introduceți mesajul ...",
  "support.responsible.title": "Membrii echipei",
  "support.search.navbar.title": "Rezultatele căutării",
  "support.substitute.author": "{substituteName} (înlocuitor pentru {authorName})",
  "support.substitute.back": "ÎNAPOI LA CHAT-UL MEU",
  "support.substitute.back.mobile": "CHAT-UL MEU",
  "support.substitute.warning": "<b>Atenție:</b> Sunteți în chat-ul lui <b>{name}</b>.",
  "support.substitute.warning.mobile": "Chat de <b>{name}</b>",
  "support.yourContacts": "Persoanele dumneavoastră de contact",
  "svsAccount.transactions.table.contextMenu.download": "",
  "svsAccount.transactions.table.contextMenu.show": "",
  "svsAccount.transactions.table.filters.quarter": "",
  "svsAccount.transactions.table.label.amount": "",
  "svsAccount.transactions.table.label.quarter": "",
  "svsAccount.transactions.table.label.text": "",
  "svsAccount.transactions.table.search.count": "",
  "svsAccount.transactions.table.search.placeholder": "",
  "svsAccount.transactions.title": "",
  "table.button.release": "ELIBERARE",
  "table.employees.dateOfJoining": "Data intrării",
  "table.employees.dateOfJoiningExiting": "Data intrării/ieșirii",
  "table.employees.dateOfLeaving": "Data ieșirii",
  "table.employees.dateOfTransmitting": "Transmisă pe",
  "table.employees.documents.date": "Data",
  "table.employees.documents.document": "Fișier",
  "table.employees.documents.documentName": "Desemnare",
  "table.employees.documents.downloadDocuments": "Descărcați fișiere",
  "table.employees.documents.uploadedBy": "Încărcat de",
  "table.employees.employee": "Angajat",
  "table.employees.jobDescription": "Titlul postului",
  "table.employees.missingDetails": "Date lipsă",
  "table.employees.personalId": "Numărul de personal",
  "table.employees.reasonForLeaving": "Motivul plecării",
  "table.employees.status": "Stare",
  "table.employees.status.active": "Activ",
  "table.employees.status.inactive": "Inactiv",
  "table.employees.status.inDataChange": "Modificarea datelor de bază",
  "table.employees.status.inDataChangeNotTransferred": "Amendamentul (proiect)",
  "table.employees.status.inDeregistration": "În cazul radierii",
  "table.employees.status.inDeregistrationNotTransferred": "Radierea (proiect)",
  "table.employees.status.inRegistration": "La înregistrare",
  "table.employees.status.inRegistrationNotTransferred": "Cerere (proiect)",
  "table.label.changes": "Modificări",
  "table.label.changeUsername": "Trimiteți e-mail pentru schimbarea numelor de utilizator",
  "table.label.comment": "Comentariu",
  "table.label.companyName": "Numele companiei",
  "table.label.contactPerson": "Contactați",
  "table.label.contactPersonId": "Număr personal",
  "table.label.costCenter": "Centrul de costuri",
  "table.label.createdAt": "Data încărcării",
  "table.label.createdAt.variant": "Creat pe",
  "table.label.createdBy": "Creat de",
  "table.label.date": "Data",
  "table.label.dateOfJoining": "Data intrării",
  "table.label.dateOfJoiningExiting": "Data intrării/ieșirii",
  "table.label.dateOfLeaving": "Data ieșirii",
  "table.label.dateOfTransmitting": "Transmisă pe",
  "table.label.deleteUser": "Ștergeți utilizatorul",
  "table.label.document": "Fișier",
  "table.label.documentName": "Desemnare",
  "table.label.documentType": "Fișier",
  "table.label.downloadDocuments": "Descărcați fișiere",
  "table.label.dueDate": "Termen limită",
  "table.label.dueDateForDraft": "Termen limită de eliberare",
  "table.label.email": "Adresa de e-mail",
  "table.label.employee": "Angajat",
  "table.label.file": "Fișier",
  "table.label.fileName": "Desemnare",
  "table.label.folder": "Dosar",
  "table.label.holiday": "Vacanță",
  "table.label.info": "Informații",
  "table.label.jobDescription": "Titlul postului",
  "table.label.lastAuthenticatedAt": "Ultima autentificare",
  "table.label.lastAuthenticatedAt.registered.tooltip": "Utilizator înregistrat",
  "table.label.lastAuthenticatedAt.unregistered.tooltip": "Utilizator neînregistrat",
  "table.label.lastCreated": "Ultimul creat",
  "table.label.members": "Membri",
  "table.label.message": "Mesaj",
  "table.label.messages": "Știri",
  "table.label.missingDetails": "Date lipsă",
  "table.label.modulePermissions": "Autorizații la nivel de zonă",
  "table.label.modules": "Domenii",
  "table.label.needsRelease": "Eliberare necesară",
  "table.label.openReleases": "Eliberări deschise",
  "table.label.otherAbsence": "Alte absențe",
  "table.label.personalId": "Numărul de personal",
  "table.label.personalNumber": "Numărul de personal",
  "table.label.phoneNumber": "Număr de telefon",
  "table.label.reasonForLeaving": "Motivul plecării",
  "table.label.recordedBy": "Capturat din",
  "table.label.release": "Eliberări",
  "table.label.released": "Transmisă",
  "table.label.releasedAt": "Transmisă pe",
  "table.label.releaseRequestedBy": "Comunicat furnizat de",
  "table.label.role": "Rolă",
  "table.label.sentBy": "Trimis de la",
  "table.label.sickLeave": "Concediu medical",
  "table.label.status": "Stare",
  "table.label.teamMessages": "Știri despre echipă",
  "table.label.ticket": "Consultare",
  "table.label.ticketBy": "Întrebare adresată de",
  "table.label.ticketDescription": "Desemnare",
  "table.label.ticketDueDate": "Termen limită",
  "table.label.tickets": "Întrebări",
  "table.label.transferStatus": "Transfer",
  "table.label.transmittedAt": "Transmisă pe",
  "table.label.type": "Artă",
  "table.label.updatedAt": "Ultima editare",
  "table.label.updatedAt.variant": "Ultima modificare",
  "table.label.uploadedBy": "Încărcat de",
  "table.label.username": "Nume utilizator",
  "table.noResults.message": "Căutarea dvs. nu a dat niciun rezultat.",
  "table.noResults.title": "Nu există rezultate de căutare",
  "taxAccount.pay.button": "Plata datoriilor fiscale",
  "taxAccount.transactions.table.filters.date": "Data",
  "taxAccount.transactions.table.filters.tax": "Impozit",
  "taxAccount.transactions.table.label.amount": "Suma",
  "taxAccount.transactions.table.label.date": "Data",
  "taxAccount.transactions.table.label.due": "Maturitate",
  "taxAccount.transactions.table.label.taxPeriod": "Perioada",
  "taxAccount.transactions.table.label.text": "Desemnare",
  "taxAccount.transactions.table.search.count": "Tranzacții: <b>{count}</b>",
  "taxAccount.transactions.table.search.placeholder": "Căutarea tranzacțiilor",
  "taxAccount.transactions.title": "Soldul contului fiscal: [valoare]",
  "termsOfUse.checkbox.error": "Vă rugăm să acceptați termenii de utilizare",
  "termsOfUse.upcoming.navbar.button": "Acceptați",
  "termsOfUse.upcoming.navbar.message": "Termenii de utilizare a aplicației TPA Connect se vor schimba începând cu {data}.",
  "ticketList.emptystate.title": "Nu sunt disponibile interogări",
  "unpaidAccounts.invoices.pay": "Plata",
  "unpaidAccounts.invoices.table.filters.date": "Data documentului",
  "unpaidAccounts.invoices.table.label.documentDate": "Data documentului",
  "unpaidAccounts.invoices.table.label.documentNumber": "Numărul documentului",
  "unpaidAccounts.invoices.table.label.documents": "",
  "unpaidAccounts.invoices.table.label.documentText": "Textul documentului",
  "unpaidAccounts.invoices.table.label.netAmount": "Suma netă",
  "unpaidAccounts.invoices.table.label.opAmount": "Suma restantă",
  "unpaidAccounts.invoices.table.label.receiptDate": "Data documentului",
  "unpaidAccounts.invoices.table.label.receiptNumber": "Numărul documentului",
  "unpaidAccounts.invoices.table.label.receiptText": "Textul documentului",
  "unpaidAccounts.invoices.table.search.count": "Facturi: <b>{count}</b>",
  "unpaidAccounts.invoices.table.search.placeholder": "Căutarea facturilor",
  "unpaidAccounts.invoices.title": "Facturi restante la TPA: [valoare]",
  "upload.category.analysis": "Raport: Evaluare lunară",
  "upload.category.annualReports": "Conturile anuale",
  "upload.category.articles": "Elaborări",
  "upload.category.billing": "Raport: Declarație lunară",
  "upload.category.certificateOfCitizenship": "Dovada cetățeniei",
  "upload.category.certificateOfRegistration": "Confirmarea înregistrării",
  "upload.category.contractOfEmployment": "Contract",
  "upload.category.contracts": "Contracte",
  "upload.category.correspondence": "Corespondență",
  "upload.category.dataChangeConfirmation": "Modificarea datelor de bază",
  "upload.category.deregisterDocument": "Documente de radiere",
  "upload.category.deregistrationConfirmation": "Radierea angajaților",
  "upload.category.employmentPermit": "Permis de muncă",
  "upload.category.identityCard": "Carte de identitate",
  "upload.category.information": "Informații",
  "upload.category.notices": "Anunțuri",
  "upload.category.other": "Altele",
  "upload.category.payslip": "fluturaș de salariu",
  "upload.category.registrationConfirmation": "Înregistrarea angajaților",
  "upload.category.report": "Rapoarte",
  "upload.category.taxOffice": "Biroul fiscal",
  "upload.category.taxReturn": "Declarații fiscale",
  "userManagement.successDialog.changeUsername.message": "<b>{userName}</b> primește o cerere de confirmare a adresei de e-mail nou anunțate.",
  "userManagement.successDialog.deleteUser.single.message": "<b>{userName}</b> a fost șters cu succes.",
  "userManagement.successDialog.deleteUser.title": "Utilizatorul a fost șters cu succes.",
  "userManagement.successDialog.existingUser.superAdmin.multi.message": "Au fost adoptate setările pentru <b>{număr} utilizatori</b>.",
  "userManagement.successDialog.existingUser.superAdmin.single.message": "Setările pentru <b>{userName}</b> au fost adoptate.",
  "userManagement.successDialog.existingUser.superAdmin.title": "Setările utilizatorului salvate cu succes",
  "userManagement.successDialog.existingUser.title": "Setări de utilizator propuse cu succes",
  "userManagement.successDialog.message": "Propunerea dvs. trebuie să fie aprobată acum de un <b>superadministrator</b>.",
  "userManagement.successDialog.newUser.superAdmin.multi.message": "<b>{count} utilizatori</b> primesc o invitație prin e-mail.",
  "userManagement.successDialog.newUser.superAdmin.single.message": "<b>{userName}</b> primește o invitație prin e-mail.",
  "userManagement.successDialog.newUser.superAdmin.title": "Utilizator invitat cu succes",
  "userManagement.successDialog.newUser.title": "Utilizatorul a sugerat cu succes",
  "userManagement.successDialog.rejectUser.title": "Utilizator respins cu succes",
  "userManagement.successDialog.releaseExistingUser.title": "Utilizator eliberat cu succes",
  "userManagement.successDialog.releaseNewUser.title": "Utilizator eliberat și invitat cu succes",
  "userManagement.successDialog.sendUsernameChangeEmail.title": "E-mailul pentru schimbarea numelui de utilizator a fost trimis cu succes.",
  "userProfile.createBmdTodos": "Creați sarcini în BMD pentru interogări",
  "userProfile.deleteAccount.message": "Sunteți sigur că doriți să vă ștergeți contul? Această acțiune nu poate fi anulată!",
  "userProfile.deleteAccount.title": "Ștergeți contul",
  "userProfile.multiFactorAuth": "Activați autentificarea cu doi factori cu număr de telefon",
  "userProfile.navbar.heading": "Profil",
  "userProfile.upgrade.navbar.heading": "Actualizare",
  "userProfile.userInterests.title": "Interese",
  "userReleaseInfoAdminDialog.releaseInfo.multi": "Vă rugăm să eliberați noii utilizatori pentru a-i invita.",
  "userReleaseInfoAdminDialog.releaseInfo.single": "Vă rugăm să aprobați noul utilizator pentru a-l invita.",
  "userReleaseInfoAdminDialog.releaseMessage.multi": "{userName} a trimis <b>{count} utilizatori noi</b>.",
  "userReleaseInfoAdminDialog.releaseMessage.single": "{userName} a trimis un <b>{count} la noul utilizator</b>.",
  "userReleaseInfoAdminDialog.submit": "Vizualizați și partajați",
  "userReleaseInfoAdminDialog.title": "Partajați și invitați utilizatori",
  "userReleaseInfoDialog.cancel": "Pentru administrarea utilizatorilor",
  "userReleaseInfoDialog.from": "de la",
  "userReleaseInfoDialog.message": "Modificările pe care le-ați propus pentru următorii utilizatori au fost aprobate/respinse:",
  "userReleaseInfoDialog.rejected": "Respins",
  "userReleaseInfoDialog.released": "eliberat",
  "userReleaseInfoDialog.title": "Schimbare utilizator",
  "viewer.unsupported.message": "{name} nu poate fi afișat în acest moment.",
  "webhook.employee.noSearch": "Este necesară introducerea unei căutări",
  "webhook.employee.placeholder": "Numele angajatului",
  "webhook.noCompanies": "Nicio autorizație de societate",
  "webhook.tickets.button": "Creați o anchetă",
  "webhook.tickets.info": "Atribuiți documentul unei zone și selectați categoria.",
  "webhook.tickets.navbar": "Consultare privind {document}",
  "webhook.tickets.taskTitle": "Sarcină pentru {name}",
  "webhook.tickets.title": "Creați o anchetă",
  "webhook.uploads.navbar": "Transmiterea fișierelor",
  "welcomeCard.accounting.addMoreRecords.button": "Introduceți documente suplimentare",
  "welcomeCard.accounting.addRecords.button": "Introduceți documentele",
  "welcomeCard.hr.addMoreRecords.button": "Transmiteți mai multe fișiere",
  "welcomeCard.hr.addRecords.button": "Transmiterea fișierelor",
  "texterify_timestamp": "2024-11-21T10:06:38Z"
} as const;

export { romanian };
