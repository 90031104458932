import { IMessageIDS } from "../i18n/util";
import { UserNotification } from "../network/APITypes";
import { StartsWith } from "./ts";

type AppNotificationTypes = StartsWith<NonNullable<UserNotification["notificationType"]>, "appNotification:">;

export const titleByType: Record<AppNotificationTypes, IMessageIDS> = {
    "appNotification:maintenance": "appNotificationDialog.title.maintenance",
    "appNotification:newFeature": "appNotificationDialog.title.newFeature",
    "appNotification:update": "appNotificationDialog.title.update",
};

export function isAppNotification(n: UserNotification): boolean {
    return !!n && !!n.notificationType && n.notificationType in titleByType;
}
