import { useLocation } from "react-router";
import styled from "styled-components";
import { GLOBAL_FEATURES } from "../../features";
import { t } from "../../i18n/util";
import { authStore } from "../../stores/AuthStore";
import { companiesStore } from "../../stores/CompaniesStore";
import { IEmployee } from "../../types/models";
import { formatDate } from "../../util/helpers";
import { getFullName } from "../../util/user";
import { withParams } from "../app/router/history";
import { Routes } from "../app/router/Routes";
import { useReasonsForLeaving } from "../hooks/useReasonsForLeaving";
import { ResultsRoutes } from "../results/router/ResultsRoutes";
import { ExpansionLine } from "../ui/ExpansionLine";
import { OverviewLine } from "../ui/OverviewLine";
import { OverviewSection } from "../ui/OverviewSection";
import { RouterLinkButton } from "../ui/Primitives";
import { customColors } from "../util/Theme";

const Caption = styled.p`
    font-family: "Roboto";
    font-size: 10px;
    line-height: 14px;
    color: var(--body2-dark);
    font-weight: bold;
    padding: 10px 0;
    border-bottom: 1px solid ${customColors.greyLight};
`;

export const HrEmployeeOverviewPastEmployments = ({ employee }: { employee: IEmployee }) => {
    const location = useLocation();
    const numPastEmployments = employee.pastEmployments?.length ?? 0;
    const isEmployed = !!employee.dateOfJoining && employee.status !== "inactive";

    const reasonsForLeaving = useReasonsForLeaving();

    if (!isEmployed && numPastEmployments === 0) {
        return null;
    }

    const companyStore = companiesStore.selectedCompanyStore;
    const canViewEmployeePayrollAccount = companyStore?.canReadEmployeePayrollAccount(employee.subsidiaryId);

    let actionButton = null;

    if (
        GLOBAL_FEATURES.payrollAccounts &&
        (canViewEmployeePayrollAccount || (authStore.isStaff && employee.userId === authStore.userId))
    ) {
        actionButton = (
            <RouterLinkButton
                color="primary"
                variant="contained"
                to={{
                    pathname: withParams(ResultsRoutes.EMPLOYEE_PAYROLL_ACCOUNT, {
                        employeeId: employee.id,
                        year: new Date().getFullYear().toString(),
                    }),
                    state: {
                        employeeName: getFullName(employee),
                        previousRoute: location.pathname.includes(Routes.STAFF) ? "staff" : "employeeDetails",
                    },
                }}
                data-id="open_employee_payroll_account_button"
            >
                {t("screen.hr.employee.details.showEmployeePayrollAccount")}
            </RouterLinkButton>
        );
    }

    return (
        <OverviewSection title={t("screen.hr.employee.details.employments")} button={actionButton}>
            {isEmployed && (
                <>
                    <Caption>{t("screen.hr.employee.details.currentEmployment")}</Caption>
                    <OverviewLine title={t("screen.hr.personaldata.dateOfJoining")} date={employee.dateOfJoining} />
                </>
            )}

            {employee.status === "inDeregistration" || employee.status === "inDeregistrationNotTransferred" ? (
                <>
                    <Caption>
                        {t(
                            employee.status === "inDeregistration"
                                ? "table.employees.status.inDeregistration"
                                : "table.employees.status.inDeregistrationNotTransferred",
                        )}
                    </Caption>
                    <OverviewLine
                        title={t("screen.hr.employee.deregistration.data.reason")}
                        label={employee.reasonForLeaving && reasonsForLeaving.idToString(employee.reasonForLeaving)}
                    />
                    <OverviewLine
                        title={t("screen.hr.employee.deregistration.data.date")}
                        label={formatDate(employee.dateOfLeaving)}
                    />
                    <OverviewLine
                        title={t("screen.hr.employee.deregistration.data.remainingHolidays")}
                        label={employee.remainingVacationDays?.toString()}
                    />
                    <OverviewLine
                        title={t("screen.hr.employee.deregistration.data.remainingOvertime")}
                        label={employee.remainingOvertime?.toString()}
                    />
                </>
            ) : null}

            {numPastEmployments > 0 && (
                <>
                    <Caption>{t("screen.hr.employee.details.pastEmployments")}</Caption>
                    {employee.pastEmployments?.map((employment, index) => {
                        const relationShip = employment.employmentRelationship
                            ? t(`employmentRelationship.${employment.employmentRelationship}`)
                            : undefined;
                        const insuranceType = employment.employmentInsuranceType
                            ? t(`employmentInsuranceType.${employment.employmentInsuranceType}`)
                            : undefined;

                        return (
                            <ExpansionLine
                                key={index}
                                title={`${numPastEmployments - index}${t("hr.employee.employment")}`}
                                label={
                                    formatDate(employment.dateOfJoining) + " - " + formatDate(employment.dateOfLeaving)
                                }
                            >
                                <OverviewLine
                                    title={t("hr.employee.reasonForLeaving")}
                                    label={employment.reasonForLeaving}
                                />
                                <OverviewLine
                                    title={t("hr.employee.jobDescription")}
                                    label={employment.jobDescription}
                                />
                                <OverviewLine
                                    title={t("hr.employee.employmentRelationship")}
                                    label={
                                        relationShip && insuranceType
                                            ? `${relationShip} (${insuranceType})`
                                            : (relationShip ?? insuranceType)
                                    }
                                />
                                <OverviewLine
                                    title={t("hr.employee.workplaceState")}
                                    label={employment.workplaceState}
                                />
                            </ExpansionLine>
                        );
                    })}{" "}
                </>
            )}
        </OverviewSection>
    );
};
