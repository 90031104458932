import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { UserNotificationSettings } from "../../network/APITypes";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";

export const useUserNotificationSettings = () => {
    const [isInitialized, setIsInitialized] = React.useState(false);
    const [notifications, setNotifications] = React.useState<UserNotificationSettings | null>(null);
    const selectedCompany = companiesStore.selectedCompany;

    const load = React.useCallback(async () => {
        if (!selectedCompany) {
            return;
        }

        try {
            generalStore.isLoading = true;
            const res = await API.getUserNotificationSettings(selectedCompany.id);
            setNotifications(res);
            setIsInitialized(true);
        } catch (error) {
            generalStore.setError(t("error.loadNotifications"), error);
        } finally {
            generalStore.isLoading = false;
        }
    }, [selectedCompany]);

    const patch = async (newNotifications: Partial<UserNotificationSettings>) => {
        if (!selectedCompany) {
            return;
        }

        try {
            generalStore.isLoading = true;
            const res = await API.patchUserNotificationSettings(selectedCompany.id, newNotifications);
            setNotifications(res);
        } catch (error) {
            generalStore.setError(t("error.save"), error);
        } finally {
            generalStore.isLoading = false;
        }
    };

    React.useEffect(() => {
        load();
    }, [load]);

    return {
        notifications,
        isInitialized,
        reload: load,
        patch,
    };
};
