import { Button } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { UserNotification } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";
import { titleByType } from "../../util/appNotifications";
import { CustomDialog, CustomDialogActions, CustomDialogContent, CustomDialogTitle } from "./CustomDialog";

export const AppNotificationsDialog = observer(function AppNotificationsDialog() {
    const appNotification = generalStore.appNotifications.length > 0 ? generalStore.appNotifications[0] : undefined;

    const handleAccept = async () => {
        if (!appNotification) {
            return;
        }

        if (!appNotification.isRead) {
            await API.putMarkNotificationAsRead({ notificationId: appNotification.id });
        }

        generalStore.appNotifications.shift(); //  remove the current notification
    };

    return (
        <CustomDialog open={!!appNotification} disableEscapeKeyDown>
            <CustomDialogContent>
                {appNotification && renderTitle(appNotification)}
                {appNotification && renderContent(appNotification)}
                <CustomDialogActions>
                    <Button variant="contained" color="primary" onClick={handleAccept}>
                        {t("appNotificationDialog.button.accept")}
                    </Button>
                </CustomDialogActions>
            </CustomDialogContent>
        </CustomDialog>
    );
});

function renderTitle(notification: UserNotification): React.ReactNode {
    switch (notification.notificationType) {
        case "appNotification:newFeature":
        case "appNotification:maintenance":
        case "appNotification:update":
            return <CustomDialogTitle>{t(titleByType[notification.notificationType])}</CustomDialogTitle>;
    }

    return null;
}

function renderContent(notification: UserNotification): React.ReactNode {
    if (!notification.contentText || !notification.contentType) {
        return null;
    }

    switch (notification.contentType) {
        case "text/html":
            return <div dangerouslySetInnerHTML={{ __html: notification.contentText }} />;
    }

    return null;
}
