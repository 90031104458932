import { Route, Switch } from "react-router-dom";
import { GLOBAL_FEATURES } from "../../../features";
import { companiesStore } from "../../../stores/CompaniesStore";
import { NotFoundSite } from "../../app/sites/NotFoundSite";
import { ResultsEmployeePayrollAccount } from "../ResultsEmployeePayrollAccount";
import { ResultsEmployerPayrollAccount } from "../ResultsEmployerPayrollAccount";
import { ResultsIndex } from "../ResultsIndex";
import { ResultsAccountingSite } from "../sites/ResultsAccountingSite";
import { ResultsAccountSheetSite } from "../sites/ResultsAccountSheetSite";
import { ResultsRoutes } from "./ResultsRoutes";

export const ResultsRouter = () => {
    const companyStore = companiesStore.selectedCompanyStore;
    const hasHrResults = companyStore?.hasHrResults();
    const hasAccountingResults = companyStore?.hasAccountingResults();

    const routes = [
        <Route key="index" exact path={ResultsRoutes.ROOT}>
            <ResultsIndex />
        </Route>,
    ];

    if (hasAccountingResults === true) {
        routes.push(
            <Route
                key="accounting"
                exact
                path={[
                    ResultsRoutes.ACCOUNT_LISTING,
                    ResultsRoutes.CUSTOMER_LISTING,
                    ResultsRoutes.VENDOR_LISTING,
                    ResultsRoutes.BALANCE_SHEET,
                    ResultsRoutes.CASH_ACCOUNTING,
                    ResultsRoutes.PROFIT_AND_LOSS,
                ]}
            >
                <ResultsAccountingSite />
            </Route>,
            <Route key="account-sheet" exact path={ResultsRoutes.ACCOUNT_SHEET}>
                <ResultsAccountSheetSite />
            </Route>,
        );
    }

    if (GLOBAL_FEATURES.payrollAccounts && hasHrResults === true) {
        routes.push(
            <Route key="employer-payroll-account" exact path={ResultsRoutes.EMPLOYER_PAYROLL_ACCOUNT}>
                <ResultsEmployerPayrollAccount />
            </Route>,
            <Route key="employee-payroll-account" exact path={ResultsRoutes.EMPLOYEE_PAYROLL_ACCOUNT}>
                <ResultsEmployeePayrollAccount />
            </Route>,
        );
    }

    if (GLOBAL_FEATURES.payrollAccounts && companyStore?.permissions.isStaff) {
        routes.push(
            <Route key="payroll-account" exact path={ResultsRoutes.EMPLOYEE_PAYROLL_ACCOUNT}>
                <ResultsEmployeePayrollAccount />
            </Route>,
        );
    }

    routes.push(<Route key="notfound" component={NotFoundSite} />);
    return <Switch>{routes}</Switch>;
};
